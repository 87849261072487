/* eslint-disable max-len */
import _ from 'lodash';
import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { Flex, FlexItem } from "@jutro/layout";
import { WMICLoader } from 'gw-components-platform-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { EndorsementService } from 'gw-capability-policychange';
import { WMICPolicyChangeStateService } from 'wmic-capability-policychange';
import { PolicyService } from 'gw-capability-policy';
import { useAuthentication } from 'wmic-digital-auth-react';
import { CONSTANTS, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import { WMICButton } from 'wmic-components-platform-react';
import WMICRemoveVehicleWizard from './WMICRemoveVehicleWizard/WMICRemoveVehicleWizard';
import WMICRemoveVehicleMessage from './WMICRemoveVehicleMessage/WMICRemoveVehicleMessage';
import { VEHICLE_TYPES } from './WMICRemoveVehicleWizard/WMICRemoveVehicleWizardConsts.js';
import { PRIMARY_USE_TEXT } from './WMICRemoveVehicleWizard/WMICRemoveVehicleWizardConsts';
import PropTypes from 'prop-types';
import metadata from './WMICRemoveVehiclePAComponent.metadata.json5';
import messages from './WMICRemoveVehiclePAComponent.messages';
import WMICRemoveVehicleWizardMessages from './WMICRemoveVehicleWizard/WMICRemoveVehicleWizard.messages.js';
import styles from './WMICRemoveVehiclePAComponent.module.scss';
import { getVehicleName } from './WMICRemoveVehicleWizard/WMICRemoveVehicleWizardUtils';

function RemoveVehiclePAComponent(props) {
    const VIEW_MODES = {
        VEHICLE_LIST_VIEW: 1,
        VEHICLE_REMOVAL_FORM: 2,
        ERROR: 3
    };

    const { policyVM, match, billingData } = props;
    const translator = useTranslator();
    const history = useHistory();
    const { authHeader } = useAuthentication();

    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');

    const deleteVehicleChangeRequest = WMICPolicyChangeStateService.getState();

    const [state, setState] = useState({
        vehicleNumberToRemove: deleteVehicleChangeRequest?.editDeleteVehicle?.vehicleNumber,
        viewMode: deleteVehicleChangeRequest?.editDeleteVehicle?.jobId ? VIEW_MODES.VEHICLE_REMOVAL_FORM : VIEW_MODES.VEHICLE_LIST_VIEW,
        acctPolTrans: [],
        hasBillingDelinqNotice: false,
        isLoading: true
    });
    const intl = useContext(IntlContext);

    const setCurrentState = useCallback((updState) => {
        setState(prevState => ({...prevState, ...updState}))
    }, []);

    useEffect(() => {
        Promise.all([
            AccountBillingDetailsService.policyChangeCheckHasDelinquency_WMIC(policyNumber, termNumber, authHeader),
            EndorsementService.policyPeriodHasFutureBoundPolicyChange_WMIC(policyNumber, authHeader),
            PolicyService.getAccountPolicyTransactions_WMIC(policyNumber, authHeader)
        ]).then(([hasBillingDelinqNotice, hasFutureBoundPolCh, acctPolTrans]) => {
            const hasAmountOverDue = billingData?.amountOverDue?.amount > 0;

            setCurrentState({ hasBillingDelinqNotice, hasFutureBoundPolCh, hasAmountOverDue, acctPolTrans, isLoading: false });
        });
    }, []);

    const getRemoveVehicleTitle = () => (
        <h2 className={styles.wmicTitle} aria-label={translator(messages.removeVehicleAria)}>{translator(messages.removeVehicle)}</h2>
    );

    const formattedNumber = (amount) => intl.formatNumber(amount);

    const focusRemoveVehicleWizardTitle = () => {
        let headerTag = document.getElementById('removeVehicleListSubtitle');
        if (!(headerTag && !headerTag.hidden)) {
            headerTag = document.getElementById('removeVehicleTitleHeader');
        }
        if (headerTag) {
            headerTag.setAttribute('tabindex', '0');
            headerTag.focus({preventScroll: true});
        }
    };

    useEffect(() => {
        focusRemoveVehicleWizardTitle();
    }, [state])

    const handleRemove = (vehicleNumber) => {
        const policyCanBeChanged = !state.hasFutureBoundPolCh && !state.hasBillingDelinqNotice && !state.hasAmountOverDue;

        if (policyCanBeChanged) {
            setCurrentState({ vehicleNumberToRemove: vehicleNumber, viewMode: VIEW_MODES.VEHICLE_REMOVAL_FORM });
        } else {
            setCurrentState({ viewMode: VIEW_MODES.ERROR });
        }
    };

    const handleCancel = () => {
        history.push(`/account-policy-change/${policyNumber}/${termNumber}/auto/${CONSTANTS.POLICY_CHANGE_TYPE.SELECT_TYPE}`);
    };

    const getRemoveVehicleListButtonSection = () => {
        return (
            <Fragment>
                <WMICButton type="outlined" onClick={ handleCancel }>
                    { translator(messages.cancel) }
                </WMICButton>
            </Fragment>
        );
    };

    const getRemoveVehicleListContent = (policy) => {
        const vehicles = _.orderBy(policy?.currentPeriod?.lobs?.personalAuto?.vehicleDTOs, 'vehicleNumber');
        const content = vehicles?.map((v, index) => {
            return (
                <div className={styles.wmicFlexColumn} role='listitem'>
                    <FlexItem grow={1} className={styles.wmicBox}>
                        <div className={styles.wmicBoxContent} key={index}>
                            <h3>
                                <span className={styles.wmicStrong}>{getVehicleName(v)}</span>
                            </h3>
                            <div className={'pb-4'}>
                                <p>
                                    {VEHICLE_TYPES[v.vehicleType_WMIC]}
                                </p>
                                <p>
                                    <span className={styles.wmicStrong}>{translator(messages.use)}</span> {PRIMARY_USE_TEXT[v.primaryUse_WMIC]}
                                </p>
                                <p>
                                    <span className={styles.wmicStrong}>{translator(messages.annualMileage)}</span> {formattedNumber(v.annualMileage_WMIC)}
                                </p>
                            </div>
                            <div>
                                <WMICButton 
                                    id={`remove_vehicle${v.vehicleNumber}_wizard_button`}
                                    type="secondary" 
                                    aria-label={translator(messages.removeVehicleButtonAriaMessage, { vehicleName: `${v.year} ${v.make} ${v.model} (${v.vehicleNumber})`, vehicleUseage: v.primaryUse_WMIC, annualMileage: v.annualMileage_WMIC})}
                                    onClick={() => handleRemove(v.vehicleNumber)}>
                                    {translator(messages.remove)}
                                </WMICButton>
                            </div>
                        </div>  
                    </FlexItem>
                </div>
            );
        });

        return (
            <Flex gap='none' className={styles.wmicFlexContainer} role='list'>
                { content }
            </Flex>
        );
    };

    const getRemoveVehicleFormContent = (policy) => {
        const v = policy?.currentPeriod?.lobs?.personalAuto?.vehicleDTOs.find((v) => v.vehicleNumber === state.vehicleNumberToRemove);
        if (v) {
            return (
                <WMICRemoveVehicleWizard
                    match={match}
                    policy={policy}
                    policyTransactions={state.acctPolTrans}
                    vehicleNumberToRemove={state.vehicleNumberToRemove}
                    removeVehiclePAComponentSetState={setCurrentState}>
                </WMICRemoveVehicleWizard>
            );
        }
    };

    const getRemoveVehicleErrorContent = () => {
        const message = (state.hasBillingDelinqNotice || state.hasAmountOverDue) ? ({
            title: translator(messages.pleaseCallUsToComplete),
            text: translator(messages.yourPolicyChangeCannotBeProcessed),
            mode: 'error'
        }) : ({
            title: translator(messages.pleaseCallUsToComplete),
            text: translator(WMICRemoveVehicleWizardMessages.hasFutureTransactionErrorMessage),
            additionalTexts: [translator(WMICRemoveVehicleWizardMessages.pleaseCallUsForAssistanceAdditionalMessage)],
            mode: 'error'
        });

        return (
            <WMICRemoveVehicleMessage {...message} />
        );
    };

    const overrideProps = {
        removeVehicleTitleHeader: {
            content: getRemoveVehicleTitle()
        },
        removeVehicleListSubpage: {
            visible: !state.isLoading && state.viewMode === VIEW_MODES.VEHICLE_LIST_VIEW
        },
        removeVehicleListContent: {
            content: getRemoveVehicleListContent(policyVM)
        },
        removeVehicleListButtonSection: {
            content: getRemoveVehicleListButtonSection()
        },
        removeVehicleFormContent: {
            visible: state.viewMode === VIEW_MODES.VEHICLE_REMOVAL_FORM,
            content: getRemoveVehicleFormContent(policyVM)
        },
        removeVehicleErrorContentContainer: {
            visible: !state.isLoading && state.viewMode === VIEW_MODES.ERROR,
        },
        removeVehicleErrorContent: {
            content: getRemoveVehicleErrorContent()
        },
        wmicLoader: {
            visible: state.isLoading
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICLoaderComponent: WMICLoader
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

RemoveVehiclePAComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired
};

export default RemoveVehiclePAComponent;