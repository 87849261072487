import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import _, { isEmpty, isNull } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { RadioButtonField, ModalFooter } from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICRichTextUtil} from 'wmic-portals-utils-js';
import messages from './WMICAddPolicyModal.messages';
import styles from './WMICAddPolicyModal.module.scss';
import {WMICLoader} from "gw-components-platform-react";
import {UsersProfileDetailsService} from "gw-capability-profileinfo";

const WMICAddPolicyConsentModal = (props) => {
    const {
        goBack,
        onFindPoliciesSuccess,
        onFindPoliciesFailure,
        policies
    } = props;
    const translator = useTranslator();
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const email = _.get(authUserData, 'email');
    const { EnrollmentService } = useDependencies('EnrollmentService');
    const [consent, updateConsent] = useState(null);
    const [loading, updateLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [showModal, setShowModal] = useState(true);
    const contactSummary = useRef(null);
    const METHOD_OF_DISTRIBUTION = { EMAIL: 'email', MAIL: 'mail'}


    const isConsentRequired = useCallback((contactSummaries) => {
        // iterate through the Named Insureds.  Look for matching names, where PMD is set to mail, or the
        // Named Insured's email address is different from the auth email address.
        for (let index = 0; index < contactSummaries.length; index++) {
            const nameMatches = contactSummaries[index]?.namedInsureds?.filter(namedInsured =>
                namedInsured?.firstName?.toLowerCase() === authUserData?.firstName?.toLowerCase()
                && namedInsured?.lastName?.toLowerCase() === authUserData?.lastName?.toLowerCase()
                && (namedInsured?.prefDistributionMethod_WMIC !== METHOD_OF_DISTRIBUTION.EMAIL
                    || namedInsured?.emailAddress1 !== email)
            )
            if (nameMatches?.length > 0) {
                return true;
            }
        }
        setShowModal(false);
        return false;

    }, [authHeader]);

    useEffect(() => {
        // this useEffect is triggered before the modal loads.  It retrieves the account Contacts,
        // determines whether not we need to ask the user for consent, and if consent is not required,
        // call the function used to link the policies and display the policy in AMP.
        (async () => {
            setIsPageLoading(true);
            const contactSummaries = await UsersProfileDetailsService.getAccountsContactSummaries(authHeader);
            contactSummary.current = contactSummaries;
            setIsPageLoading(false);

            contactSummaries && !isConsentRequired(contactSummaries) && linkPoliciesFoundWithConsent();
        })()
    }, [authHeader])

    const policyNumbers = useMemo(() => {
        return policies.map((policy) => policy.policyNumber);
    }, [policies]);

    const handleLinkingResult = useCallback((response) => {
        if (response && !isEmpty(policies)) {
            onFindPoliciesSuccess(policies);
        } else {
            const message = translator(messages.youEnteredTheFollowing, { info: policyNumbers.join(', ') });
            onFindPoliciesFailure(`${message}\n${translator(messages.unableToAddPolicies)}`);
        }
    },
    [policies, onFindPoliciesSuccess, translator, policyNumbers, onFindPoliciesFailure]);

    const linkPoliciesFoundWithConsent = useCallback(() => {
        updateLoading(true);
        const request = {
            PolicyNumbers: policyNumbers,
           ...(isConsentRequired(contactSummary.current) && { 
                consentRecordData: {
                    distributionType: consent ? 'email' : 'mail',
                    systemOrigin: 'amp',
                    applicationFlow: 'linkPolicy',
                    consentType: 'distributionMethod'
                }
            })
        };

        EnrollmentService.addLinkablePolicies_WMIC(request, authHeader).then((response) => {
            handleLinkingResult(response);
        }).catch(() => {
            const message = translator(messages.youEnteredTheFollowing, { info: policyNumbers.join(', ') });
            onFindPoliciesFailure(`${message}\n${translator(messages.unableToAddPolicies)}`);
        }).finally(() => {
            updateLoading(false);
        });
    }, [
        policyNumbers,
        consent,
        EnrollmentService,
        authHeader,
        handleLinkingResult,
        translator,
        onFindPoliciesFailure
    ]);

    const getOption = (translatedText) => {
        return <div className={styles.radioOption}>{WMICRichTextUtil.translateRichText(translatedText)}</div>
    }

    return (
        isPageLoading ? <WMICLoader /> : (
            showModal && <div className={styles.modal}>
                <h1 className='wmic-modal-header-title'>{translator(messages.setContactPreference)}</h1>
                <div className={styles.consentModalBody}>
                    <div className={styles.consentHeaderPart1}>
                        <p className={styles.consentHeaderTextPart1}>
                            {translator(messages.paragraphOne)}
                        </p>
                        <ul>
                            {
                                policies.map((policy) => {
                                    return (
                                        <li key={policy.policyNumber}>
                                            {`${policy.policyNumber} - ${policy.type}`}
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        <strong className={styles.highlightedText}>
                            {translator(messages.paragraphTwo)}
                        </strong>
                    </div>
                    <div className={styles.consentHeaderPart2}>
                        <p>{translator(messages.consentParagraphOne)}</p>
                        <p>{translator(messages.consentParagraphTwo)}</p>
                        <p>{WMICRichTextUtil.translateRichText(translator(messages.consentParagraphThree))}</p>
                        <div>
                            <ul>
                                <li>{translator(messages.consentListItemOne)}</li>
                                <li>{translator(messages.consentListItemTwo)}</li>
                                <li>{translator(messages.consentListItemThree)}</li>
                            </ul>
                        </div>
                    </div>
                    <hr aria-hidden />
                    <div className={styles.consentAction}>
                        <h2 className={styles.consentActionHeader}>
                            {translator(messages.consentQuestion)}
                        </h2>
                        <div className="sr-only" id="choiceDescription">{translator(messages.consentQuestionDescriptionAria)}</div>
                        <RadioButtonField
                            availableValues={[
                                {
                                    code: 'true',
                                    name: getOption(translator(messages.consentYes, { email }))
                                },
                                {
                                    code: 'false',
                                    name: getOption(translator(messages.consentNo))
                                }
                            ]}
                            id="consentSelection"
                            value={consent}
                            onValueChange={(newValue) => { updateConsent(newValue); }}
                        />
                    </div>
                </div>
                <ModalFooter>
                    <WMICButton
                        onClick={() => { goBack(); }}
                        type="outlined"
                    >
                        {translator(messages.back)}
                    </WMICButton>
                    <WMICButton
                        onClick={() => linkPoliciesFoundWithConsent()}
                        disabled={isNull(consent) || loading}
                    >
                        {translator(messages.submit)}
                    </WMICButton>
                </ModalFooter>
            </div>
        )
    );
};

WMICAddPolicyConsentModal.propTypes = {
    goBack: PropTypes.func.isRequired,
    onFindPoliciesSuccess: PropTypes.func.isRequired,
    onFindPoliciesFailure: PropTypes.func.isRequired,
    policies: PropTypes.arrayOf(PropTypes.shape({}))
};

WMICAddPolicyConsentModal.defaultProps = {
    policies: []
};

export default WMICAddPolicyConsentModal;