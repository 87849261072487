import React, {
    useEffect, useContext, useState, useCallback
} from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import { IntlContext, useTranslator } from '@jutro/locale';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { withRouter, Link } from 'react-router-dom';
import { ClaimService } from 'gw-capability-claim';
import { useAuthentication } from 'wmic-digital-auth-react';
import { Icon, InfoLabel } from '@jutro/components';
import { LobIconUtil } from 'wmic-portals-utils-js';
import { getNormalizedLOBName } from '@xengage/gw-portals-config-js';
import ClaimLobOptionsMenu from '../ClaimLobOptionsMenu/ClaimLobOptionsMenu';
import VendorLinkComponent from '../VendorLinkComponent/VendorLinkComponent';
import metadata from './ClaimListComponent.metadata.json5';
import styles from '../../pages/ClaimList/ClaimList.module.scss';
import claimsMessages from '../../Claims.messages';

function ClaimListComponent({ history }) {
    const { authHeader } = useAuthentication();
    const { vendorTypesForPolicyHolderByLob, claimsFilterLOBOptions } = appConfig;
    const [claimDataTable, setClaimDataTable] = useState('');
    const [isLoading, setLoadingState] = useState(false);

    const [selectedClaimLOB, setSelectedClaimLOBe] = useState(undefined);
    const [searchFilter, setSearchFilter] = useState(undefined);
    const [isClosed, setIsClosed] = useState(false);
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const getVendors = useCallback(
        (claim) => {
            if (!claim.vendors) {
                return [];
            }
            const normalizedLobCode = getNormalizedLOBName(claim.lineOfBusinessCode);
            const usedVendorTypes = vendorTypesForPolicyHolderByLob[normalizedLobCode] || [];

            const policyHolderVendors = claim.vendors.filter((vendor) => {
                let type = vendor.contactDTO.subtype;
                if (normalizedLobCode === 'ho') {
                    type = vendor.contactDTO.contactType;
                }
                return usedVendorTypes.indexOf(type) >= 0;
            });
            return policyHolderVendors;
        },
        [vendorTypesForPolicyHolderByLob]
    );

    const processClaimsData = useCallback(
        (claimsData) => {
            if (!_.isEmpty(claimsData)) {
                let claimsArrayResult = claimsData;
                claimsArrayResult = claimsArrayResult.map((claimInfo) => {
                    const claims = {
                        product: claimInfo.lineOfBusinessCode,
                        claimNumber: claimInfo.claimNumber,
                        dateOfLoss: intl.formatDate(new Date(claimInfo.lossDate), { year: 'numeric', month: 'short', day: 'numeric' }),
                        status: translator({
                            id: `typekey.ClaimState.${claimInfo.claimState}`,
                            defaultMessage: claimInfo.claimState
                        }),
                        policyNumber: claimInfo.policyNumber,
                        vendors: getVendors(claimInfo)
                    };
                    return claims;
                });
                return claimsArrayResult;
            }
            return [];
        },
        [getVendors, intl, translator]
    );

    const getClaims = useCallback(
        (query) => {
            const claimsPromise = ClaimService.getClaimSummaries(query, {}, authHeader);
            return claimsPromise
                .then((responseData) => {
                    const data = processClaimsData(responseData.items);
                    setClaimDataTable(data);
                })
                .finally(() => {
                    setLoadingState(false);
                });
        },
        [authHeader, processClaimsData]
    );

    const loadClaimData = useCallback(
        (filters) => {
            setLoadingState(true);

            let claimStates = isClosed ? ['open', 'draft', 'closed'] : ['open', 'draft'];
            let policyType = selectedClaimLOB !== translator(claimsMessages.allClaims)
                ? selectedClaimLOB
                : undefined;
            let queryText = searchFilter;

            if (!_.isUndefined(filters)) {
                if (!_.isUndefined(filters.isClosed)) {
                    claimStates = filters.isClosed
                        ? ['open', 'draft', 'closed']
                        : ['open', 'draft'];
                }
                if (!_.isUndefined(filters.policyType)) {
                    policyType = filters.policyType !== translator(claimsMessages.allClaims)
                        ? filters.policyType
                        : undefined;
                }
                queryText = !_.isUndefined(filters.queryText) ? filters.queryText : queryText;
            }

            const query = {
                claimStates: claimStates,
                policyType: policyType,
                queryText: queryText
            };

            return getClaims(query);
        },
        [selectedClaimLOB, searchFilter, isClosed, getClaims, translator]
    );

    useEffect(() => {
        loadClaimData();
        setSelectedClaimLOBe(translator(claimsMessages.allClaims));

        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterChange = useCallback(
        (value, id) => {
            if (id === 'LOBFilter' && value !== 'Filters') {
                setSelectedClaimLOBe(value);
                loadClaimData({ policyType: value });
            }
            if (id === 'searchFilter') {
                loadClaimData({ queryText: value });
            }
            if (id === 'closedFilter') {
                setIsClosed(value);
                loadClaimData({ isClosed: value });
            }
        },
        [loadClaimData]
    );

    const getCell = useCallback(
        (item, index, property) => {
            const toolTipMessage = {
                product: translator(claimsMessages.line),
                dateOfLoss: translator(claimsMessages.dateOfLossClaimDetail)
            };
            return <span title={toolTipMessage[property.id]}>{item[property.id]}</span>;
        },
        [translator]
    );

    const getStatusCell = useCallback(
        (item, index, property) => {
            const titleMessage = translator(claimsMessages.claimStatus);
            const status = item[property.id];
            let typeStatus = 'success';

            if (status === translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'draft' })) {
                typeStatus = 'warning';
            } else if (status === translator({ id: 'typekey.ClaimState.closed', defaultMessage: 'closed' })) {
                typeStatus = 'neutral';
            }

            return (
                <InfoLabel type={typeStatus} size="small" title={titleMessage[property.id]}>
                    {item[property.id]}
                </InfoLabel>
            );
        },
        [translator]
    );

    const getVendorsCell = useCallback((item, index, property) => {
        const vendors = item[property.id];
        return <VendorLinkComponent id="vendorsCell" vendors={vendors} />;
    }, []);

    const getLink = useCallback(
        (item, index, property) => {
            const toolTipMessage = {
                policyNumber: translator(claimsMessages.policyNumberClaimDetail),
                claimNumber: translator(claimsMessages.claimNumber)
            };
            if (
                property.id === 'claimNumber'
                && item.status
                    === translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'draft' })
            ) {
                const redirectpath = {
                    pathname: '/fnol-select-policy',
                    state: {
                        claimNumber: item[property.id],
                        redirectPath: '/claims',
                        claimStatus: item.status
                    }
                };
                return (
                    <Link
                        to={redirectpath}
                        className={styles.gwClaimsLinkContent}
                        title={toolTipMessage[property.id]}
                    >
                        {item[property.id]}
                    </Link>
                );
            }
            const redirectRoute = property.id === 'policyNumber'
                ? `/claims/policy/${item.claimNumber}`
                : `/claims/${item[property.id]}`;
            const redirectPath = {
                pathname: redirectRoute,
                state: {
                    claimNumber: item[property.id],
                    redirectPath: '/claims',
                    claimStatus: item.status
                }
            };
            return (
                <Link
                    to={redirectPath}
                    className={styles.gwClaimsLinkContent}
                    title={toolTipMessage[property.id]}
                >
                    {item[property.id]}
                </Link>
            );
        },
        [translator]
    );

    const getProductIcon = useCallback((item) => {
        const icon = LobIconUtil.getMaterialIcon(item.product);
        return <Icon icon={icon} title={item.product} key={item.product} />;
    }, []);

    const handleFileClick = useCallback(() => {
        return history.push({
            pathname: '/fnol-select-policy',
            state: {
                redirectPath: '/claims'
            }
        });
    }, [history]);

    const overrides = {
        claimListTableLoader: {
            loaded: !isLoading
        },
        claimTable: {
            data: claimDataTable,
            visible: !isLoading
        },
        searchFilter: {
            onValueChange: setSearchFilter,
            value: searchFilter,
            onEnter: () => {
                handleFilterChange(searchFilter, 'searchFilter');
            }
        },
        noDataMessage: {
            visible: _.isEmpty(claimDataTable) && !isLoading,
            content: translator(claimsMessages.noClaimsResult)
        },
        claimLobOptionsMenu: {
            values: [translator(claimsMessages.allClaims), ...claimsFilterLOBOptions],
            handleFilterChange: handleFilterChange,
            isClosed: isClosed,
            selectedClaimLOB: selectedClaimLOB,
            isShowClosedClaimFilter: true
        },
        fileClaimButton: {
            to: handleFileClick
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell: getCell,
            getLink: getLink,
            getProductIcon: getProductIcon,
            getVendorsCell: getVendorsCell,
            getStatusCell: getStatusCell,
            handleFilterValue: handleFilterChange,
            handleClosedClaims: setIsClosed,
            handleFileClick: handleFileClick
        },
        resolveComponentMap: {
            claimloboptionsmenu: ClaimLobOptionsMenu
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            {...resolvers} />
    );
}

export default withRouter(ClaimListComponent);
