import { MetadataContent } from '@jutro/uiconfig';
import metadata from './WMICBulletPointComponent.metadata.json5';
import styles from './WMICBulletPointComponent.module.scss';


import React from 'react';


export default function WMICBulletPointComponent(props) {
    const { bulletPoints } = props;

    const overrideProps = {
        bulletpoints: {
            data: bulletPoints
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}
