/* Guidewire private */
!function (e, t) {
    if ("object" == typeof exports && "object" == typeof module)
        module.exports = t(require("lodash"), require("@jutro/events"), require("uuid"), require("gw-platform-events-js"), require("customer-transportservice-config"), require("app-config"));
    else if ("function" == typeof define && define.amd)
        define(["lodash", "@jutro/events", "uuid", "gw-platform-events-js", "customer-transportservice-config", "app-config"], t);
    else {
        var r = "object" == typeof exports ? t(require("lodash"), require("@jutro/events"), require("uuid"), require("gw-platform-events-js"), require("customer-transportservice-config"), require("app-config")) : t(e.lodash, e["@jutro/events"], e.uuid, e["gw-platform-events-js"], e["customer-transportservice-config"], e["app-config"]);
        for (var n in r)
            ("object" == typeof exports ? exports : e)[n] = r[n]
    }
}
(window, (function (e, t, r, n, o, i) {
        return function (e) {
            var t = {};
            function r(n) {
                if (t[n])
                    return t[n].exports;
                var o = t[n] = {
                    i: n,
                    l: !1,
                    exports: {}
                };
                return e[n].call(o.exports, o, o.exports, r),
                o.l = !0,
                o.exports
            }
            return r.m = e,
            r.c = t,
            r.d = function (e, t, n) {
                r.o(e, t) || Object.defineProperty(e, t, {
                    enumerable: !0,
                    get: n
                })
            },
            r.r = function (e) {
                "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
                    value: "Module"
                }),
                Object.defineProperty(e, "__esModule", {
                    value: !0
                })
            },
            r.t = function (e, t) {
                if (1 & t && (e = r(e)), 8 & t)
                    return e;
                if (4 & t && "object" == typeof e && e && e.__esModule)
                    return e;
                var n = Object.create(null);
                if (r.r(n), Object.defineProperty(n, "default", {
                        enumerable: !0,
                        value: e
                    }), 2 & t && "string" != typeof e)
                    for (var o in e)
                        r.d(n, o, function (t) {
                            return e[t]
                        }
                            .bind(null, o));
                return n
            },
            r.n = function (e) {
                var t = e && e.__esModule ? function () {
                    return e.default
                }
                 : function () {
                    return e
                };
                return r.d(t, "a", t),
                t
            },
            r.o = function (e, t) {
                return Object.prototype.hasOwnProperty.call(e, t)
            },
            r.p = "",
            r(r.s = 6)
        }
        ([function (t, r) {
                    t.exports = e
                }, function (e, r) {
                    e.exports = t
                }, function (e, t) {
                    e.exports = r
                }, function (e, t) {
                    e.exports = n
                }, function (e, t) {
                    e.exports = o
                }, function (e, t) {
                    e.exports = i
                }, function (e, t, r) {
                    "use strict";
                    function n(e, t, r) {
                        return t in e ? Object.defineProperty(e, t, {
                            value: r,
                            enumerable: !0,
                            configurable: !0,
                            writable: !0
                        }) : e[t] = r,
                        e
                    }
                    function o(e, t) {
                        var r = Object.keys(e);
                        if (Object.getOwnPropertySymbols) {
                            var n = Object.getOwnPropertySymbols(e);
                            t && (n = n.filter((function (t) {
                                            return Object.getOwnPropertyDescriptor(e, t).enumerable
                                        }))),
                            r.push.apply(r, n)
                        }
                        return r
                    }
                    function i(e) {
                        for (var t = 1; t < arguments.length; t++) {
                            var r = null != arguments[t] ? arguments[t] : {};
                            t % 2 ? o(Object(r), !0).forEach((function (t) {
                                    n(e, t, r[t])
                                })) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(r)) : o(Object(r)).forEach((function (t) {
                                    Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(r, t))
                                }))
                        }
                        return e
                    }
                    function s(e, t) {
                        if (!(e instanceof t))
                            throw new TypeError("Cannot call a class as a function")
                    }
                    function a(e, t) {
                        for (var r = 0; r < t.length; r++) {
                            var n = t[r];
                            n.enumerable = n.enumerable || !1,
                            n.configurable = !0,
                            "value" in n && (n.writable = !0),
                            Object.defineProperty(e, n.key, n)
                        }
                    }
                    function u(e, t, r) {
                        return t && a(e.prototype, t),
                        r && a(e, r),
                        e
                    }
                    r.r(t),
                    r.d(t, "JsonRPCService", (function () {
                            return q
                        })),
                    r.d(t, "BaseTransportService", (function () {
                            return y
                        })),
                    r.d(t, "TransportService", (function () {
                            return S
                        }));
                    var c = r(1),
                    f = r(2),
                    p = r(0),
                    d = r.n(p),
                    l = r(4),
                    v = r.n(l),
                    g = r(3),
                    m = r(5),
                    b = r.n(m);
                    function j() {
                        var e,
                        t,
                        r = (e = v.a.stickySessionName, d.a.isString(e) ? e : "X_GWRE_SESSION");
                        return sessionStorage.getItem(r) ? t = sessionStorage.getItem(r) : (t = function (e) {
                                var t = Object(f.v4)();
                                if (d.a.isFunction(e)) {
                                    var r = e();
                                    d.a.isString(r) && (t = r)
                                }
                                return t
                            }
                                (v.a.customerGenerateSessionID), sessionStorage.setItem(r, t)),
                        t
                    }
                    var O = "application/json",
                    y = {
                        send: function (e, t, r) {
                            var n,
                            o,
                            s = j(),
                            a = {
                                Accept: O,
                                "x-gwre-session": s,
                                "Accept-Language": (n = localStorage.getItem("selectedLanguage"), o = d.a.get(b.a, "localisation.languageVariant"), o ? "".concat(n, "_").concat(o) : n)
                            },
                            u = Object(f.v4)(),
                            c = {
                                method: "POST",
                                headers: Object.assign(a, t),
                                body: r
                            };
                            return g.events.emit("backend::requestsent", i({
                                    endpoint: e,
                                    requestID: u
                                }, c)),
                            fetch(e, c).then((function (t) {
                                    var r = t.headers.get("Content-Type");
                                    if (!t.ok) {
                                        if (401 === t.status)
                                            return g.events.emit("backend::unauthorizedrequest", i({
                                                    endpoint: e,
                                                    requestID: u
                                                }, c)), Promise.reject({
                                                status: 401
                                            });
                                        if (r && r.includes(O))
                                            return t.json().then((function (e) {
                                                    return Promise.reject(d.a.pick(e, "id", "error"))
                                                }));
                                        var n = "".concat(t.status, " (").concat(t.statusText, ")");
                                        return g.events.emit("backend::responsefailure", {
                                            error: n,
                                            requestID: u,
                                            endpoint: e,
                                            requestParams: c
                                        }),
                                        Promise.reject(new Error(n))
                                    }
                                    return t.json()
                                })).then((function (t) {
                                    return g.events.emit("backend::responsesuccess", {
                                        data: t,
                                        requestID: u,
                                        endpoint: e,
                                        requestParams: c
                                    }),
                                    d.a.pick(t, "id", "result")
                                }))
                        }
                    },
                    h = function () {
                        function e() {
                            s(this, e)
                        }
                        return u(e, null, [{
                                    key: "send",
                                    value: function (t, r, n) {
                                        var o = (new Date).getTime();
                                        return y.send(t, n, r).then((function (e) {
                                                return e.result
                                            })).catch((function (t) {
                                                var r = t.error,
                                                n = void 0 === r ? {}
                                                 : r;
                                                return Promise.reject(e.getFormatedErrorResponse(n, o))
                                            }))
                                    }
                                }, {
                                    key: "getFormatedErrorResponse",
                                    value: function () {
                                        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                                        t = arguments.length > 1 ? arguments[1] : void 0,
                                        r = (new Date).getTime() - t;
                                        return {
											data: e.data,
                                            baseError: e.message,
                                            errorCode: d.a.get(e, "data.appError", "GW_ERROR"),
                                            appErrorCode: d.a.get(e, "data.appErrorCode", 101),
                                            isTimeout: 0 === e.status && r < 9e4,
                                            isUnauthenticated: 401 === e.status
                                        }
                                    }
                                }, {
                                    key: "isSessionExpiredReason",
                                    value: function (e) {
                                        return !0 === e.isTimeout
                                    }
                                }, {
                                    key: "isUserUnauthenticatedReason",
                                    value: function (e) {
                                        return !0 === e.isUnauthenticated
                                    }
                                }
                            ]),
                        e
                    }
                    (),
                    S = h,
                    P = 0,
                    E = Object(f.v4)(),
                    w = {
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    T = function () {
                        function e() {
                            s(this, e)
                        }
                        return u(e, null, [{
                                    key: "send",
                                    value: function (e, t, r) {
                                        var n = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {};
                                        Object(c.publish)(c.JUTRO_TOPICS.FETCH_REQUEST, {
                                            serviceEndPoint: e,
                                            method: t,
                                            params: r
                                        }),
                                        P += 1;
                                        var o = "".concat(E, "_").concat(P),
                                        s = JSON.stringify({
                                            id: "".concat(o),
                                            jsonrpc: "2.0",
                                            method: t,
                                            params: r
                                        }),
                                        a = {
                                            "X-B3-TraceId": "".concat(o),
                                            "X-B3-SpanId": "".concat(o),
                                            "X-B3-Sampled": 0
                                        };
                                        return S.send(e, s, i(i(i({}, w), n), a)).then((function (n) {
                                                return Object(c.publish)(c.JUTRO_TOPICS.FETCH_RESPONSE, {
                                                    serviceEndPoint: e,
                                                    method: t,
                                                    params: r,
                                                    response: n
                                                }),
                                                n
                                            })).catch((function (n) {
                                                throw Object(c.publish)(c.JUTRO_TOPICS.FETCH_ERROR, {
                                                    serviceEndPoint: e,
                                                    method: t,
                                                    params: r,
                                                    error: n
                                                }),
                                                (S.isSessionExpiredReason(n) || S.isUserUnauthenticatedReason(n)) && window.location.reload(!0),
                                                n.gwInfo = {
                                                    serviceEndPoint: e,
                                                    method: t,
                                                    params: r
                                                },
                                                n
                                            }))
                                    }
                                }
                            ]),
                        e
                    }
                    (),
                    q = T
                }
            ])
    }));
