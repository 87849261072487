import React, {
    useContext, useCallback, Fragment
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import { IntlContext, useTranslator } from '@jutro/locale';
import metadata from './CoverageDetailsComponent.metadata.json5';
import messages from '../../Claims.messages';

function CoverageDetailsComponent(props) {
    const { coverages, coveragesHeader } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const getNameCell = useCallback(
        (item, index, property) => {
            return <span title={translator(messages.coverage)}>{item[property.id]}</span>;
        },
        [translator]
    );

    const getLimitCell = useCallback(
        (item) => {
            return (
                <div title={translator(messages.limit)}>
                    {!_.isNil(item.incidentLimit) && (
                        <div>{translator(messages.perAccident)}</div>
                    )}
                    {!_.isNil(item.exposureLimit) && (
                        <div>{translator(messages.perPerson)}</div>
                    )}
                    {_.isNil(item.incidentLimit) && _.isNil(item.exposureLimit) && <div>-</div>}
                </div>
            );
        },
        [translator]
    );

    const getValueCell = useCallback((item) => {
        return (
            <Fragment>
                {!_.isNil(item.incidentLimit) && (
                    <div>
                        {
                            intl.formatNumber(
                                item.incidentLimit.amount,
                                {
                                    style: 'currency',
                                    currency: item.incidentLimit.currency,
                                    currencyDisplay: 'code'
                                }
                            )
                        }
                    </div>
                )}
                {!_.isNil(item.exposureLimit) && (
                    <div>
                        {
                            intl.formatNumber(
                                item.exposureLimit.amount,
                                {
                                    style: 'currency',
                                    currency: item.exposureLimit.currency,
                                    currencyDisplay: 'code'
                                }
                            )
                        }
                    </div>
                )}
            </Fragment>
        );
    }, [intl]);

    const getDeductableCell = useCallback((item) => {
        return (
            !_.isNil(item.deductible) && (
                <div>
                    {
                        intl.formatNumber(
                            item.deductible.amount,
                            {
                                style: 'currency',
                                currency: item.deductible.currency,
                                currencyDisplay: 'code'
                            }
                        )
                    }
                </div>
            )
        );
    }, [intl]);

    if (_.isEmpty(coverages)) {
        return null;
    }
    const overrides = {
        coverageDetails: {
            data: coverages
        },
        coverageSectionTitle: {
            content: coveragesHeader
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            getNameCell,
            getLimitCell,
            getValueCell,
            getDeductableCell
        }
    };
    return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
}

CoverageDetailsComponent.propTypes = {
    coverages: PropTypes.shape([]).isRequired,
    coveragesHeader: PropTypes.string.isRequired
};

export default CoverageDetailsComponent;
