/* eslint-disable max-len */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import {
    Button, ModalNext, ModalHeader, ModalBody, ModalFooter
} from '@jutro/components';
import { WalletService } from 'gw-capability-policy';
import { CONSTANTS } from 'wmic-portals-utils-js';
import userProfileMessages from '../WMICInsuranceCardModal.messages';
import messages from './WMICIOSStepsModal.messages';
import styles from './WMICIOSStepsModal.module.scss';

const WMICIOSStepsModal = (props) => {
    const {
        isOpen, onReject, onResolve, policyNumber, termNumber, vehicles, authHeader
    } = props;
    const translator = useTranslator();
    const [disableDownloadIdCard, updateDisableDownloadIdCard] = useState(false);

    const downloadIdCard = (vehicleNumber) => {
        updateDisableDownloadIdCard(true);
        const reader = new window.FileReader();
        WalletService.getIdCard(policyNumber, termNumber, vehicleNumber, authHeader)
            .then((response) => response.blob())
            .then((response) => {
                if (response) {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(response);
                        updateDisableDownloadIdCard(false);
                        return;
                    }
                    updateDisableDownloadIdCard(false);
                    reader.readAsDataURL(response);
                } else {
                    // failed
                    updateDisableDownloadIdCard(false);
                    onResolve(CONSTANTS.ERROR);
                }
            });

        reader.onloadend = () => {
            const url = reader.result;
            const anchor = document.createElement('a');
            anchor.setAttribute('href', url);
            anchor.setAttribute('download', 'pass.pkpass');
            anchor.style.display = 'none';
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
            updateDisableDownloadIdCard(false);
        };
    };

    const handleClose = () => {
        onReject();
    };
    
    return (
        <ModalNext isOpen={isOpen} onRequestClose={handleClose} shouldCloseOnEsc>
            <ModalHeader
                title={translator(userProfileMessages.insuranceIDCard)}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: true
                    }
                }}
            />
            <ModalBody>
                <div className={styles['digital-wallet']}>
                    <h3 className={styles['ww-editorial-subhead']}>{translator(messages.followTheseSteps)}</h3>
                    <ol className={styles['wmic-ol']}>
                        <li>{translator(messages.onYourDevice)}</li>
                        <li>{translator(messages.idCardShouldAutomatically)}</li>
                        <li>{translator(messages.youShouldSeeAPreview)}</li>
                        <li>{translator(messages.theCardIsNowAdded)}</li>
                    </ol>
                    <h3 className={styles['ww-editorial-subhead']}>{translator(messages.selectTheVehicle)}</h3>
                    <div className="ww-poi-buttons">
                        {vehicles.map(({ year, make, model, vehicleNumber }) => (
                                <div>
                                    <Button
                                        onClick={() => downloadIdCard(vehicleNumber)}
                                        disabled={disableDownloadIdCard}
                                        trackButtonIdentifier={translator(messages.downloadIOSIdCardTrackButtonIdentifier)}
                                    >
                                        {`${year} ${make} ${model} (${vehicleNumber})`}
                                    </Button>
                                    <br />
                                </div>
                            ))}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => { onReject(); }}
                    type="primary"
                    className="ww-btn-primary"
                >
                    {translator(messages.close)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
};

WMICIOSStepsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    policyNumber: PropTypes.string.isRequired,
    termNumber: PropTypes.number.isRequired,
    vehicles: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default WMICIOSStepsModal;