/* Guidewire private */
!function (e, t) {
    if ("object" == typeof exports && "object" == typeof module)
        module.exports = t(require("lodash"), require("@xengage/gw-portals-util-js"), require("@xengage/gw-portals-metadata-js"), require("customer-viewmodel-config"), require("@xengage/gw-portals-viewmodel-utils-js"), require("google-libphonenumber"));
    else if ("function" == typeof define && define.amd)
        define(["lodash", "@xengage/gw-portals-util-js", "@xengage/gw-portals-metadata-js", "customer-viewmodel-config", "@xengage/gw-portals-viewmodel-utils-js", "google-libphonenumber"], t);
    else {
        var n = "object" == typeof exports ? t(require("lodash"), require("@xengage/gw-portals-util-js"), require("@xengage/gw-portals-metadata-js"), require("customer-viewmodel-config"), require("@xengage/gw-portals-viewmodel-utils-js"), require("google-libphonenumber")) : t(e.lodash, e["@xengage/gw-portals-util-js"], e["@xengage/gw-portals-metadata-js"], e["customer-viewmodel-config"], e["@xengage/gw-portals-viewmodel-utils-js"], e["google-libphonenumber"]);
        for (var r in n)
            ("object" == typeof exports ? exports : e)[r] = n[r]
    }
}
(window, (function (e, t, n, r, a, i) {
        return function (e) {
            var t = {};
            function n(r) {
                if (t[r])
                    return t[r].exports;
                var a = t[r] = {
                    i: r,
                    l: !1,
                    exports: {}
                };
                return e[r].call(a.exports, a, a.exports, n),
                a.l = !0,
                a.exports
            }
            return n.m = e,
            n.c = t,
            n.d = function (e, t, r) {
                n.o(e, t) || Object.defineProperty(e, t, {
                    enumerable: !0,
                    get: r
                })
            },
            n.r = function (e) {
                "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
                    value: "Module"
                }),
                Object.defineProperty(e, "__esModule", {
                    value: !0
                })
            },
            n.t = function (e, t) {
                if (1 & t && (e = n(e)), 8 & t)
                    return e;
                if (4 & t && "object" == typeof e && e && e.__esModule)
                    return e;
                var r = Object.create(null);
                if (n.r(r), Object.defineProperty(r, "default", {
                        enumerable: !0,
                        value: e
                    }), 2 & t && "string" != typeof e)
                    for (var a in e)
                        n.d(r, a, function (t) {
                            return e[t]
                        }
                            .bind(null, a));
                return r
            },
            n.n = function (e) {
                var t = e && e.__esModule ? function () {
                    return e.default
                }
                 : function () {
                    return e
                };
                return n.d(t, "a", t),
                t
            },
            n.o = function (e, t) {
                return Object.prototype.hasOwnProperty.call(e, t)
            },
            n.p = "",
            n(n.s = 6)
        }
        ([function (t, n) {
                    t.exports = e
                }, function (e, n) {
                    e.exports = t
                }, function (e, t) {
                    e.exports = n
                }, function (e, t) {
                    e.exports = r
                }, function (e, t) {
                    e.exports = a
                }, function (e, t) {
                    e.exports = i
                }, function (e, t, n) {
                    "use strict";
                    n.r(t),
                    n.d(t, "ViewModel", (function () {
                            return M
                        })),
                    n.d(t, "ViewModelServiceFactory", (function () {
                            return ke
                        })),
                    n.d(t, "ViewModelUtil", (function () {
                            return x
                        })),
                    n.d(t, "PhoneUtil", (function () {
                            return De
                        }));
                    var r = n(0),
                    a = n.n(r);
                    function i(e, t) {
                        (null == t || t > e.length) && (t = e.length);
                        for (var n = 0, r = new Array(t); n < t; n++)
                            r[n] = e[n];
                        return r
                    }
                    function o(e, t) {
                        if (e) {
                            if ("string" == typeof e)
                                return i(e, t);
                            var n = Object.prototype.toString.call(e).slice(8, -1);
                            return "Object" === n && e.constructor && (n = e.constructor.name),
                            "Map" === n || "Set" === n ? Array.from(e) : "Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? i(e, t) : void 0
                        }
                    }
                    function u(e, t) {
                        var n;
                        if ("undefined" == typeof Symbol || null == e[Symbol.iterator]) {
                            if (Array.isArray(e) || (n = o(e)) || t && e && "number" == typeof e.length) {
                                n && (e = n);
                                var r = 0,
                                a = function () {};
                                return {
                                    s: a,
                                    n: function () {
                                        return r >= e.length ? {
                                            done: !0
                                        }
                                         : {
                                            done: !1,
                                            value: e[r++]
                                        }
                                    },
                                    e: function (e) {
                                        throw e
                                    },
                                    f: a
                                }
                            }
                            throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.")
                        }
                        var i,
                        u = !0,
                        s = !1;
                        return {
                            s: function () {
                                n = e[Symbol.iterator]()
                            },
                            n: function () {
                                var e = n.next();
                                return u = e.done,
                                e
                            },
                            e: function (e) {
                                s = !0,
                                i = e
                            },
                            f: function () {
                                try {
                                    u || null == n.return || n.return()
                                }
                                finally {
                                    if (s)
                                        throw i
                                }
                            }
                        }
                    }
                    var s = n(1);
                    function c(e, t, n) {
                        return {
                            container: e,
                            metadata: t,
                            pathToItem: {
                                asString: n
                            }
                        }
                    }
                    var l = {
                        build: function (e, t, n) {
                            return {
                                forChild: function (r) {
                                    return function (e, t, n, r) {
                                        var i = new Array(r ? r.owners.length + 1 : 1);
                                        if (r)
                                            for (var o = a.a.isNumber(n) ? "[".concat(n, "]") : ".".concat(n), u = 0; u < r.owners.length; u += 1) {
                                                var s = r.owners[u];
                                                i[u] = c(s.container, s.metadata, s.pathToItem.asString + o)
                                            }
                                        return i[i.length - 1] = c(e, t, a.a.isNumber(n) ? "[".concat(n, "]") : n),
                                        function (e) {
                                            return {
                                                owners: e,
                                                get parent() {
                                                    var e = this.owners.length;
                                                    if (0 !== e)
                                                        return this.owners[e - 1].container
                                                }
                                            }
                                        }
                                        (i)
                                    }
                                    (e, t, r, n)
                                }
                            }
                        },
                        emptyChain: {
                            owners: [],
                            parent: void 0
                        }
                    },
                    f = n(2),
                    p = n.n(f),
                    d = ["root", "property", "collectionElement"];
                    function y(e, t) {
                        return t.kind = e,
                        d.forEach((function (n) {
                                t["is".concat(s.StringUtil.capitalizeFirstLetter(n))] = e === n
                            })),
                        Object.freeze(t)
                    }
                    var g = Object.freeze({
                        ROOT: y("root", {}),
                        COLLECTION_ELT: y("collectionElement", {}),
                        property: function (e) {
                            return y("property", {
                                property: e
                            })
                        }
                    });
                    function v(e, t, n, r, a, i, o) {
                        var u,
                        s = "class" !== (u = r).kind ? f.NodeMetadataDefinition.EMPTY : u.typeInfo.metadata;
                        return Object.freeze({
                            step: e,
                            typeMeta: s,
                            inheritedMeta: t,
                            elementMetadata: f.NodeMetadataDefinition.merge(t, s),
                            xCenter: n,
                            valueType: r,
                            externalContext: a,
                            readOnly: i,
                            maskInfo: o
                        })
                    }
                    var m = {
                        root: function (e, t, n) {
                            var r = {
                                kind: "class",
                                name: t.typeName,
                                typeInfo: t
                            };
                            return v(g.ROOT, f.NodeMetadataDefinition.EMPTY, e, r, n)
                        },
                        property: function (e, t) {
                            return v(g.property(t.name), t.metadata, e, t.type, null, t.readOnly, t.maskInfo)
                        },
                        collectionElement: function (e, t) {
                            return v(g.COLLECTION_ELT, f.NodeMetadataDefinition.EMPTY, e, t)
                        }
                    },
                    h = ["value", "aspects", "_propertyName", "_parent", "_ancestorChain", "_metadataInfo", "_accessorCode", "_aspects"],
                    b = h.concat(["_aspectFactories"]);
                    function w(e) {
                        return !(!e || !a.a.isObject(e)) && h.every((function (t) {
                                return t in e
                            }))
                    }
                    var x = {
                        isViewModelNode: w,
                        unpackIfViewModelNode: function (e) {
                            return w(e) ? e.value : e
                        },
                        findRoot: function e(t) {
                            return t._parent ? e(t._parent) : t
                        },
                        getNonVMPath: function (e) {
                            return e.replace(/\.children|\.value/g, "")
                        },
                        cleanse: function (e) {
                            return w(e) ? e.value : e
                        },
                        iterateOverChildNodes: function (e, t) {
                            Object.keys(e).filter((function (e) {
                                    return !b.includes(e)
                                })).filter((function (t) {
                                    return w(e[t])
                                })).forEach((function (n) {
                                    return t(e[n], n)
                                }))
                        }
                    },
                    C = function (e, t) {
                        function n() {
                            e.apply(this, arguments),
                            this._vmElements = [],
                            this._lastChildren = Object.freeze([])
                        }
                        return n.prototype = Object.create(e.prototype),
                        n.prototype.constructor = n,
                        n.prototype.getElement = function (e) {
                            if (!(e >= this.value.length))
                                return this._vmElements[e] || this._syncVmListWithModel(), this._vmElements[e]
                        },
                        n.prototype.getLastElement = function () {
                            if (0 !== this.value.length)
                                return this._vmElements[this.value.length - 1] || this._syncVmListWithModel(), this._vmElements[this.value.length - 1]
                        },
                        n.prototype.findFirstElement = function (t, n) {
                            if (!(n >= this.value.length - 1)) {
                                t instanceof e && (t = t.value),
                                n = void 0 === n ? 0 : n;
                                var r = this.value.indexOf(t, n);
                                return this._vmElements[r] || this._syncVmListWithModel(),
                                this._vmElements[r]
                            }
                        },
                        n.prototype.setElement = function (t, n) {
                            if (t >= this.value.length)
                                throw new Error("Index provided is outside the bounds of the list. Use the push or unshift methods to add elements");
                            n instanceof e && (n = n.value),
                            this.value[t] = n
                        },
                        n.prototype.pushElement = function (e) {
                            return x.isViewModelNode(e) && (e = e.value),
                            this.value.push(e),
                            this.findFirstElement(e)
                        },
                        Object.defineProperty(n.prototype, "length", {
                            get: function () {
                                return this.value ? this.value.length : 0
                            }
                        }),
                        n.prototype.forEach = function (e, t) {
                            if ("function" != typeof e)
                                throw new TypeError("".concat(e, " is not a function"));
                            this._syncVmListWithModel(),
                            this._vmElements.forEach(e, t)
                        },
                        n.prototype.filter = function (e, t) {
                            if ("function" != typeof e)
                                throw new TypeError("".concat(e, " is not a function"));
                            return this._syncVmListWithModel(),
                            this._vmElements.filter(e, t)
                        },
                        Object.defineProperty(n.prototype, "children", {
                            get: function () {
                                return this.length === this._lastChildren.length || (this._syncVmListWithModel(), this._lastChildren = Object.freeze(this._vmElements.slice(0, this.length))),
                                this._lastChildren
                            }
                        }),
                        Object.defineProperty(n.prototype, "_dtoName", {
                            get: function () {
                                return this._metadataInfo.valueType.elementType.name
                            }
                        }),
                        Object.defineProperty(n.prototype, "_xCenter", {
                            get: function () {
                                return this._metadataInfo.xCenter
                            }
                        }),
                        n.prototype._syncVmListWithModel = function () {
                            for (var e = this._metadataInfo, n = l.build(this, this._metadataInfo, this._ancestorChain), r = m.collectionElement(e.xCenter, e.valueType.elementType), a = this._vmElements.length; a < this.value.length; a += 1)
                                this._vmElements.push(t(this, a, r, n.forChild(a)))
                        },
                        n
                    };
                    function T(e, t, n) {
                        return t in e ? Object.defineProperty(e, t, {
                            value: n,
                            enumerable: !0,
                            configurable: !0,
                            writable: !0
                        }) : e[t] = n,
                        e
                    }
                    function _(e, t) {
                        var n = Object.keys(e);
                        if (Object.getOwnPropertySymbols) {
                            var r = Object.getOwnPropertySymbols(e);
                            t && (r = r.filter((function (t) {
                                            return Object.getOwnPropertyDescriptor(e, t).enumerable
                                        }))),
                            n.push.apply(n, r)
                        }
                        return n
                    }
                    function O(e) {
                        for (var t = 1; t < arguments.length; t++) {
                            var n = null != arguments[t] ? arguments[t] : {};
                            t % 2 ? _(Object(n), !0).forEach((function (t) {
                                    T(e, t, n[t])
                                })) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(n)) : _(Object(n)).forEach((function (t) {
                                    Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(n, t))
                                }))
                        }
                        return e
                    }
                    var E = function (e, t, n) {
                        function r(e, r, a) {
                            var u;
                            this._ancestorChain = r,
                            this._metadataInfo = e,
                            this._accessorCode = a,
                            this._aspectFactories = t,
                            this._aspects = {},
                            "class" === e.valueType.kind && e.valueType.typeInfo.metaType.isTypelist && (this.get = i, this.set = o);
                            for (var s = 0; s < t.length; s += 1) {
                                var c = t[s].getAspectProperties(this, this._metadataInfo, this._ancestorChain, n);
                                if (c)
                                    for (u in c)
                                        u in this._aspects && delete this._aspects[u], Object.defineProperty(this._aspects, u, O(O({}, c[u]), {}, {
                                                configurable: !0
                                            }))
                            }
                        }
                        function i() {
                            var e = r.prototype.get.call(this);
                            return a.a.isString(e) ? a.a.find(this._metadataInfo.valueType.typeInfo.codes, {
                                code: e
                            }) : e
                        }
                        function o(e) {
                            if (e instanceof r && (e = e.value), e && !a.a.isString(e)) {
                                var t = this._metadataInfo.valueType.typeInfo.getCode(e.code);
                                i = t,
                                o = (n = e).code === i.code,
                                u = n.priority === i.priority,
                                s = n.typelist === i.typelist,
                                o && u && s || (console.error("Critical MVC violation: attempt to provide non-genuine typecode"), e = t),
                                e = e.code
                            }
                            var n,
                            i,
                            o,
                            u,
                            s;
                            r.prototype.set.call(this, e)
                        }
                        return r.prototype = {
                            get: function () {
                                return this._ancestorChain.parent ? this._ancestorChain.parent.value[this._propertyName] : e
                            },
                            set: function (t) {
                                t instanceof r && (t = t.value),
                                this._ancestorChain.parent ? this._ancestorChain.parent.value[this._propertyName] = t : e = t
                            },
                            get value() {
                                return this.get()
                            },
                            set value(e) {
                                this.set(e)
                            },
                            get aspects() {
                                return this._aspects
                            },
                            get _propertyName() {
                                return this._accessorCode
                            },
                            get _parent() {
                                return this._ancestorChain.parent
                            },
                            get _dtoName() {
                                return this._metadataInfo.valueType.name
                            },
                            get _xCenter() {
                                return this._metadataInfo.xCenter
                            },
                            get _context() {
                                return this._metadataInfo.externalContext
                            },
                            get baseVMNode() {
                                return this._ancestorChain.owners[0]
                            }
                        },
                        r
                    },
                    N = function (e, t) {
                        return {
                            create: function (n, r) {
                                return function (n, r) {
                                    var a = E(n, e, t),
                                    i = C(a, p),
                                    o = new a(r, l.emptyChain);
                                    function c(e, t, n, r) {
                                        var a = null;
                                        Object.defineProperty(e, t, {
                                            get: function () {
                                                if (e.value)
                                                    return a || (a = p(e, t, m.property(n.dtoType.xCenter.name, n), r))
                                            },
                                            set: function (i) {
                                                a || (a = p(e, t, m.property(n.dtoType.xCenter.name, n), r)),
                                                a.set(i)
                                            },
                                            enumerable: !0
                                        })
                                    }
                                    function f(e) {
                                        var t,
                                        n = l.build(e, e._metadataInfo, e._ancestorChain),
                                        r = u(e._metadataInfo.valueType.typeInfo.properties);
                                        try {
                                            for (r.s(); !(t = r.n()).done; ) {
                                                var a = t.value;
                                                c(e, s.StringUtil.toSerializedPropertyName(a.name), a, n.forChild(a.name))
                                            }
                                        } catch (i) {
                                            r.e(i)
                                        }
                                        finally {
                                            r.f()
                                        }
                                    }
                                    function p(e, t, n, r) {
                                        if (n.valueType.isCollection)
                                            return new i(n, r, t);
                                        var o = new a(n, r, t),
                                        u = n.valueType.typeInfo;
                                        return u && u.metaType.isDto && f(o),
                                        o
                                    }
                                    return f(o),
                                    o
                                }
                                (n, r)
                            }
                        }
                    },
                    M = {
                        get: function (e) {
                            var t = [],
                            n = {};
                            return {
                                addAspectFactory: function (e) {
                                    t.push(e)
                                },
                                configure: function (e) {
                                    return a.a.extend(n, e)
                                },
                                create: function (r, i, o) {
                                    var u = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
                                    s = a.a.cloneDeep(r),
                                    c = e.get(i).types.get(o);
                                    if (c.metaType.isTypelist)
                                        throw new Error("View model objects can only be created from DTO types not Typelists ".concat(o));
                                    var l = m.root(i, c, u);
                                    return N(t, n).create(s, l)
                                },
                                changeContext: function (e, t) {
                                    var n = e._dtoName,
                                    r = e._xCenter,
                                    a = e.aspects.context(),
                                    i = Object.assign({}, a, t);
                                    return this.create(e.value, r, n, i)
                                },
                                clone: function (e) {
                                    var t = e._dtoName,
                                    n = e._xCenter,
                                    r = a.a.cloneDeep(e.value),
                                    i = e._metadataInfo.externalContext || {};
                                    return this.create(r, n, t, i)
                                }
                            }
                        }
                    };
                    function j(e, t) {
                        return function (e) {
                            if (Array.isArray(e))
                                return e
                        }
                        (e) || function (e, t) {
                            if ("undefined" != typeof Symbol && Symbol.iterator in Object(e)) {
                                var n = [],
                                r = !0,
                                a = !1,
                                i = void 0;
                                try {
                                    for (var o, u = e[Symbol.iterator](); !(r = (o = u.next()).done) && (n.push(o.value), !t || n.length !== t); r = !0);
                                } catch (s) {
                                    a = !0,
                                    i = s
                                }
                                finally {
                                    try {
                                        r || null == u.return || u.return()
                                    }
                                    finally {
                                        if (a)
                                            throw i
                                    }
                                }
                                return n
                            }
                        }
                        (e, t) || o(e, t) || function () {
                            throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.")
                        }
                        ()
                    }
                    var I = n(3),
                    A = {
                        create: function (e) {
                            var t = "edge.aspects.validation.dto.ContextDTO";
                            return {
                                getAspectProperties: function (n, r, i) {
                                    var o = function (n) {
                                        var r = {},
                                        i = e.getCompilationContext(n.xCenter);
                                        return n.externalContext && a.a.keys(n.externalContext).forEach((function (e) {
                                                r[e] = function () {
                                                    return n.externalContext[e]
                                                }
                                            })),
                                        a.a.each(n.elementMetadata.get(t), (function (e) {
                                                var t = i.compile(e.expression);
                                                r[e.name] = t
                                            })),
                                        r
                                    }
                                    (r);
                                    return {
                                        context: {
                                            value: function (e) {
                                                return e ? o[e] ? o[e](n, i.parent) : i.parent ? i.parent.aspects.context(e) : void 0 : function (e, t, n) {
                                                    var r = n.parent ? n.parent.aspects.context() : [],
                                                    i = Object.getOwnPropertyNames(e).concat(Object.getOwnPropertyNames(r));
                                                    return a.a.zipObject(i, i.map((function (e) {
                                                                return t.aspects.context(e)
                                                            })))
                                                }
                                                (o, n, i)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    S = n(4),
                    P = "edge.aspects.validation.dto.VisibilityAndRequirednessRuleDTO";
                    var V = {
                        create: function (e) {
                            function t(e, t) {
                                var n = e.compile(t.data.expression),
                                r = e.compile(t.data.message);
                                return {
                                    shouldApply: t.shouldApply,
                                    status: n,
                                    message: r
                                }
                            }
                            return {
                                getAspectProperties: function (n, r, a) {
                                    for (var i = e.getCompilationContext(r.xCenter), o = function (e, t, n, r) {
                                        return S.Augment.collectRules(e, t, n, r, P)
                                    }
                                        (i, n, r, a), s = new Array(o.length), c = 0; c < o.length; c += 1)
                                        s[c] = t(i, o[c]);
                                    var l = function (e) {
                                        return {
                                            visible: function (t, n, r) {
                                                var a,
                                                i = u(e);
                                                try {
                                                    for (i.s(); !(a = i.n()).done; ) {
                                                        var o = a.value;
                                                        if (o.shouldApply() && "NOT_SET" === o.status(t, n, r))
                                                            return !1
                                                    }
                                                } catch (s) {
                                                    i.e(s)
                                                }
                                                finally {
                                                    i.f()
                                                }
                                                return !0
                                            },
                                            required: function (t, n, r) {
                                                var a,
                                                i = u(e);
                                                try {
                                                    for (i.s(); !(a = i.n()).done; ) {
                                                        var o = a.value;
                                                        if (o.shouldApply() && "REQUIRED" === o.status(t, n, r))
                                                            return !0
                                                    }
                                                } catch (s) {
                                                    i.e(s)
                                                }
                                                finally {
                                                    i.f()
                                                }
                                                return !1
                                            },
                                            requirednessMessages: function (t, n, r) {
                                                var a,
                                                i = [],
                                                o = u(e);
                                                try {
                                                    for (o.s(); !(a = o.n()).done; ) {
                                                        var s = a.value;
                                                        s.shouldApply() && "REQUIRED" === s.status(t, n, r) && s.message(t, n, r) && i.push(s.message(t, n, r))
                                                    }
                                                } catch (c) {
                                                    o.e(c)
                                                }
                                                finally {
                                                    o.f()
                                                }
                                                return i
                                            }
                                        }
                                    }
                                    (s);
                                    return {
                                        visible: {
                                            get: function () {
                                                return l.visible(n, a.parent, n.aspects.context)
                                            }
                                        },
                                        required: {
                                            get: function () {
                                                return l.required(n, a.parent, n.aspects.context)
                                            }
                                        },
                                        _requirednessMessages: {
                                            get: function () {
                                                return l.requirednessMessages(n, a.parent, n.aspects.context)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    D = "edge.aspects.validation.dto.ValidationRuleDTO",
                    F = {
                        typeValid: a.a.constant(!0),
                        messages: a.a.constant([])
                    },
                    k = ["byte", "short", "int", "long", "Byte", "Short", "Integer", "Long", "BigInteger"],
                    L = ["float", "double", "Float", "Double", "BigDecimal"],
                    U = a.a.constant(!0),
                    q = a.a.constant([]);
                    var R = {
                        create: function (e, t) {
                            var n = {
                                typeValid: function (e) {
                                    return !!a.a.isNumber(e) || /^-?\d*(\.\d*)?$/.test(e)
                                },
                                messages: function () {
                                    return [t.notANumber]
                                }
                            },
                            r = {
                                typeValid: function (e) {
                                    return a.a.isNumber(e) ? Math.round(e) === e : /^-?\d+$/.test(e)
                                },
                                messages: function (e) {
                                    return n.typeValid(e) ? [t.notAnInteger] : n.messages(e)
                                }
                            };
                            function i(e, t) {
                                var n = e.compile(t.data.expression),
                                r = e.compile(t.data.message);
                                return {
                                    shouldApply: t.shouldApply,
                                    validate: n,
                                    message: r
                                }
                            }
                            function o(e) {
                                var t = e.value;
                                return a.a.isUndefined(t) || a.a.isNull(t) || "" === t
                            }
                            return {
                                getAspectProperties: function (t, s, c) {
                                    var l,
                                    f = s.readOnly;
                                    if ((null === (l = s.maskInfo) || void 0 === l ? void 0 : l.isMasked) && t.value === s.maskInfo.pattern)
                                        return {
                                            valid: {
                                                get: function () {
                                                    return !0
                                                }
                                            },
                                            validationMessages: {
                                                get: function () {
                                                    return []
                                                }
                                            },
                                            validationMessage: {
                                                get: function () {}
                                            }
                                        };
                                    for (var p = e.getCompilationContext(s.xCenter), d = function (e, t, n, r) {
                                        return S.Augment.collectRules(e, t, n, r, D)
                                    }
                                        (p, t, s, c), y = new Array(d.length), g = 0; g < d.length; g += 1)
                                        y[g] = i(p, d[g]);
                                    var v,
                                    m = function (e) {
                                        return {
                                            messages: function (t, n, r) {
                                                return e.filter((function (e) {
                                                        return e.shouldApply() && !e.validate(t, n, r) && e.message(t, n, r)
                                                    })).map((function (e) {
                                                        return e.message(t, n, r)
                                                    }))
                                            },
                                            valid: function (t, n, r) {
                                                var a,
                                                i = u(e);
                                                try {
                                                    for (i.s(); !(a = i.n()).done; ) {
                                                        var o = a.value;
                                                        if (o.shouldApply() && !0 !== o.validate(t, n, r))
                                                            return !1
                                                    }
                                                } catch (s) {
                                                    i.e(s)
                                                }
                                                finally {
                                                    i.f()
                                                }
                                                return !0
                                            }
                                        }
                                    }
                                    (y),
                                    h = "primitive" === (v = s.valueType).kind ? k.indexOf(v.name) >= 0 ? r : L.indexOf(v.name) >= 0 ? n : F : F,
                                    b = null,
                                    w = f ? q : function () {
                                        var e;
                                        e = o(t) ? t.aspects._requirednessMessages : h.typeValid(t.value) ? m.messages(t, c.parent, t.aspects.context) : h.messages(t.value);
                                        var n = a.a.uniq(e);
                                        return a.a.isEqual(b, n) || (b = n),
                                        b
                                    };
                                    return {
                                        valid: {
                                            get: f ? U : function () {
                                                return o(t) ? !t.aspects.required : h.typeValid(t.value) && m.valid(t, c.parent, t.aspects.context)
                                            }
                                        },
                                        validationMessages: {
                                            get: w
                                        },
                                        validationMessage: {
                                            get: function () {
                                                return w()[0]
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    };
                    function z(e) {
                        return e.aspects.valid
                    }
                    function B(e) {
                        if (!e.aspects.valid)
                            return !1;
                        for (var t = e.length, n = 0; n < t; n += 1)
                            if (!e.getElement(n).aspects.subtreeValid)
                                return !1;
                        return !0
                    }
                    function W(e) {
                        if (e.readOnly)
                            return function () {
                                return !0
                            };
                        switch (e.valueType.kind) {
                        case "class":
                            var t = e.valueType.typeInfo;
                            return t.metaType.isDto ? (n = t, function (e) {
                                if (!e.aspects.valid)
                                    return !1;
                                var t,
                                r = u(n.properties);
                                try {
                                    for (r.s(); !(t = r.n()).done; ) {
                                        var a = t.value;
                                        if (e[a.jsProperty] && !e[a.jsProperty].aspects.subtreeValid)
                                            return !1
                                    }
                                } catch (i) {
                                    r.e(i)
                                }
                                finally {
                                    r.f()
                                }
                                return !0
                            }) : z;
                        case "collection":
                            return B;
                        default:
                            return z
                        }
                        var n
                    }
                    var Y = function () {
                        return !0
                    },
                    $ = {
                        getAspectProperties: function (e, t, n, r) {
                            var a = r && r.subtreeValidity && r.subtreeValidity.assumeValid ? r.subtreeValidity.assumeValid : null,
                            i = a && a.includes(t.valueType.name) ? Y : W(t);
                            return {
                                subtreeValid: {
                                    get: function () {
                                        return i(e)
                                    }
                                }
                            }
                        }
                    };
                    var Q = {
                        getAspectProperties: function (e, t) {
                            return {
                                inputCtrlType: {
                                    get: function () {
                                        return e = t.valueType,
                                        n = "text",
                                        (r = "primitive" !== e.kind) && e.isCollection ? n = "collection" : r && e.typeInfo.metaType.isTypelist ? n = "typelist" : "Date" === e.name ? n = "date" : ["integer", "int", "bigdecimal", "long"].includes(e.name.toLowerCase()) ? n = "number" : "Boolean" === e.name && (n = "boolean"),
                                        n;
                                        var e,
                                        n,
                                        r
                                    }
                                }
                            }
                        }
                    },
                    K = {
                        getAspectProperties: function (e, t) {
                            var n = t.maskInfo,
                            r = e.value;
                            if (!(null == n ? void 0 : n.isMasked))
                                return {};
                            var a = r === n.pattern;
                            return {
                                isMasked: {
                                    get: function () {
                                        return !!(null == n ? void 0 : n.isMasked)
                                    }
                                },
                                pattern: {
                                    get: function () {
                                        return null == n ? void 0 : n.pattern
                                    }
                                },
                                isPatternApplied: {
                                    get: function () {
                                        return a
                                    }
                                }
                            }
                        }
                    },
                    G = "edge.aspects.validation.dto.TypelistFilterDTO",
                    H = "edge.aspects.validation.dto.CategoryFilterDTO",
                    J = "edge.aspects.validation.dto.TypekeyListFilterDTO";
                    var X = {
                        create: function (e) {
                            function t(e, t, n, r) {
                                var a = e.compile(r.categoryExpression);
                                return function () {
                                    return a(t, n, t.aspects.context)
                                }
                            }
                            return {
                                getAspectProperties: function (n, r, i) {
                                    if ("class" !== r.valueType.kind)
                                        return {};
                                    var o = r.valueType.typeInfo;
                                    if (!o.metaType.isTypelist)
                                        return {};
                                    var u = o.codes;
                                    r.elementMetadata.get(G).forEach((function (e) {
                                            var t = o.getFilter(e.filterName);
                                            u = u.filter(t.allows)
                                        })),
                                    r.elementMetadata.get(J).forEach((function (e) {
                                            u = u.filter((function (t) {
                                                        return e.codes.indexOf(t.code) >= 0
                                                    }))
                                        }));
                                    var s = r.elementMetadata.get(H);
                                    if (0 === s.length)
                                        return {
                                            availableValues: {
                                                value: u
                                            }
                                        };
                                    var c = e.getCompilationContext(r.xCenter),
                                    l = i.parent || {},
                                    f = s.map(a.a.partial(t, c, n, l)),
                                    p = null;
                                    return {
                                        availableValues: {
                                            get: function () {
                                                var e = u.filter((function (e) {
                                                            return f.every((function (t) {
                                                                    var n = t();
                                                                    return !n || e.belongsToCategory(n)
                                                                }))
                                                        }));
                                                return function (e, t) {
                                                    if (null === e != (null === t))
                                                        return !1;
                                                    if (e.length !== t.length)
                                                        return !1;
                                                    for (var n = 0; n < e.length; n += 1)
                                                        if (e[n] !== t[n])
                                                            return !1;
                                                    return !0
                                                }
                                                (e, p) || (p = e),
                                                p
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    };
                    function Z(e) {
                        return e.value
                    }
                    function ee(e) {
                        var t = e.value;
                        return a.a.isString(t) ? (console.warn("Serializing string as a typelist value.Typecodes should be used instead."), t) : t.code
                    }
                    function te(e) {
                        for (var t = e.length, n = [], r = 0; r < t; r += 1)
                            n.push(e.getElement(r).aspects.serializedForm);
                        return n
                    }
                    function ne(e, t) {
                        var n = {};
                        return e.forEach((function (e) {
                                var r = t[e].aspects.serializedForm;
                                a.a.isUndefined(r) || (n[e] = r)
                            })),
                        n
                    }
                    var re = {
                        getAspectProperties: function (e, t) {
                            var n = function (e) {
                                var t = e.valueType;
                                switch (t.kind) {
                                case "primitive":
                                    return Z;
                                case "collection":
                                    return te;
                                case "stringMap":
                                    if ("primitive" === t.valueType.kind)
                                        return Z;
                                    throw new Error("Maps are not supported on the view model");
                                case "class":
                                    switch (t.typeInfo.metaType.kind) {
                                    case "typelist":
                                        return ee;
                                    case "dto":
                                        return a.a.partial(ne, t.typeInfo.properties.map((function (e) {
                                                    return e.jsProperty
                                                })));
                                    default:
                                        throw new Error("Unsupported class type kind ".concat(t.typeInfo.metaType.kind))
                                    }
                                default:
                                    throw new Error("Unsupported kind of the view model node : ".concat(t.kind))
                                }
                            }
                            (t);
                            return {
                                serializedForm: {
                                    get: function () {
                                        if (e.aspects.visible) {
                                            var t = e.value;
                                            return a.a.isUndefined(t) || a.a.isNull(t) ? t : n(e)
                                        }
                                    }
                                }
                            }
                        }
                    },
                    ae = {
                        getAspectProperties: function (e, t, n) {
                            if (!n.parent)
                                return {
                                    _vmFromData: {
                                        get: function () {
                                            return a.a.partial(N(e._aspectFactories).create, a.a, t)
                                        }
                                    }
                                }
                        }
                    };
                    function ie(e, t, n) {
                        if (!e || e.aspects.subtreeValid)
                            return n;
                        var r = e._metadataInfo.valueType;
                        if (r.isPrimitive || r.typeInfo && r.typeInfo.metaType.isTypelist)
                            e.aspects.valid || n.push(t);
                        else if (r.isCollection)
                            e.children.forEach((function (e, r) {
                                    return ie(e, "".concat(t, "[").concat(r, "]"), n)
                                }));
                        else if (r.isClass || !e._parent) {
                            e._metadataInfo.valueType.typeInfo.properties.forEach((function (r) {
                                    var a = e[r.jsProperty];
                                    if (a || !r)
                                        return ie(a, "".concat(t, ".").concat(r.jsProperty), n);
                                    n.push(t)
                                }))
                        }
                        return n
                    }
                    var oe = {
                        getAspectProperties: function (e, t, n) {
                            if (!n.parent)
                                return {
                                    invalidChildProperties: {
                                        get: function () {
                                            return ie(e, "", [])
                                        }
                                    }
                                }
                        }
                    };
                    var ue = {
                        createMutableNamespace: function (e, t) {
                            var n = {};
                            function r(t, r) {
                                if (a.a.has(n, t))
                                    throw new Error("Type ".concat(t, " is already defined in ").concat(e));
                                n[t] = r
                            }
                            return t && a.a.each(t, (function (e, t) {
                                    r(t, e)
                                })), {
                                hasType: function (e) {
                                    return a.a.has(n, e)
                                },
                                getFunction: function (e, t) {
                                    var r = s.MapUtil.get(n, e) || {};
                                    return s.MapUtil.get(r, t)
                                },
                                registerType: r
                            }
                        },
                        createCompositeNamespace: function () {
                            var e = Array.prototype.slice.call(arguments);
                            return {
                                hasType: function (t) {
                                    return e.some((function (e) {
                                            return e.hasType(t)
                                        }))
                                },
                                getFunction: function (t, n) {
                                    var r = a.a.find(e, (function (e) {
                                                return e.hasType(t)
                                            }));
                                    if (r)
                                        return r.getFunction(t, n)
                                }
                            }
                        }
                    };
                    function se(e) {
                        return function (e) {
                            if (Array.isArray(e))
                                return i(e)
                        }
                        (e) || function (e) {
                            if ("undefined" != typeof Symbol && Symbol.iterator in Object(e))
                                return Array.from(e)
                        }
                        (e) || o(e) || function () {
                            throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.")
                        }
                        ()
                    }
                    var ce = Object.freeze({});
                    function le(e) {
                        return x.isViewModelNode(e) ? e.aspects.typedValue : e
                    }
                    function fe(e, t, n) {
                        var r = t.map((function (e) {
                                    return le(e(n))
                                }));
                        return r.includes(ce) ? ce : e.apply(void 0, se(r))
                    }
                    var pe,
                    de = (pe = {
                            int: a.a.identity,
                            long: a.a.identity,
                            string: a.a.identity,
                            BigDecimal: a.a.identity,
                            boolean: a.a.identity,
                            dto: a.a.identity,
                            null: a.a.constant(null),
                            Date: function (e) {
                                function t(t) {
                                    return e.apply(this, arguments)
                                }
                                return t.toString = function () {
                                    return e.toString()
                                },
                                t
                            }
                            ((function (e) {
                                    return new Date(e)
                                }))
                        }, function (e, t) {
                        var n = s.MapUtil.get(pe, e.type);
                        if (n)
                            return a.a.constant(n(e.value));
                        if ("enum " === e.type.substring(0, 5))
                            return a.a.constant(e.value);
                        if ("typekey " === e.type.substring(0, 8))
                            return a.a.constant(t.typekeyLookup(e.type.substring(8), e.value));
                        throw new Error("Unsupported constant type ".concat(e.type))
                    });
                    var ye = O({
                        const: de,
                        parameter: function (e) {
                            var t = e.index;
                            return function (e) {
                                return e[t]
                            }
                        },
                        getfield: function () {
                            function e(e) {
                                return e.charAt(0).toLowerCase() + e.substring(1)
                            }
                            return function (t, n) {
                                var r = n.compile(t.base),
                                i = t.propertyName;
                                return function (t) {
                                    return function (t, n) {
                                        if (t === ce || a.a.isUndefined(t) || a.a.isNull(t))
                                            return t;
                                        if (x.isViewModelNode(t)) {
                                            var r = t[n];
                                            if (r || (r = t[e(n)]), r)
                                                return r;
                                            if ((t = t.aspects.typedValue) === ce || a.a.isUndefined(t) || a.a.isNull(t))
                                                return t
                                        }
                                        var i = t[n];
                                        return i || t[e(n)]
                                    }
                                    (r(t), i)
                                }
                            }
                        }
                        (),
                        nativecall: function (e, t) {
                            var n = e.container,
                            r = e.name,
                            i = e.params.map(t.compile),
                            o = t.operators.getFunction(n, r);
                            if (o)
                                return a.a.partial(o, i);
                            var u = t.functions.getFunction(n, r);
                            if (u)
                                return a.a.partial(fe, u, i);
                            throw new Error("Could not find function ".concat(r, " in ").concat(n))
                        },
                        translate: function (e, t) {
                            var n = e.arguments.map(t.compile);
                            return n.unshift(a.a.constant(e.displayKey)),
                            a.a.partial(fe, t.translate, n)
                        }
                    }, I.kindCompilers);
                    var ge = {
                        createCompiler: function (e, t, n, r) {
                            return function (e, t, n, r) {
                                function a(e) {
                                    var t = s.MapUtil.get(ye, e.kind);
                                    if (!t)
                                        throw new Error("Unsupported expression kind ".concat(e.kind));
                                    return t(e, i)
                                }
                                var i = {
                                    compile: a,
                                    operators: e,
                                    functions: t,
                                    translate: n,
                                    typekeyLookup: r
                                };
                                return {
                                    compile: function (e) {
                                        var t = a(e);
                                        return function () {
                                            return le(t(Array.prototype.slice.call(arguments)))
                                        }
                                    }
                                }
                            }
                            (e, t, n, r)
                        },
                        NONSENSE: ce,
                        cleanse: le
                    };
                    var ve = {
                        createRootContext: function (e, t, n, r) {
                            var i = ue.createMutableNamespace("[Root]", e),
                            o = ue.createMutableNamespace("[Root]", t),
                            u = ge.createCompiler(i, o, n, a.a.partial(r, null)),
                            s = [];
                            function c(e, t) {
                                var n = a.a.find(s, (function (t) {
                                            return t.namespace.hasType(e)
                                        }));
                                if (n)
                                    throw new Error("Type ".concat(e, " is already registered in child context ").concat(n.name));
                                o.registerType(e, t)
                            }
                            return {
                                registerType: function (e, t) {
                                    c(e, t)
                                },
                                registerTypes: function (e) {
                                    a.a.each(e, (function (e, t) {
                                            c(t, e)
                                        }))
                                },
                                compile: function (e) {
                                    return u.compile(e)
                                },
                                deriveContext: function (e) {
                                    var t = ue.createMutableNamespace(e, {}),
                                    u = ue.createCompositeNamespace(o, t),
                                    c = ge.createCompiler(i, u, n, a.a.partial(r, e));
                                    function l(e, n) {
                                        if (o.hasType(e))
                                            throw new Error("Type ".concat(e, " is already registered in common namespace"));
                                        t.registerType(e, n)
                                    }
                                    return s.push({
                                        name: e,
                                        namespace: t
                                    }), {
                                        registerType: function (e, t) {
                                            l(e, t)
                                        },
                                        registerTypes: function (e) {
                                            a.a.each(e, (function (e, t) {
                                                    l(t, e)
                                                }))
                                        },
                                        compile: function (e) {
                                            return c.compile(e)
                                        }
                                    }
                                }
                            }
                        },
                        NONSENSE: ge.NONSENSE,
                        cleanse: ge.cleanse
                    },
                    me = ve.NONSENSE,
                    he = ve.cleanse,
                    be = {
                        ":sys:ops:": {
                            and: function (e, t) {
                                var n,
                                r = !1,
                                a = u(e);
                                try {
                                    for (a.s(); !(n = a.n()).done; ) {
                                        var i = n.value,
                                        o = he(i(t));
                                        if (o === me)
                                            r = !0;
                                        else if (!o)
                                            return !1
                                    }
                                } catch (s) {
                                    a.e(s)
                                }
                                finally {
                                    a.f()
                                }
                                return !r || me
                            },
                            or: function (e, t) {
                                var n,
                                r = !1,
                                a = u(e);
                                try {
                                    for (a.s(); !(n = a.n()).done; ) {
                                        var i = n.value,
                                        o = he(i(t));
                                        if (o === me)
                                            r = !0;
                                        else if (o)
                                            return !0
                                    }
                                } catch (s) {
                                    a.e(s)
                                }
                                finally {
                                    a.f()
                                }
                                return !!r && me
                            }
                        }
                    },
                    we = function (e, t) {
                        return e === t || (e && e.code && e.typelist && (e = e.code), a.a.isUndefined(e) && (e = null), t && t.code && t.typelist && (t = t.code), a.a.isUndefined(t) && (t = null), e === t)
                    },
                    xe = {
                        ":sys:ops:": {
                            eq: we,
                            neq: function (e, t) {
                                return !we(e, t)
                            },
                            less: function (e, t) {
                                return e < t
                            },
                            greater: function (e, t) {
                                return e > t
                            },
                            lessOrEqual: function (e, t) {
                                return e <= t
                            },
                            greaterOrEqual: function (e, t) {
                                return e >= t
                            },
                            not: function (e) {
                                return !e
                            },
                            lessOrEqualToNextYear: function (e) {
                                return (new Date).getFullYear() + 1 >= e
                            }
                        }
                    };
                    function Ce(e, t) {
                        throw new Error("Could not find typelist ".concat(t, " in the xcenter ").concat(e))
                    }
                    var Te = {
                        createExpressionLanguage: function (e, t) {
                            t = t || Ce;
                            var n = ve.createRootContext(be, xe, e, t),
                            r = {};
                            return {
                                getCompilationContext: function (e) {
                                    if (!e)
                                        return n;
                                    var t = s.MapUtil.get(r, e);
                                    if (t)
                                        return t;
                                    var a = n.deriveContext(e);
                                    return r[e] = a,
                                    a
                                }
                            }
                        },
                        NONSENSE: me,
                        cleanse: he
                    },
                    _e = {
                        get: function (e) {
                            var t = e.metadata,
                            n = e.insuranceSuiteMetadataService,
                            r = e.translator,
                            a = n || p.a.createSuiteMetadata(t);
                            return Te.createExpressionLanguage((function (e) {
                                    for (var t = arguments.length, n = new Array(t > 1 ? t - 1 : 0), a = 1; a < t; a++)
                                        n[a - 1] = arguments[a];
                                    return r ? r(e, n) : e
                                }), (function (e, t, n) {
                                    return a.get(e).types.get("typelist", "typekey.".concat(t)).getCode(n)
                                }))
                        }
                    },
                    Oe = Te.NONSENSE;
                    function Ee(e) {
                        if ("number" == typeof e)
                            return e;
                        if ("string" == typeof e) {
                            var t = Number.parseInt(e);
                            return Number.isNaN(t) ? Oe : t
                        }
                        return Oe
                    }
                    function Ne(e) {
                        if ("number" == typeof e)
                            return e;
                        if ("string" == typeof e) {
                            var t = Number.parseFloat(e);
                            return Number.isNaN(t) ? Oe : t
                        }
                        return Oe
                    }
                    function Me(e) {
                        return "boolean" == typeof e ? e : "string" == typeof e ? "true" === e.toLowerCase().trim() : Oe
                    }
                    var je = {
                        byte: Ee,
                        Byte: Ee,
                        short: Ee,
                        Short: Ee,
                        char: a.a.identity,
                        Char: a.a.identity,
                        int: Ee,
                        Integer: Ee,
                        long: Ee,
                        Long: Ee,
                        BigInteger: Ee,
                        float: Ne,
                        Float: Ne,
                        double: Ne,
                        Double: Ne,
                        BigDecimal: Ne,
                        String: a.a.identity,
                        boolean: Me,
                        Boolean: Me,
                        Date: function (e) {
                            return "string" == typeof e ? new Date(e) : e instanceof Date ? e : "number" == typeof e ? new Date(e) : Oe
                        }
                    };
                    function Ie(e, t) {
                        var n = e.value;
                        return null == n ? n : t(n)
                    }
                    var Ae = {
                        getAspectProperties: function (e, t) {
                            var n,
                            r = "primitive" === (n = t.valueType).kind && je[n.name] || a.a.identity;
                            return {
                                typedValue: {
                                    get: Ie.bind(null, e, r)
                                }
                            }
                        }
                    },
                    Se = function (e) {
                        return O({
                            optionalEditability: function (e, t) {
                                return e ? t : "OPTIONAL"
                            },
                            optionalVisibility: function (e, t) {
                                return e ? t : "OPTIONAL"
                            },
                            belongsToFilter: function (e, t) {
                                return e && e.typelist.getFilter(t).allows(e)
                            },
                            hasCategory: function (e, t) {
                                return !!t && e.belongsToCategory(t)
                            },
                            getContextValue: function (e, t) {
                                return e(t)
                            },
                            matchesPattern: function (e, t) {
                                return !e || !t || new RegExp("^".concat(e, "$")).test(t)
                            },
                            typekeyIn: function (e, t) {
                                return t.indexOf(e.code) >= 0
                            },
                            range: function (e, t, n) {
                                return e <= n && n <= t
                            },
                            decimalRange: function (e, t, n) {
                                return e <= n && n <= t
                            },
                            strLength: function (e) {
                                return e ? e.length : 0
                            },
                            arrLength: function (e) {
                                return e ? e.length : 0
                            },
                            getFromMap: function (e, t) {
                                return e[t]
                            },
                            compareDate: function (e, t) {
                                return new Date(e).getTime() - new Date(t).getTime()
                            },
                            isEmpty: function (e) {
                                return !e || 0 === e.length
                            },
                            isCurrency: function (t) {
                                if (null == t || "" === t)
                                    return !1;
                                if ("number" == typeof t && (t = t.toString()), "string" != typeof t)
                                    return !1;
                                if (!t.match(/^-?\d*(\.\d*)?$/))
                                    return !1;
                                var n = e ? e.getLocaleInfo().currency : {
                                    fractionSize: 2
                                };
                                return -1 === t.indexOf(".") || t.indexOf(".") >= t.length - (n.fractionSize + 1)
                            }
                        }, I.validations)
                    },
                    Pe = function () {
                        return {
                            getStates: function (e) {
                                var t = [];
                                e && (t = e.typelist.xCenter.types.typelists.find((function (e) {
                                                return "typekey.State" === e.typeName
                                            })).codes.filter((function (t) {
                                                return t.belongsToCategory(e)
                                            })));
                                return t
                            }
                        }
                    },
                    Ve = n(5),
                    De = function () {
                        return {
                            prettyPrint: function (e, t) {
                                var n = Ve.PhoneNumberUtil.getInstance(),
                                r = t;
                                try {
                                    return n.format(n.parse(e, r))
                                } catch (a) {
                                    return e
                                }
                            },
                            isPossibleNumber: function (e, t) {
                                var n,
                                r,
                                a = Ve.PhoneNumberUtil.getInstance(),
                                i = !0;
                                if (e) {
                                    r = (r = (n = e.indexOf(" x")) > 0 ? e.substring(0, n) : e).replace(/[-_]/g, "");
                                    try {
                                        i = a.isValidNumber(a.parse(r, t))
                                    } catch (o) {
                                        return !1
                                    }
                                }
                                return i
                            }
                        }
                    },
                    Fe = {
                        formatShortDate: function (e) {
                            return e
                        }
                    };
                    var ke = {
                        getViewModelService: function (e, t) {
                            var n = p.a.createSuiteMetadata(e),
                            r = _e.get({
                                insuranceSuiteMetadataService: n,
                                translator: t
                            }),
                            i = M.get(n),
                            o = {};
                            return o.notANumber = t("platform.modelvalidation.DTOValidationService.Please enter numbers only"),
                            o.notAnInteger = t("platform.modelvalidation.DTOValidationService.Value entered must not contain decimal values"),
                            i.addAspectFactory(A.create(r)),
                            i.addAspectFactory(V.create(r)),
                            i.addAspectFactory(R.create(r, o)),
                            i.addAspectFactory($),
                            i.addAspectFactory(Q),
                            i.addAspectFactory(K),
                            i.addAspectFactory(X.create(r)),
                            i.addAspectFactory(re),
                            i.addAspectFactory(ae),
                            i.addAspectFactory(oe),
                            i.addAspectFactory(Ae),
                            a.a.toPairs(I.aspectFactory).forEach((function (e) {
                                    var t = j(e, 2),
                                    n = (t[0], t[1]);
                                    try {
                                        n && i.addAspectFactory(n(r))
                                    } catch (a) {
                                        0
                                    }
                                })),
                            r.getCompilationContext().registerType("edge.aspects.validation.ValidationFunctions", Se()),
                            r.getCompilationContext().registerType("gw.api.contact.AddressAutocompleteUtil", Pe()),
                            r.getCompilationContext().registerType("edge.capabilities.locale.util.DateFormatter", Fe),
                            r.getCompilationContext().registerType("gw.api.util.DateUtil", s.DateUtil),
                            r.getCompilationContext().registerType("gw.api.util.PhoneUtil", De()),
                            r.getCompilationContext().registerType("edge.time.LocalDateUtil", s.LocalDateUtil),
                            Object.entries(I.types).forEach((function (e) {
                                    var t = j(e, 2),
                                    n = t[0],
                                    a = t[1],
                                    i = a.namespace,
                                    o = a.type;
                                    try {
                                        r.getCompilationContext(i).registerType(n, o)
                                    } catch (u) {
                                        0
                                    }
                                })), {
                                create: i.create,
                                changeContext: i.changeContext,
                                clone: i.clone,
                                productMetadata: n
                            }
                        }
                    }
                }
            ])
    }));
