import React from 'react';
import { CheckboxField } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import styles from './WMICCustomCircularCheckbox.module.scss';

const WMICCustomCircularCheckbox = (props) => {
    const translator = useTranslator();

    const renderCheckbox = (checkboxProps) => {
        const {
            value, label, children, ...otherProps
        } = checkboxProps;
        return (
            <Flex direction="column">
                <Flex className={styles.row}>
                    <CheckboxField
                        {...otherProps}
                        value={value}
                    />
                    <span className={styles.label}>{translator(label)}</span>
                </Flex>
                {
                    (children && value)
                    && (
                        <Flex direction="column" className={styles.childContainer}>
                            {
                                children.map((child) => renderCheckbox(child.props))
                            }
                        </Flex>
                    )
                }
            </Flex>
        );
    };

    return (
        <Flex>
            {renderCheckbox(props)}
        </Flex>
    );
};

WMICCustomCircularCheckbox.propTypes = {
    ...CheckboxField.PropTypes,
};


export default WMICCustomCircularCheckbox;