/* eslint-disable max-len */
import React, {
    Fragment,
    useContext,
    useEffect,
    useCallback,
    useState,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { WMICLoader } from 'gw-components-platform-react';
import { useModal } from '@jutro/components';
import { TranslatorContext, IntlContext } from '@jutro/locale';
import { WMICCoverageComparisonVM } from 'gw-capability-policychange-common-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WMICEndorsementUtil, WMICPolicyChangeStateService, WMICControllerUtil } from 'wmic-capability-policychange';
import { WMICButton } from 'wmic-components-platform-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { DateUtil, LobIconUtil, WMICTempStorageService, CONSTANTS } from 'wmic-portals-utils-js';

import WMICPolicyChangeReviewVehicleCoverages from './WMICPolicyChangeReviewVehicleCoverages/WMICPolicyChangeReviewVehicleCoverages';
import WMICErrorModal from '../WMICErrorModal/WMICErrorModal';
import WMICCancelEditReviewPolicyChangeModal from './WMICCancelEditReviewPolicyChangeModal/WMICCancelEditReviewPolicyChangeModal';
import WMICPolicyChangeReviewVehicleEdit from './WMICPolicyChangeReviewVehicleEdit/WMICPolicyChangeReviewVehicleEdit';

import metadata from './WMICReviewPolicyChangePA.metadata.json5';
import messages from './WMICReviewPolicyChangePA.messages';
import styles from './WMICReviewPolicyChangePA.module.scss';

const PRIMARY_USE_TEXT = {
    commuting: 'Drive to work / school',
    business: 'Business',
    pleasure: 'Pleasure'
}

const getBindErrorMessage = (translator, transactionNumber, expiryDate) => (
    <Fragment>
        {translator(messages.changeCouldNotBeProcessed)}
        <br/><br/>
        {translator(messages.transactionNumber, { transactionNumber })}
        <br/><br/>
        {translator(messages.expires, { expiryDate })}
        <br/><br/>
        {translator(messages.callToSpeakWithAgent)}
    </Fragment>
);

function WMICReviewPolicyChangePA(props) {
    const modalApi = useModal();
    const { policyVM, loadingState, setLoadingState } = props;
    const translator = useContext(TranslatorContext);
    const history = useHistory();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const intl = useContext(IntlContext);
    const PREFILLED_STORAGE_KEYS = {
        PA_POLICY_CHANGE_JOB_NUMBER: 'PA_POLICY_CHANGE_JOB_NUMBER'
    };
    
    //Following specific for delete-vehicle flow which could be back dated. other policychange would take today by default.
    const deleteVehicleChangeRequest = WMICPolicyChangeStateService.getChangeRequest('deleteVehicle', 'delete-vehicle');
    const pcEffectDate = (() => {
        if (deleteVehicleChangeRequest) {
            const { date: pcEffectiveLocaleDate } = deleteVehicleChangeRequest?.changeModel;
            return new Date(new Date(pcEffectiveLocaleDate.year, pcEffectiveLocaleDate.month, pcEffectiveLocaleDate.day).setHours(24,0,0,0));
        } else {
            return new Date(new Date().setHours(24,0,0,0)); 
        }
    })
    
    const getReviewAndConfirmHeader = () => (
        <h2 className={`${styles.wmicReviewSubTitle} ${styles.wmicBlue}`} aria-label={translator(messages.reviewAndConfirmHeaderAria)}>{translator(messages.reviewAndConfirmHeader)}</h2>
    );

    const [state, setState] = useState({
        model: undefined,
        vehicles: [],
        newPremium: undefined,
        oldPremium: undefined,
        jobNumber: undefined,
        removedVehicle: undefined,
        remainingVehicles: [],
        loading: false
    });

    const setCurrentState = useCallback((updState) => setState((prevState) => ({...prevState, ...updState})), []);

    const getOriginalCoverage = (originalCoverages, coverage) => {
        return _.find(originalCoverages, (originalCoverage) => {
            return originalCoverage.publicID.value === coverage.publicID.value;
        });
    };

    const buildReviewVehiclesEdit = useCallback(() => {
        if (deleteVehicleChangeRequest) {
            const { remainingVehicles, vehicle: vehicleToDelete } = deleteVehicleChangeRequest.changeModel;
            const vehicles = _.map(remainingVehicles, (vehicle) => {
                return ({
                    displayName: vehicle.displayName,
                    year: vehicle.year,
                    make: vehicle.make,
                    model: vehicle.model,
                    vehicleNumber: vehicle.vehicleNumber,
                    fixedId: vehicle.fixedId,
                    annualMileage: vehicle.annualMileage,
                    annualMileageUnder12000Toggle: vehicle.annualMileageUnder12000Toggle,
                    primaryUse: PRIMARY_USE_TEXT[vehicle?.primaryUse],
                    primaryDriver: vehicle.primaryDriver,
                    occasionalDrivers: vehicle?.occasionalDrivers,
                    primaryDriverName: vehicle.primaryDriverName,
                    occasionalDriversNames: vehicle?.occasionalDriversNames
                });
            });

            setCurrentState({ removedVehicle: {...vehicleToDelete}, remainingVehicles: vehicles });
        }
    }, [deleteVehicleChangeRequest, setCurrentState])

    const buildCoverageComparison = useCallback((policyChangeModel) => {
        const result = [];
        _.each(policyChangeModel?.originalLobData?.personalAuto?.coverables?.vehicles?.value, (vehicle) => {
            const vehicleCoverages = [];
            const originalVehicleCoverages = [];
            const comparisonVM = [];
            const vehicleVM = {
                fixedId: vehicle.fixedId,
                displayName: `${vehicle.year} ${vehicle.make} ${vehicle.model} (${vehicle.vehicleNumber})`
            };

            _.each(policyChangeModel.lobData.personalAuto.offerings.getElement(0).coverages.vehicleCoverages.value, (coverage, index) => {
                if (coverage.fixedId === vehicle.fixedId) {
                    vehicleCoverages.push(policyChangeModel.lobData.personalAuto.offerings.getElement(0).coverages.vehicleCoverages.findFirstElement(policyChangeModel.lobData.personalAuto.offerings.getElement(0).coverages.vehicleCoverages.value[index]));
                }
            });

            _.each(policyChangeModel.originalLobData.personalAuto.offerings.getElement(0).coverages.vehicleCoverages.value, (coverage, index) => {
                if (coverage.fixedId === vehicle.fixedId) {
                    originalVehicleCoverages.push(policyChangeModel.originalLobData.personalAuto.offerings.getElement(0).coverages.vehicleCoverages.findFirstElement(policyChangeModel.originalLobData.personalAuto.offerings.getElement(0).coverages.vehicleCoverages.value[index]));
                }
            });

            const editableCoverages = WMICEndorsementUtil.getEditableVehicleCoverages(vehicleCoverages[0]?.coverages, originalVehicleCoverages[0].coverages);

            _.each(editableCoverages?.coverages, (coverage) => {
                const coverageComparisonVM = new WMICCoverageComparisonVM();
                const originalCoverage = getOriginalCoverage(editableCoverages.originalCoverages, coverage);
                if (originalCoverage) {
                    originalCoverage.terms.value = originalCoverage.terms.value.filter(
                        (item) => (item.publicID !== CONSTANTS.COVERAGE_TERM_NAMES.PA_COLLISIONCOVRENTALEXPLIMIT && item.publicID !== CONSTANTS.COVERAGE_TERM_NAMES.PA_COMPREHENSIVECOVRENTALEXPLIMIT)
                    );
                    _.each(originalCoverage.terms.value, (originalTerm) => {
                        const newTerm = _.find(coverage.terms.value, (term) => {
                            return term.publicID === originalTerm.publicID;
                        });

                        if (newTerm.chosenTerm !== originalTerm.chosenTerm) {
                            coverageComparisonVM.coverageName = originalCoverage.name.value;
                            coverageComparisonVM.coveragePublicId = originalCoverage.publicID.value;
                            coverageComparisonVM.coverageTermPatternCode = originalTerm.patternCode;
                            coverageComparisonVM.originalCoverageTermValue = originalTerm.chosenTermValue;
                            coverageComparisonVM.newCoverageTermValue = newTerm.chosenTermValue;
                            comparisonVM.push(coverageComparisonVM);
                        }
                    });
                }
            });

            vehicleVM.coverages = editableCoverages;
            vehicleVM.coverageComparisons = comparisonVM;
            if (vehicleVM.coverageComparisons.length > 0) {
                result.push(vehicleVM);
            }
        });

        setCurrentState({ vehicles: result });
    }, [setCurrentState]);

    const goBackHomeAction = useCallback(() => {
        history.push('/home');
    }, [history]);

    const jobNum = WMICTempStorageService.read(PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER);

    const showErrorModal = useCallback(async (content, title) => {
        await modalApi.showModal(<WMICErrorModal onCancelEdit={goBackHomeAction} content={content} title={title} isModalOpen={true} transactionNumber={jobNum}/>).catch(_.noop);
    }, [goBackHomeAction]);

    useEffect(() => {
        setLoadingState(true);
        setCurrentState({ jobNumber: jobNum });

        if (jobNum) {
            WMICEndorsementUtil.getPolicyChangeModelByJobNumber(jobNum, viewModelService, authHeader)
                .then((policyChange) => {
                    setCurrentState({ model: policyChange });
                    if (policyChange.quoteData.value
                    && policyChange.quoteData.value.offeredQuotes
                    && policyChange.quoteData.value.offeredQuotes[0]
                    && policyChange.quoteData.value.offeredQuotes[0].status.toLowerCase() !== CONSTANTS.QUOTED.toLowerCase()) {
                        showErrorModal();
                    } else {
                        setCurrentState({
                            newPremium: policyChange?.quoteData.offeredQuotes.getElement(0).premium.total.value.amount,
                            oldPremium: policyChange?.previousTotalCost.value.amount
                        });
                        if (deleteVehicleChangeRequest) {
                            buildReviewVehiclesEdit();
                        } else {
                            buildCoverageComparison(policyChange);
                        }
                        setLoadingState(false);
                    }
                });
        } else {
            showErrorModal();
        }
    }, [PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER, authHeader, buildCoverageComparison, buildReviewVehiclesEdit, deleteVehicleChangeRequest, setCurrentState, showErrorModal, viewModelService]);

    useEffect(() => {
        focusReviewAndConfirmHeader();
    }, [loadingState]);
        
    const focusReviewAndConfirmHeader = () => {
        const headerTag = document.getElementById('reviewAndConfirmHeader');
        if (headerTag && !loadingState) {
            headerTag.setAttribute('tabindex', '0');
            headerTag.focus({preventScroll: true});
        }
    };

    const getTitleWithIcon = useCallback((title, icon) => {
        return (
            <Fragment>
                <i className={`fa ${icon}`} />
                <h2 className={styles.wmicReviewSubTitle}>{title}</h2>
            </Fragment>
        );
    }, []);

    const formattedNumber = (number) => intl.formatNumber(number, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const getReviewYourVehicleEditsContent = useCallback(() => {
        return <WMICPolicyChangeReviewVehicleEdit
            jobId={state.jobNumber}
            removedVehicle={state.removedVehicle}
            remainingVehicles={state.remainingVehicles}
            policy={policyVM.currentPeriod}
        />
    }, [policyVM.currentPeriod, state.jobNumber, state.remainingVehicles, state.removedVehicle])

    const getEditedVehicleCoverageContent = useCallback(() => {
        return (
            <Fragment>
                { state.vehicles?.map((vehicle, idx) => <WMICPolicyChangeReviewVehicleCoverages key={idx} vehicle={vehicle} /> )}
            </Fragment>
        );
    }, [state.vehicles]);

    const cancelEditing = useCallback(() => {
        setLoadingState(true);
        WMICEndorsementUtil.notTaken(state.jobNumber, authHeader).then(() => {
            WMICTempStorageService.pop(PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER);
            goBackHomeAction();
        });
    }, [PREFILLED_STORAGE_KEYS.PA_POLICY_CHANGE_JOB_NUMBER, authHeader, goBackHomeAction, state.jobNumber]);

    const cancelPolicyEdit = useCallback(async () => {
        await modalApi.showModal(<WMICCancelEditReviewPolicyChangeModal onCancelEdit={cancelEditing} isOpen />).catch(_.noop);
    }, [cancelEditing]);

    const bindPolicy = useCallback(() => {
        const paymentDetails = {
            paymentMethod: CONSTANTS.PAYMENT_TYPES.NONE,
            creditCardData: null,
            bankAccountData: null
        };
        
        setLoadingState(true);
        if (deleteVehicleChangeRequest) {
            WMICControllerUtil.getMessage({
                policyNumber: policyVM.currentPeriod.policyNumber,
                termNumber: policyVM.currentPeriod.termNumber_WMIC,
                policyData: policyVM,
                authUserData,
                authHeader: authHeader
            }, translator).then((message) => {
                if (!message){
                    showErrorModal()
                } else {
                    const paymentView = viewModelService.create(paymentDetails, 'pc', 'wmic.edge.us.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
                    if (paymentView.aspects.subtreeValid) {
                        WMICEndorsementUtil.bindWithRenewal(state.jobNumber, paymentDetails, message, CONSTANTS.POLICY_CHANGE_TYPE.SELF_SERVE_REMOVE_VEHICLE, authHeader)
                            .then((policyChange) => {
                                if (policyChange.policyChange.exceptions && policyChange.policyChange.exceptions.length > 0) {
                                    showErrorModal();
                                } else {
                                    history.push(`/bound-policy-change/${state.model.policyNumber.value}/${state.jobNumber}`);
                                }
                            });
                    } else {
                        showErrorModal();
                    }
                }});
        } else {
            const paymentView = viewModelService.create(paymentDetails, 'pc', 'wmic.edge.us.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
            if (paymentView.aspects.subtreeValid) {
                WMICEndorsementUtil.bindPolicyChange(state.jobNumber, paymentDetails, CONSTANTS.POLICY_CHANGE_TYPE.SELF_SERVE_ADJUST_COVERAGES, authHeader)
                    .then((policyChange) => {
                        if (policyChange.policyChange.exceptions && policyChange.policyChange.exceptions.length > 0) {
                            const effectiveDate = policyChange?.policyChange?.baseData?.effectiveDate;
                            let expiryDate = effectiveDate ? new Date(effectiveDate.year, effectiveDate.month, effectiveDate.day) : new Date()
                            expiryDate = DateUtil.addDays(expiryDate, 45);
                            const bindErrorMessage = getBindErrorMessage(
                                translator,
                                state.jobNumber,
                                intl.formatDate(expiryDate, { year: 'numeric', month: 'short', day: '2-digit' })
                            )
                            showErrorModal(bindErrorMessage);
                        } else {
                            history.push(`/bound-policy-change/${state.model.policyNumber.value}/${state.jobNumber}`);
                        }
                    })
                    .catch((error) => {
                        console.warn('Error occured while binding policy ::', error);
                        showErrorModal();
                    });
            } else {
                showErrorModal();
            }
        }
    }, [authHeader, deleteVehicleChangeRequest, history, intl, policyVM, showErrorModal, state.jobNumber, state?.model?.policyNumber?.value, translator, viewModelService]);

    const submitTrackButtonIdentifier = useMemo(() => {
        let result = translator(messages.policyChangeSubmitTrackButtonIdentifierGeneric);

        if (state.removedVehicle) {
            result = translator(messages.policyChangeSubmitTrackButtonIdentifierSpecific, { policyChangeType: translator(messages.removeVehicle)});
        } else if (state.newPremium) {
            result = translator(messages.policyChangeSubmitTrackButtonIdentifierSpecific, { policyChangeType: translator(messages.adjustCoveragesOrDeductibles)});
        }

        return result;
    }, [state.removedVehicle, state.newPremium]);

    const getAriaMessageForPremium = (oldPremium, newPremium) => {
        const difference = Math.abs(Math.round((state.oldPremium - state.newPremium) * 100) / 100).toFixed(2)
        return newPremium < oldPremium ? translator(messages.premiumSavingSummaryAriaMessage, {oldPremium, newPremium, savingAmount: difference}) :
            (newPremium === oldPremium ? translator(messages.premiumNoChangeAriaMessage, {oldPremium}) :
                translator(messages.premiumIncreaseSummaryAriaMessage, {oldPremium, newPremium, increaseAmount: difference}))
    }

    const getPremiumChangeContent = useCallback(() => {
        return (
            <Fragment>
                <h2 className={styles['is-sr-only']}>{getAriaMessageForPremium(state.oldPremium?.toFixed(2), state.newPremium?.toFixed(2))}</h2>
                <div className="ww-edit-price px-5-5">
                    <div className="ww-edit-price-rev" aria-hidden="true">
                        {translator(messages.currentPremiumAmount)}
                        <br />
                        <strong>{`$${formattedNumber(state.oldPremium)}`}</strong>
                        <br />
                        {state.newPremium > state.oldPremium ? translator(messages.increaseInPremium) : 
                            (state.newPremium < state.oldPremium ? translator(messages.decreaseInPremium) : translator(messages.noChangeInPremium))}
                        <br />
                        { state.newPremium < state.oldPremium && (
                            <strong>{`$${formattedNumber(Math.abs(Math.round((state.oldPremium - state.newPremium) * 100) / 100))}`}</strong>
                        )}
                        { state.newPremium > state.oldPremium && (
                            <strong>{`$${formattedNumber(Math.abs(Math.round((state.oldPremium - state.newPremium) * 100) / 100))}`}</strong>
                        )}
                        { state.newPremium == state.oldPremium && (
                            <strong>{`$${formattedNumber(0)}`}</strong>
                        )}
                        <br />
                        <hr className="my-4"></hr>
                    </div>
                    <div className="ww-edit-price-diff" aria-hidden="true">
                        <strong>{`$${formattedNumber(state.newPremium)}`}</strong>
                        <br />
                        {translator(messages.newPremiumAmount)}
                    </div>
                </div>
                <div className="ww-edit-apply-changes">
                    <p className="pb-4">
                        { state.newPremium > state.oldPremium && (
                            <strong className="ww-edit-edit-result">
                                {translator(messages.theChangesYouVeSelectedWillResultInAnIncreaseToYourPremium)}
                            </strong>
                        )}
                        { state.newPremium < state.oldPremium && (
                            <strong className="ww-edit-edit-result">
                                {translator(messages.theChangesYouVeSelectedWillResultInASavingsToYourPremium)}
                            </strong>
                        )}
                        {translator(messages.ifYouAreReadyToFinalizeTheseChangesYouMayConfirmAndApplyThemBelow)}
                    </p>
                    { pcEffectDate >= new Date(new Date().setHours(24,0,0,0)) && (
                        <p className="py-4">
                            {translator(messages.yourPolicyWillReflectTheseChangesAfterMidnightTonight)}
                        </p>
                    )}
                    { state.newPremium < state.oldPremium && (
                        <p className="py-4">
                            {translator(messages.creditInformationWillBeProvidedAfterYourChangesHaveBeenProcessed)}
                        </p>
                    )}
                    { state.newPremium > state.oldPremium && (
                        <p className="py-4">
                            {translator(messages.paymentInformationWillBeProvidedAfterYourChangesHaveBeenProcessed)}
                        </p>
                    )}
                    <p className="py-4">
                        <WMICButton type="outlined" onClick={cancelPolicyEdit}>
                            {translator(messages.cancelEdit)}
                        </WMICButton>
                        <WMICButton type="primary" onClick={bindPolicy} trackButtonIdentifier={submitTrackButtonIdentifier}>
                            {translator(messages.continue)}
                        </WMICButton>
                    </p>
                    <p className="ww-subscript">
                        <span>{`${translator(messages.quoteNumber)} ${state.jobNumber}`}</span>
                    </p>
                </div>
            </Fragment>
        );
    }, [bindPolicy, cancelPolicyEdit, state.jobNumber, state.newPremium, state.oldPremium, state.removedVehicle, translator]);

    const overrideProps = {
        reviewAndConfirmHeader: {
            content: getReviewAndConfirmHeader()
        },
        wmicLoader: {
            visible: state.loading,
        },
        editedVehicleCoveragesSection: {
            visible: !loadingState && !state.removedVehicle,
        },
        editedVehicleCoveragesTitle: {
            content: getTitleWithIcon(translator(messages.editedVehicleCoverages), LobIconUtil.getWmicIcon('PersonalAuto'))
        },
        reviewYourVehicleEditsSection: {
            visible: !loadingState && Boolean(state.removedVehicle),
        },
        reviewYourVehicleEditsTitle: {
            content: getTitleWithIcon(translator(messages.reviewYourVehicleEdits), LobIconUtil.getWmicIcon('PersonalAuto'))
        },
        buttonEditPolicy: {
            content: translator(messages.editPolicy),
            onClick: () => {
                history.push(`/edit-policy-self-service/${policyVM.currentPeriod?.policyNumber}`);
            }
        },
        editedVehicleCoveragesContent: {
            content: getEditedVehicleCoverageContent()
        },
        reviewYourVehicleEditsContent: {
            content: getReviewYourVehicleEditsContent()
        },
        premiumChangeContent: {
            content: getPremiumChangeContent(),
            visible: !loadingState
        },
        notificationSection: {
            visible: !loadingState
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICButton,
            WMICLoaderComponent: WMICLoader
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICReviewPolicyChangePA.propTypes = {
    policyVM: PropTypes.shape({}).isRequired
};

export default WMICReviewPolicyChangePA;