import React from 'react';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import { WMICModal } from 'gw-capability-policy-react';
import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/uiconfig';
import { WMICButton } from 'wmic-components-platform-react';
import metadata from './WMICDocumentDownloadButton.metadata.json5';
import styles from './WMICDocumentDownloadButton.module.scss';
import messages from './WMICDocumentDownloadButton.messages.js';

const WMICDocumentDownloadButton = (props) => {
    const modalApi = useModal();
    const {
        targetDocument,
        size,
        policyNumber
    } = props;

    const translator = useTranslator();

    const documentEndpoints = {
        pc: 'policyDocument',
        cc: 'claimDocument',
        bc: 'billingDocument'
    };

    const documentEndpoint = documentEndpoints[targetDocument.type];

    const fileIcons = {
        doc: 'fa-file-word-o',
        docx: 'fa-file-word-o',
        docm: 'fa-file-word-o',
        pdf: 'fa-file-pdf-o',
        xlt: 'fa-file-excel-o',
        xlsx: 'fa-file-excel-o',
        xlsm: 'fa-file-excel-o',
        gif: 'fa-file-image-o',
        png: 'fa-file-image-o',
        jpeg: 'fa-file-image-o'
    };

    const getFileExtension = (name) => {
        if (name.indexOf('.') === -1) {
            return '';
        }
        const extension = name.split('.').pop();
        return extension || '';
    };

    const getFileIcon = (file) => {
        let fileIcon = 'fa fa-file-pdf-o';
        if (file && file.fileName) {
            const extension = getFileExtension(file.fileName).toLowerCase() || '';
            fileIcon = fileIcons[extension] ? `fa ${fileIcons[extension]}` : 'fa fa-file-pdf-o';
        }
        return fileIcon;
    };

    const getDocumentDownloadUrl = () => {
        const docUrl = DocumentDownloadService.getDocumentLink(getProxiedServiceUrl(documentEndpoint), targetDocument.id, targetDocument.sessionID);
        return docUrl;
    };

    const handleDownloadClick = () => {
        if (targetDocument.id) {
            const url = getDocumentDownloadUrl();
            window.open(url, '_blank');
        } else {
            const body = WMICRichTextUtil.translateRichText(translator(messages.documentErrorMessage));
            const title = translator(messages.documentErrorTitle);
            modalApi.showModal(
                <WMICModal
                    id="documentErrorModal"
                    modalHeader={title}
                    modalBody={body}
                    onConfirmCallback={() => _.noop()}
                    confirmButtonText={translator(messages.documentClose)}
                />
            );
        }
    };

    const getButtonContent = (fileIcon) => {
        return (
            <div>
                <i className={fileIcon} />
                <span>
                    {targetDocument.friendlyName}
                </span>
            </div>
        );
    };

    const getButtonAriaLabel = () => {
        // Only return aria-label if policy number and document name exist
        return (
            policyNumber && targetDocument.friendlyName ? translator(messages.ariaLabel, {
                documentName: targetDocument.friendlyName,
                policyNumber: policyNumber
            }) : ''
        )
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICButton
        },
        resolveCallbackMap: {
            onDownloadClick: handleDownloadClick,
            getFileIcon: getFileIcon,
        },
    };

    const fileIcon = getFileIcon(targetDocument);
    const buttonContent = getButtonContent(fileIcon);

    const overrideProps = {
        documentDownloadButton: {
            content: buttonContent,
            size,
            trackButtonIdentifier: props.trackButtonIdentifier || targetDocument.fileName || targetDocument.friendlyName,
            'aria-label': getButtonAriaLabel()
        },
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
};

WMICDocumentDownloadButton.propTypes = {
    targetDocument: PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        sessionID: PropTypes.number,
        friendlyName: PropTypes.string,
    }).isRequired,
    documentFriendlyName: PropTypes.string.isRequired,
    size: PropTypes.string,
};

WMICDocumentDownloadButton.defaultProps = {
    targetDocument: {
        id: '',
        type: '',
        friendlyName: '',
        fileName: ''
    },
    size: 'small'
};

export default WMICDocumentDownloadButton;