/* eslint-disable max-len */
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { LobIconUtil, CONSTANTS, WMICRichTextUtil, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import { WMICButton } from 'wmic-components-platform-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { useAuthentication } from 'wmic-digital-auth-react';
import classNames from 'classnames';
import { useModal } from '@jutro/components';
import { useHistory } from 'react-router-dom';
import { WMICControllerUtil } from 'wmic-capability-policychange';
import { WMICManagePaymentPlan, WMICPolicyDetailsUtil, WMICModal } from 'gw-capability-policy-react';
import metadata from './WMICChangeTypeSelectorHOComponent.metadata.json5';
import messages from './WMICChangeTypeSelectorHOComponent.messages';
import styles from './WMICChangeTypeSelectorHOComponent.module.scss';

function WMICChangeTypeSelectorHOComponent(props) {
    const modalApi = useModal();
    const {
        policyVM,
        policyNumber,
        termNumber,
        billingData,
        setLoadingState,
        loadingState
    } = props;
    const translator = useTranslator();
    const [showWarningMessage, updateShowWarningMessage] = useState(false);
    const [showValidationMessage, updateShowValidationMessage] = useState(false);
    const { authHeader, authUserData } = useAuthentication();
    const [selectedValue, updateSelectedValue] = useState('');
    const [submitPath, updateSubmitPath] = useState(undefined);
    const [policyChangeOptions, updatePolicyChangeOptions] = useState([]);
    const history = useHistory();
    const PAYMENT_PLAN_MODAL_NAME = 2;
    _.set(billingData, 'policyStatus', WMICPolicyDetailsUtil.getBillingInfoPolicyStatus(policyVM));
    _.set(billingData, 'renewalPolicyStatus', WMICPolicyDetailsUtil.getBillingInfoPolicyStatus(policyVM));
    const [ldFlags, setLdFlags] = useState({});

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('changeTypeSelectorHeader');
        if (headerTag && !loadingState) {
            headerTag.focus({preventScroll: true});
        }
    }, [loadingState])

    const getChangeTypeSelectorTitle = () => {
        const title = translator(messages.selectAPolicyChange);
        const icon = LobIconUtil.getWmicIcon('Homeowners');

        return (
            <Fragment>
                <i className={`fa ${icon} ${styles['wmic-title-icon']}`} />
                <h2 className={styles['wmic-policy-title']} aria-label={translator(messages.selectAPolicyChangeAria)}>{title}</h2>
            </Fragment>
        );
    };

    const prepareOptions = (billingInformation) => {
        const state = billingInformation?.accountSummary?.state;
        const opt = canShowPaymentPlanChangeOption(billingInformation.billingMethod, billingInformation.outstandingAmount, billingInformation.amountOverDue)
            ? [{ 
                id: CONSTANTS.POLICY_CHANGE_TYPE.PAYMENT_PLAN,
                displayName: translator(messages.changePaymentPlan)
            }]
            : [];
        opt.push({
            id: CONSTANTS.POLICY_CHANGE_TYPE.OTHER_CHANGES,
            displayName: translator(messages.idLikeToChangeOrUpdateSomethingElse)
        });
        const OPTIONS = {
            OR: [
                {
                    id: CONSTANTS.POLICY_CHANGE_TYPE.CHANGE_ADDRESS,
                    displayName: translator(messages.changeOfAddress)
                },
                ...opt
            ],
            CA: [
                {
                    id: CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON,
                    displayName: translator(messages.addOrUpdateRecipientForNoticeOfCancellationOrNonrenewal)
                },
                ...opt
            ]
        };
        updatePolicyChangeOptions(OPTIONS[state]);
    };

    const canShowPaymentPlanChangeOption = (method, outstandingAmount, amountOverdue) => {
        return method !== CONSTANTS.LIST_BILL
            && outstandingAmount?.amount > 0
            && outstandingAmount?.amount !== amountOverdue?.amount
            && !(amountOverdue?.amount > 0 || outstandingAmount?.amount === 0);
    };

    useEffect(() => {
        initFeatureFlags();
        setLoadingState(true);
        AccountBillingDetailsService.getPolicyPaymentSummaryByPolicyNumber_WMIC(policyNumber, authHeader)
            .then((data) => {
                if (data.accountSummary.country === CONSTANTS.COUNTRY.US) {
                    prepareOptions(data);
                }
            }).finally(() => {
                setLoadingState(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectOption = useCallback((value) => {
        updateSelectedValue(value);
        updateShowValidationMessage(false);

        if (value === CONSTANTS.POLICY_CHANGE_TYPE.OTHER_CHANGES) {
            updateSubmitPath(undefined);
        } else {
            updateSubmitPath(`/account-policy-change/${policyNumber}/${termNumber}/home/${value}`);
        }
        const subOptions = policyChangeOptions.find((option) => option.id === value && option.options !== undefined );
        const showWarning = WMICControllerUtil.showWarningMessage(policyNumber, value, subOptions);
        updateShowWarningMessage(showWarning);
    }, [policyChangeOptions, policyNumber, termNumber]);

    const onSubmit = useCallback((selectedOption) => {
        if (!selectedOption) {
            updateShowValidationMessage(true);
            return;
        }
        if (selectedOption === CONSTANTS.POLICY_CHANGE_TYPE.PAYMENT_PLAN) {
            return modalApi.showModal(
                <WMICManagePaymentPlan
                    billingData={billingData}
                    stepName={PAYMENT_PLAN_MODAL_NAME}
                    updateBillingDetails={_.noop}
                    ldFlags={ldFlags}
                />
            );
        }
        if (selectedOption === CONSTANTS.POLICY_CHANGE_TYPE.OTHER_CHANGES) {
            return modalApi.showModal(
                <WMICModal
                    id="callUsModal"
                    modalHeader={translator(messages.callUs)}
                    modalBody={WMICRichTextUtil.translateRichText(translator(messages.changesUnavailableOnline))}
                    onConfirmCallback={() => _.noop()}
                    confirmButtonText={translator(messages.close)}
                />
            );
        }
        return history.push(submitPath);
    }, [history, submitPath, translator, billingData]);


    const getWarning = () => {
        return (
            <div className={styles['ww-notification-message']}>
                <h3 className={classNames(styles['ww-notification-message-header'], styles['ww-status-warning'])}>
                    <i className="fa fa-exclamation-triangle" />
                    {translator(messages.youHavePendingPolicyChanges)}
                </h3>
                <div className={styles['ww-notification-message-body']}>
                    {translator(messages.weveDetectedYouHavePreviouslySubmittedPolicyChanges)}
                </div>
            </div>
        );
    };

    const getSubmitButtonTrackButtonIdentifier = (value) => {
        let returnValue = '';
        const selectedPolicyChange = policyChangeOptions.find((option) => option.id === value);
        if (selectedPolicyChange) {
            returnValue = translator(messages.policyChangeSelectTrackButtonIdentifier, {selectedChange: selectedPolicyChange.displayName})
        }
        return returnValue;
    }

    const overrideProps = {
        changeTypeSelectorContainer: {
            visible: !loadingState
        },
        changeTypeSelectorTitle: {
            content: getChangeTypeSelectorTitle()
        },
        radioButtonGroupAria: {
            content: translator(messages.policyChangeSelectContentAria)
        },
        warningMessageContainer: {
            visible: showWarningMessage,
            content: getWarning()
        },
        radioButtonGroup: {
            availableValues: policyChangeOptions,
            onValueChange: (value) => handleSelectOption(value),
            value: selectedValue
        },
        cancelButton: {
            onClick: () => history.push(`/account-policy-details/${policyNumber}/${termNumber}`)
        },
        submitButton: {
            onClick: () => onSubmit(selectedValue),
            to: submitPath,
            trackButtonIdentifier: getSubmitButtonTrackButtonIdentifier(selectedValue)
        },
        validationMessage :{
            visible: showValidationMessage,
            content: translator(messages.validation)
        },
        californiaDisclaimerParagraph: {
            visible: billingData?.accountSummary?.state === CONSTANTS.STATE.CA,
            content: WMICRichTextUtil.translateRichText(translator(messages.legalDisclaimerCA))
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICButton
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICChangeTypeSelectorHOComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    policyNumber: PropTypes.string.isRequired,
    billingData: PropTypes.shape({}).isRequired,
    termNumber: PropTypes.string.isRequired,
};

export default WMICChangeTypeSelectorHOComponent;