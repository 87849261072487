import React from 'react';
import createHOC from 'react-create-hoc';
import CloudAuthenticationContext from './CloudAuthenticationContext';
import { AuthProvider } from '@jutro/auth';

/**
 * Creates a content wrapped with CloudAuthenticationContext
 *
 * @function withAuthenticationProvider
 * @memberof module:gw-digital-auth-react
 *
 * @param {ReactElement} ComponentToWrap component to be wrapped with AuthContestProvider
 * @param {Object} authProps authorisation passed to the CloudAuthenticationContext
 * @see {@link module:gw-digital-auth-react.CloudAuthenticationContext} for more information
 *
 * @returns {ReactElement} the content wrapped with CloudAuthenticationContext
 */
const withAuthenticationProvider = createHOC('withAuthenticationProvider', (ComponentToWrap, authProps) => {
    return function WithAuthenticationProvider(props) {
        return (
            <AuthProvider>
                <CloudAuthenticationContext {...authProps}>
                    <ComponentToWrap {...props} />
                </CloudAuthenticationContext>
            </AuthProvider>
        );
    };
},
{ factory: true, allowExtraProps: true });

export default withAuthenticationProvider;
