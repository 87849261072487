import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@jutro/components';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import { messages as platformMessages} from '@xengage/gw-platform-translations';
import metadata from './ClaimLobOptionsMenu.metadata.json5';
import styles from './ClaimLobOptionsMenu.module.scss';

function ClaimLobOptionsMenu(props) {
    const {
        values, handleFilterChange, isClosed, selectedClaimLOB, isShowClosedClaimFilter
    } = props;
    const translator = useTranslator();

    const generateOverrides = useMemo(() => {
        const lobOptionsOverrides = values.map((item, index) => {
            return {
                [`itemName${index}`]: {
                    content: platformMessages[item] ? translator(platformMessages[item]) : item,
                    onClick: () => {
                        handleFilterChange(item, 'LOBFilter');
                    },
                    className: item === selectedClaimLOB ? 'itemNameSelected' : 'itemNameClass'
                },
                [`iconChecked${index}`]: {
                    visible: item === selectedClaimLOB
                }
            };
        });

        return Object.assign({}, ...lobOptionsOverrides);
    }, [values, handleFilterChange, selectedClaimLOB, translator]);

    const renderTrigger = useCallback(
        (dropDownProps, toggleMenu) => {
            const { isOpen } = dropDownProps;
            const menuClick = () => {
                toggleMenu(!isOpen);
            };
            return (
                <IconButton
                    onClick={menuClick}
                    role="menuitem"
                    icon="mi-filter-list"
                />
            );
        },
        []
    );

    const overrides = {
        lobValues: {
            data: values,
        },
        onClosedLink: {
            onValueChange: (value) => handleFilterChange(value, 'closedFilter'),
            value: isClosed,
            visible: isShowClosedClaimFilter
        },
        onClosedMenuTitle: {
            visible: isShowClosedClaimFilter
        },
        claimLobOptionsMenu: {
            renderTrigger: renderTrigger
        },
        ...generateOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {}
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            {...resolvers} />
    );
}
ClaimLobOptionsMenu.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    isClosed: PropTypes.bool,
    showClosedClaimFilter: PropTypes.bool,
    selectedClaimLOB: PropTypes.string
};

ClaimLobOptionsMenu.defaultProps = {
    values: [],
    isClosed: false,
    selectedClaimLOB: undefined,
    isShowClosedClaimFilter: true
};

export default ClaimLobOptionsMenu;
