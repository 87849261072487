import React from 'react';
import PropTypes from 'prop-types';
import { uniqueInnerId } from '@jutro/platform';
import { useTranslator } from '@jutro/locale';
// import { MetadataForm } from '@jutro/uiconfig';
import { MetadataForm } from '@jutro/uiconfig';
import styles from './AddressForm.module.scss';
import metadata from './AddressForm.metadata.json5';
import messages from './AddressForm.messages';

const AddressForm = ({
    id,
    address,
    setAddress,
    setValidity
}) => {
    const translator = useTranslator();

    const ids = uniqueInnerId(id, 'street', 'unit', 'city', 'state', 'zip');

    const overrideProps = {
        '@all': (id) => ({
            messageProps: {
                requiredField: `${translator(messages[`${id}`])} field is required`
            }
        }),
        street: {
            id: ids.street,
            label: messages.street
        },
        unit: {
            id: ids.unit,
            label: messages.unitNumber
        },
        city: {
            id: ids.city,
            label: messages.city
        },
        state: {
            id: ids.state,
            label: messages.state
        },
        zip: {
            id: ids.zip,
            label: messages.zip,
            validator: {
                pattern: '^\\d{5}(-\\d{4})?$',
                message: messages.zipCodeIncorrect
            }
        }
    };

    return (
        <MetadataForm
            data={address}
            onDataChange={(value, path) => {
                const newFilters = { ...address };
                newFilters[path] = value;
                setAddress(newFilters);
            }}
            onValidationChange={(isValid) => {
                setValidity(isValid);
            }}
            uiProps={metadata['edit-address']}
            overrideProps={overrideProps}
            classNameMap={styles}
            showOptional />
    );
};

AddressForm.propTypes = {
    id: PropTypes.string.isRequired,
    address: PropTypes.shape({
        street: PropTypes.string,
        unit: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
    }).isRequired,
    setAddress: PropTypes.func.isRequired,
    setValidity: PropTypes.func.isRequired
};

export default AddressForm;
