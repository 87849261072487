import React from 'react';
import { Button } from '@jutro/components';
import PropTypes from "prop-types";
import cx from 'classnames';
import { 
    BUTTON_DEFAULT_TYPE,
    BUTTON_ALLOWED_TYPES,
    BUTTON_FILLED_TYPES,
    BUTTON_BASE_CLASSNAME,
    BUTTON_FILLED,
    BUTTON_OUTLINED 
} from '../constants'

const WMICButton = (props) => {
    const {
        type: passedType = BUTTON_DEFAULT_TYPE, className: passedClassName, ...otherProps
    } = props;

    return (
        <Button
            {...otherProps}
            type={BUTTON_FILLED_TYPES.includes(passedType) ? BUTTON_FILLED : BUTTON_OUTLINED }
            className={cx(`${BUTTON_BASE_CLASSNAME} ${BUTTON_BASE_CLASSNAME}-${passedType}`, passedClassName)}
        />
    );
};

WMICButton.propTypes = {
    type: PropTypes.oneOf(BUTTON_ALLOWED_TYPES),
};

export default WMICButton;
