/* eslint-disable max-len,radix*/
import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    Fragment
} from 'react';
import { withRouter } from 'react-router-dom';
import { WMICButton, WMICDelinquencyModal } from 'wmic-components-platform-react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import { WMICLoader } from 'gw-components-platform-react';
import { WMICLink } from "wmic-components-amp-common-react";
import { Grid } from '@jutro/layout';
import { IntlContext, useTranslator } from '@jutro/locale';
import { useAuthentication } from 'wmic-digital-auth-react';
import { PolicyService } from 'gw-capability-policy';
import { AccountBillingDetailsService, BillingService } from 'gw-capability-billing';
import { EndorsementService } from 'gw-capability-policychange';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    WMICPolicyInfoBox,
    WMICBillingInfoBox,
    WMICVehiclesInfoBox,
    WMICDriversInfoBox,
    WMICPolicyCoverageInfoBox,
    WMICLocationInfoBox,
    WMICModal,
    WMICPolicyDocuments
} from 'gw-capability-policy-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    WMICPageNavigationUtil,
    LocalDateUtil,
    CONSTANTS,
    WMICPaymentUtil,
    WMICFeatureFlagUtil,
    SUITES,
    WMICRichTextUtil,
    WMICAMPNavigationUtil,
    WMICBillingUtil,
    WMICRemoteLogger
} from 'wmic-portals-utils-js';
import { WMICStickyMessageComponent, WMICNoticesComponent } from 'gw-capability-policy-common-react';
import WMICPolicyDetailsUtil from '../WMICPolicyDetailsUtil';

import metadata from './WMICPolicyDetails.metadata.json5';
import messages from './WMICPolicyDetails.messages';
import styles from './WMICPolicyDetails.module.scss';

function WMICPolicyDetails(props) {
    const modalApi = useModal();
    const { match, history } = props;
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const [billingDetails, setBillingDetails] = useState([]);
    const [policyDetails, setPolicyDetails] = useState([]);
    const [policyTransactionsInfo, setPolicyTransactionsInfo] = useState([]);
    const [paymentNotices, setPaymentNotices] = useState([]);
    const [loadingState, setLoadingState] = useState(true);
    const [policyDocs, setPolicyDocs] = useState([]);
    const [additionalPolicyDocs, setAdditionalPolicyDocs] = useState([]);
    const [ldFlags, setLdFlags] = useState([]);
    const [notices, setNotices] = useState([]);
    const [hasNotification, setHasNotification] = useState(false);
    const [policyChangeCanBeStarted, setPolicyChangeCanBeStarted] = useState(true);
    const [hasBillingDelinquencyNOITC, setHasBillingDelinquencyNOITC] = useState(false);
    const [hasFutureBoundPolicyChange, setHasFutureBoundPolicyChange] = useState(false);
    const [paPolicyChangeButtonLabel, setPAPolicyChangeButtonLabel] = useState('');
    const [mostRecentBillingDocument, updateMostRecentBillingDocument] = useState({});
    const [periodData, setPeriodData] = useState();
    const [isRenewal, setIsRenewal] = useState(false);
    const [logger, setLogger] = useState({});
    const currentDate = new Date();
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const rFlagsPromise = WMICFeatureFlagUtil.useFlags(authUserData);
    const DOM_ELEMENT_IDS = {
        POLICY_INFO: 'policyInfoBoxContainer',
        BILLING: 'billingInfoBoxContainer',
        DRIVERS: 'cardDrivers',
        LOCATION: 'locationInfoBoxGrid',
        MAIN_MENU: 'wmicMainMenu',
        POLICY_DOCUMENTS: 'policyDocumentsContainer'
    };
    const { WMICPaymentModal } = useDependencies('WMICPaymentModal');

    const getPeriod = (policy) => {
        if (policy.renewedPeriod || policy.currentPeriod) {
            const termNumberParsed = _.isString(termNumber) ? parseInt(termNumber) : termNumber;

            if (policy.renewedPeriod?.termNumber_WMIC === termNumberParsed) {
                return policy.renewedPeriod;
            }

            return policy.currentPeriod;
        }

        return undefined;
    };

    const isPA = (policy) => getPeriod(policy)?.lobs?.personalAuto?.coverageDTOs !== undefined;

    const isHO = useCallback((policy) => getPeriod(policy)?.lobs?.homeOwners?.coverageDTOs !== undefined, []);

    const getFeatureAvailability = useCallback((featureName) => {
        const response = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureName);

        return response;
    }, [ldFlags]);

    const additionalDocumentsFeatureFlagAvailability = (_ldFlags) => WMICFeatureFlagUtil.queryAvailabilityAMP(_ldFlags, featureFlags.ADDITIONAL_DOCUMENTS);

    const canShowPolicyNotice = () => _.get(policyDetails, 'renewedPeriod.termNumber_WMIC', '').toString() === termNumber;

    const getSideMenuLinks = (policy) => {
        const offset = canShowPolicyNotice() ? 56 : 0;
        const getVehicleLinks = (p) => {
            let vehicleLinks = [];
            const period = getPeriod(p);
            
            if (isPA(p)) {
                const vehicleDisplayNames = _.map(_.orderBy(period.lobs.personalAuto.vehicleDTOs, (vehicleDTO) => parseInt(vehicleDTO.vehicleNumber)), (v) => `${v.year} ${v.make} ${v.model} (${v.vehicleNumber})`);
                
                vehicleLinks = _.map(vehicleDisplayNames, (vName, index) => (
                        <li key={`ww-side-nav-vehicle-${index}`} className={styles.sideNavLinks} role="listitem">
                            <WMICLink onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, `vehicleContainer${index}`, offset)} to="/home"
                                trackbuttonidentifier={translator(messages.submenuItem)}>
                                {vName}
                            </WMICLink>
                        </li>
                    ));
            }
            
            return vehicleLinks;
        };

        const getDriversLink = (p) => {
            if (isPA(p)) {
                return (
                    <li key="ww-side-nav-drivers" className={styles.sideNavLinks} role="listitem">
                        <WMICLink onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.DRIVERS, offset)} to="/home"
                            trackbuttonidentifier={translator(messages.submenuItem)}>
                            {translator(messages.drivers)}
                        </WMICLink>
                    </li>
                );
            }

            return [];
        };

        const getLocationLink = (p) => {
            if (isHO(p)) {
                const period = getPeriod(p);
                const address = period.lobs.homeOwners?.dwellingDTO.address;
                const addressString = `${address.addressLine1}, ${address.city}, ${address.state} ${address.postalCode}`;
                
                return (
                    <li key="ww-side-nav-location" className={styles.sideNavLinks} role="listitem">
                        <span className="sr-only">
                            {translator(messages.propertyDetailsAria, {address: addressString})}
                        </span>
                        <WMICLink aria-hidden onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.LOCATION, offset)} to="/home"
                            trackbuttonidentifier={translator(messages.submenuItem)}>
                            {addressString}
                        </WMICLink>
                    </li>
                );
            }
            
            return [];
        };

        let navigationLinks = [];

        navigationLinks.push(
            <li key="ww-side-nav-policy-info" className={styles.sideNavLinks} role="listitem">
                <WMICLink onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.POLICY_INFO, offset)} to="/home"
                    trackbuttonidentifier={translator(messages.submenuItem)}>
                    {translator(messages.policyDetails)}
                </WMICLink>
            </li>
        );
        navigationLinks.push(
            <li key="ww-side-nav-billing" className={styles.sideNavLinks} role="listitem">
                <WMICLink onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.BILLING, offset)} to="/home"
                    trackbuttonidentifier={translator(messages.submenuItem)}>
                    {translator(messages.billing)}
                </WMICLink>
            </li>
        );
        navigationLinks = navigationLinks.concat(getVehicleLinks(policy));
        navigationLinks = navigationLinks.concat(getDriversLink(policy));
        navigationLinks = navigationLinks.concat(getLocationLink(policy));
        navigationLinks.push(
            <li key="ww-side-nav-policy-document" className={styles.sideNavLinks} role="listitem">
                <WMICLink onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.POLICY_DOCUMENTS, offset)} to="/home"
                    trackbuttonidentifier={translator(messages.submenuItem)}>
                    {translator(messages.policyDocuments)}
                </WMICLink>
            </li>
        );

        WMICAMPNavigationUtil.setMobileViewLinks(navigationLinks);

        return (
            // TODO-eagnilleri to be implemented when the document card will be ready (AMPDM-621)
            <Grid
                columns={['1fr']}
                gap="none"
                justifyItems="left"
                className={styles.policyMenuContentGrid}
            >
                {navigationLinks}
            </Grid>
        );
    };

    const getPrimaryLOB = (policy) => {
        const policyPeriodData = getPeriod(policy);

        if (policyPeriodData) {
            return isHO(policy) ? policyPeriodData.lobs.homeOwners : policyPeriodData.lobs.personalAuto;
        }

        return undefined;
    };

    const getPageTitle = (policy) => {
        const getLineTitle = (t) => {
            let lineTitle = '';
            const title = t?.toLowerCase();

            if ([CONSTANTS.POLICY_TYPES.HO.HOMEOWNERS, CONSTANTS.POLICY_TYPES.HO.HOMEOWNER].includes(title)) {
                lineTitle = translator(messages.homeowners);
            } else if ([CONSTANTS.POLICY_TYPES.HO.TENANT, CONSTANTS.POLICY_TYPES.HO.RENTERS].includes(title)) {
                lineTitle = translator(messages.renters);
            } else if (title === CONSTANTS.POLICY_TYPES.HO.CONDOMINIUM) {
                lineTitle = translator(messages.condominium);
            } else {
                lineTitle = t;
            }
            
            return lineTitle;
        };

        if (isPA(policy)) {
            return (
                <Fragment>
                    <i className="fa fa-car" />
                    { translator(messages.paPolicyNumber, { policyNumber: policyNumber }) }
                </Fragment>
            );
        }

        return (
            <Fragment>
                <i className="fa fa-home" />
                { translator(messages.hoPolicyNumber, {
                    policyType: getLineTitle(getPrimaryLOB(policy)?.dwellingDTO?.policyType),
                    policyNumber: policyNumber
                }) }
            </Fragment>
        );
    };

    const policyNotice = useCallback((noticeText) => (
            <WMICStickyMessageComponent>
                <div className="ww-card__content ww-card-notice">
                    <div className="ww-card-notice-text">
                        <i className="fa fa-exclamation-circle" />
                        {`${translator(messages.notice)} ${translator(noticeText)}`}
                    </div>
                </div>
            </WMICStickyMessageComponent>
        ), [translator]);

    const canStartPolicyChangePA = useCallback((policy) => {
        const isPolicyInForce = (status) => {
            const polStatus = status.toLowerCase();
            
            return !(polStatus === CONSTANTS.LOB_STATUSES.SCHEDULED
                || polStatus === CONSTANTS.LOB_STATUSES.CANCELLED
                || polStatus === CONSTANTS.LOB_STATUSES.EXPIRED);
        };

        return getFeatureAvailability(featureFlags.POLICYCHANGE).isAvailable
            && policy.currentPeriod
            && isPA(policy)
            && isPolicyInForce(policy.currentPeriod.lobs.personalAuto.status);
    }, [isPA]);

    const canShowHOEditPolicyButton = useCallback(() => {
        return isHO(policyDetails)
            && !!billingDetails
            && billingDetails.policyStatus
            && !WMICPaymentUtil.isPolicyInactive(billingDetails)
    }, [isHO, policyDetails, billingDetails]);

    const canShowPAEditPolicyButton = useCallback(() => {
        return canStartPolicyChangePA(policyDetails);
    }, [policyDetails, canStartPolicyChangePA]);

    useEffect(() => {
        if (policyDetails) {
            const isRenewal = _.get(policyDetails, 'renewedPeriod.termNumber_WMIC', '').toString() === termNumber;
            setIsRenewal(isRenewal);
        }
    }, [policyDetails]);

    useEffect(() => {
        setPaymentNotifications();
    }, [ldFlags]);

    useEffect(() => {
        if (!_.isEmpty(ldFlags)) {
            const updatedLogger = WMICRemoteLogger.getWMICRemoteLogger(
                'WMICPolicyDetails',
                history.location.pathname,
                window.location,
                ldFlags,
                authHeader
            );
            setLogger(updatedLogger);
        }
    },[ldFlags]);

    const setPaymentNotifications = () => {
        const cardPaymentAvailability = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.CARD);
        const cardNotice = WMICFeatureFlagUtil.getCardAvailabilityInfo(cardPaymentAvailability, currentDate, translator);
        const bankAccountPaymentAvailability = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.BANK);
        const bankNotice = WMICFeatureFlagUtil.getBankAvailabilityInfo(bankAccountPaymentAvailability, currentDate, translator);
        const paymentAvailability = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.PAYMENTS);
        const paymentNotice = WMICFeatureFlagUtil.getPaymentsAvailabilityInfo(paymentAvailability, currentDate, translator);
        let payNotices = [];
        const outages = {
            card: false,
            bank: false,
            payments: false
        };
        if (cardNotice && cardNotice.body) {
            payNotices.push(cardNotice);
            outages.card = true;
        }
        if (bankNotice && bankNotice.body) {
            payNotices.push(bankNotice);
            outages.bank = true;
        }
        if (paymentNotice && paymentNotice.body) {
            payNotices.push(paymentNotice);
            outages.payments = true;
        }
        if (payNotices.length > 0) {
            setHasNotification(true);
        }

        if (outages.card && outages.bank) {
            payNotices = WMICFeatureFlagUtil.setBothPaymentsDownNotice(payNotices, translator);
        }

        setPaymentNotices(payNotices);
    };

    const getMostRecentBillingDocuments = useCallback((documents) => {
        const mostRecentBillingDocumentInfo = WMICPolicyDetailsUtil.getMostRecentBillingDocumentInfo(
            documents, policyDetails.renewedPeriod !== undefined, termNumber
        );
        if (mostRecentBillingDocumentInfo) {
            const setMostRecentBillingDocument = {
                id: mostRecentBillingDocumentInfo.workingPublicID,
                friendlyName: WMICPolicyDetailsUtil.getDocumentFriendlyName(mostRecentBillingDocumentInfo.documentType),
                sessionID: mostRecentBillingDocumentInfo.sessionID,
                fileName: mostRecentBillingDocumentInfo.name,
                type: SUITES.BC
            };
            updateMostRecentBillingDocument(setMostRecentBillingDocument);
        }
    }, [policyDetails.renewedPeriod, termNumber]);

    const viewDocument = {
        id: 'policy.common.directives.templates.policy-documents.View Document',
        defaultMessage: 'View Document'
    }
    const validForms = {
        'HODEC': translator(messages.policyDeclaration),
        'PADEC': translator(messages.policyDeclaration)
    };

    const DOCUMENT_STATUSES = {
        COMPLETED: 'complete_wmic',
        FINAL: 'final'
    };

    const validSpecialOfferDocumentPatterns = {
        'OfferOfEarthquake': translator(messages.offerOfEarthquakeCoverage)
    };

    const isDateWithInTwoYears = (compDate) => {
        const dateTwoYearBack = new Date();
        dateTwoYearBack.setFullYear(dateTwoYearBack.getFullYear() - 2);

        return compDate >= dateTwoYearBack;
    };

    const isValidFormDoc = (docDTO, policyPeriod) => {
        return validForms[docDTO.formNumber_WMIC] && isDateWithInTwoYears(new Date(policyPeriod.effective));
    };

    const isValidSpecialOfferDoc = (docDTO) => {
        return validSpecialOfferDocumentPatterns[docDTO.documentPatternID_WMIC]
            && isDateWithInTwoYears(new Date(docDTO.dateCompleted_WMIC))
            && (docDTO.status === DOCUMENT_STATUSES.COMPLETED || docDTO.status === DOCUMENT_STATUSES.FINAL);
    };

    const addPolicyPeriodDocs = useCallback((policyPeriod) => {
        const docs = [];
        let doc;
        for (const docDTO of policyPeriod.documentDTOs) {
            if (isValidFormDoc(docDTO, policyPeriod)) {
                doc = {
                    id: docDTO.workingPublicID,
                    friendlyName: translator(viewDocument),
                    sessionID: docDTO.sessionID,
                    fileName: docDTO.name,
                    name: validForms[docDTO.formNumber_WMIC],
                    dateCompleted: policyPeriod.effective,
                    localDateCompleted: policyPeriod.localEffectiveDate,
                    fromJobType: policyPeriod.jobType,
                    term: policyPeriod.termNumber_WMIC,
                    type: SUITES.PC
                };
                docs.push(doc);
            } else if (isValidSpecialOfferDoc(docDTO)) {
                doc = {
                    id: docDTO.workingPublicID,
                    friendlyName: translator(viewDocument),
                    sessionID: docDTO.sessionID,
                    fileName: docDTO.name,
                    name: validSpecialOfferDocumentPatterns[docDTO.documentPatternID_WMIC],
                    dateCompleted: docDTO.dateCompleted_WMIC,
                    localDateCompleted: docDTO.localDateCompleted_WMIC,
                    fromJobType: policyPeriod.jobType,
                    term: policyPeriod.termNumber_WMIC,
                    type: SUITES.PC
                };
                docs.push(doc);
            }
        }

        return docs;
    }, [translator]);

    const generatePolicyDocs = useCallback((documents, policyPeriods = [])=> {
        const policyDocuments = []
        for (const docDTO of documents) {
            const currentDoc = {
                id: docDTO.workingPublicID,
                friendlyName: translator(viewDocument),
                sessionID: docDTO.sessionID,
                dateCompleted: docDTO.dateCompleted_WMIC,
                localDateCompleted: docDTO.localDateCompleted_WMIC,
                fileName: docDTO.name,
                name: translator(WMICPolicyDetailsUtil.getDocumentFriendlyName(docDTO.documentType)),
                type: SUITES.BC
            };
            policyDocuments.push(currentDoc);
        }

        const policyPeriodDocs = [];
        if(policyPeriods.length) {
            for (const policyPeriod of policyPeriods) {
                if(policyPeriod.displayStatus_WMIC.toLowerCase() !== CONSTANTS.LOB_STATUSES.CANCELLED) {
                    policyPeriodDocs.push(...addPolicyPeriodDocs(policyPeriod));
                }
            }
        }

        const uniquePolicyPeriodDocs = policyPeriodDocs.filter(
            (doc, index, docs) => docs.findIndex((doc2) => (doc2.id === doc.id)) === index
        )

        const orderByDocs = _.sortBy([...policyDocuments, ...uniquePolicyPeriodDocs], (dateObj) => {
            return dateObj.dateCompleted;
        }).reverse();

        setPolicyDocs([...orderByDocs]);

    }, [addPolicyPeriodDocs, translator]);

    const generateAdditionalPolicyDocs = async (_periodData) => {
        const additionalDocuments = [];
        if (_periodData && _periodData.documentDTOs) {
            let tempLdFlags = ldFlags;
            if (_.isEmpty(ldFlags)) {
                tempLdFlags = await rFlagsPromise;
                setLdFlags(tempLdFlags);
            }
            for (const docDTO of _periodData.documentDTOs) {
                if (
                    (docDTO.isStaticForm_WMIC || docDTO.formNumber_WMIC === CONSTANTS.DOCUMENT_TYPE.RCN || docDTO.formNumber_WMIC === CONSTANTS.DOCUMENT_TYPE.WFRD) &&
                    additionalDocumentsFeatureFlagAvailability(tempLdFlags).isAvailable
                ) {
                    const currentDoc = {
                        id: docDTO.workingPublicID,
                        friendlyName: translator(viewDocument),
                        sessionID: docDTO.sessionID,
                        dateCompleted: docDTO.dateCompleted,
                        dateCompletedTruncatedTime: new Date(
                            docDTO.localDateCompleted_WMIC.year,
                            docDTO.localDateCompleted_WMIC.month,
                            docDTO.localDateCompleted_WMIC.day
                        ),
                        localDateCompleted: docDTO.localDateCompleted_WMIC,
                        fileName: docDTO.name,
                        name: docDTO.formDescription_WMIC,
                        type: SUITES.PC,
                    };
                    additionalDocuments.push(currentDoc);
                }
            }
            const additionalDocsSortedbyDate = _.orderBy(
                additionalDocuments,
                ['dateCompleted', 'name'],
                ['desc', 'asc']
            );
            setAdditionalPolicyDocs(additionalDocsSortedbyDate);
        }
    }

    const generatePeriodData = useCallback((policyData) => {
        let getPeriodData;
        if (
            policyData.renewedPeriod
            && policyData.renewedPeriod.termNumber_WMIC
            && policyData.renewedPeriod.termNumber_WMIC.toString() === termNumber
        ) {
            getPeriodData = policyData.renewedPeriod;
        } else {
            getPeriodData = policyData.currentPeriod;
        }
        setPeriodData(getPeriodData);

        generateAdditionalPolicyDocs(getPeriodData);
    }, [termNumber]);

    const initFeatureFlags = async () => {
        const rFlags = await rFlagsPromise;
        setLdFlags(rFlags);
    }

    const fetchPolicyDetails = () => {
        setLoadingState(true);
        Promise.all([
            BillingService.getPolicyPaymentSummaryByPolicyNumber_WMIC(policyNumber, authHeader),
            PolicyService.getAccountPolicyDetails_WMIC(policyNumber, termNumber, authHeader, authUserData.firstName, authUserData.lastName),
            PolicyService.getAccountPolicyTransactions_WMIC(policyNumber, authHeader),
            EndorsementService.policyChangeCanBeStarted_WMIC(policyNumber, authHeader),
            AccountBillingDetailsService.getBillingDocumentsByPolicy_WMIC(policyNumber, termNumber, authHeader),
            PolicyService.getIssuedPolicyPeriodSummaries(policyNumber, '', authHeader),
            AccountBillingDetailsService.getBillingDocumentsByPolicyAndTerm_WMIC(policyNumber, termNumber, authHeader)
        ]).then(([billDetails, accPolDetails, accPolTransInfo, polChCanBeStarted, getBillingDocumentsByPolicy, getIssuedPolicyPeriod, getBillingDocumentsByPolicyAndTerm]) => {
            setBillingDetails(billDetails);
            setPolicyDetails(accPolDetails);
            setPolicyTransactionsInfo(accPolTransInfo);
            setPolicyChangeCanBeStarted(polChCanBeStarted);
            generatePolicyDocs(getBillingDocumentsByPolicy, getIssuedPolicyPeriod);
            const policyChangeButtonLabel = canStartPolicyChangePA(accPolDetails)
                && getFeatureAvailability(featureFlags.ASSISTEDPOLICYCHANGE).isAvailable ? translator(messages.editPolicy) : translator(messages.editCoverages);
            setPAPolicyChangeButtonLabel(policyChangeButtonLabel);
            getMostRecentBillingDocuments(getBillingDocumentsByPolicyAndTerm);
            generatePeriodData(accPolDetails);
        }).catch((err) => {
            logger.error(`Error in fetchPolicyDetails(). Details: ${err}`);
        }).finally(() => {
            setLoadingState(false);
        });
    };

    useEffect(() => {
        setLoadingState(true);
        initFeatureFlags();
        return () => {
            WMICAMPNavigationUtil.setMobileViewLinks(undefined);
        };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    useEffect(() => {
        if (!_.isEmpty(ldFlags) && !_.isEmpty(logger)) {
            fetchPolicyDetails();
        }
    }, [ldFlags, logger]);

    const getFutureRenewalTransactions = () => {
        if (policyTransactionsInfo?.policyTransactions && policyTransactionsInfo.policyTransactions.length > 0) {
            return _.chain(policyTransactionsInfo.policyTransactions)
                .filter((transaction) => { return transaction.type.toLowerCase().trim() === CONSTANTS.RENEWAL; })
                .sortBy((transaction) => { return transaction.completionDate; })
                .reverse()
                .value();
        }
        return [];
    };

    const getPolicyChangesAfterPolicyEffDate = () => {
        if (policyTransactionsInfo?.policyTransactions && policyTransactionsInfo.policyTransactions.length > 0) {
            return _.chain(policyTransactionsInfo.policyTransactions)
                .filter((transaction) => {
                    return transaction.type.toLowerCase() === CONSTANTS.POLICY_CHANGE
                        && transaction.completionDate > transaction.termEffectiveDate;
                })
                .sortBy((transaction) => { return transaction.completionDate; })
                .reverse()
                .value();
        }
        return [];
    };

    const getPolicyChangeNotices = (availability) => {
        const policyChangeNotices = [];
        const policyChangeNotice = WMICFeatureFlagUtil.getPolicyChangeAvailabilityInfo(availability, currentDate, translator);
        if (policyChangeNotice) {
            policyChangeNotices.push(policyChangeNotice);
            setHasNotification(true);
        }
        return policyChangeNotices;
    };

    const getPolicyType = () => {
        if (isPA(policyDetails)) {
            return 'auto';
        }
        if (isHO(policyDetails)) {
            return 'home';
        }
        return undefined;
    };

    const paymentModal = (policyModalData, isOpen) => {
        modalApi.showModal(<WMICPaymentModal data={policyModalData} isOpen={isOpen} ldFlags={ldFlags} />).catch(_.noop());
    };

    const editPAPolicy = () => {
        setLoadingState(true);
        Promise.all([
            AccountBillingDetailsService.policyChangeCheckHasDelinquency_WMIC(policyNumber, termNumber, authHeader),
            EndorsementService.policyChangeCanBeStarted_WMIC(policyNumber, authHeader),
            EndorsementService.policyPeriodHasFutureBoundPolicyChange_WMIC(policyNumber, authHeader)
        ]).then(([billingDelinqNotice, polChCanBeStarted, hasFutureBoundPolCh]) => {
            const policyChangeAvailability = getFeatureAvailability(featureFlags.POLICYCHANGE);
            const policyChangeNotices = getPolicyChangeNotices(policyChangeAvailability);
            setNotices(policyChangeNotices);
            setHasBillingDelinquencyNOITC(billingDelinqNotice);
            setPolicyChangeCanBeStarted(polChCanBeStarted);
            setHasFutureBoundPolicyChange(hasFutureBoundPolCh);

        
            if (getFeatureAvailability(featureFlags.ASSISTEDPOLICYCHANGE).isAvailable) {
                history.push(`/account-policy-change/${policyNumber}/${termNumber}/${getPolicyType()}/select-type`);
                return;
            }

            let cannotEditPolicyMessage = '';

            let policyCanBeChanged = canStartPolicyChangePA(policyDetails) && polChCanBeStarted && isPA(policyDetails);

            if (hasFutureBoundPolCh) {
                policyCanBeChanged = false;
                const policyChangesAfterPolicyEffDate = getPolicyChangesAfterPolicyEffDate();
                if (policyChangesAfterPolicyEffDate && policyChangesAfterPolicyEffDate.length > 0) {
                    const compDate = LocalDateUtil.toMidnightDate(policyChangesAfterPolicyEffDate[0].localCompletionDate);
                    const compDateFormatted = intl.formatDate(compDate, { month: 'long', day: 'numeric' });
                    const nextDayFormatted = intl.formatDate(compDate.setDate(compDate.getDate() + 1), { month: 'long', day: 'numeric' });
                    cannotEditPolicyMessage = translator(messages.pendingPolicyChange, {date: compDateFormatted, nextDay: nextDayFormatted});
                } else {
                    cannotEditPolicyMessage = translator(messages.pendingPolicyChangeTempUnavailable);
                }
            } else if (billingDelinqNotice) {
                policyCanBeChanged = false;
            } 
            else if (!policyCanBeChanged) {
                policyCanBeChanged = false;
                cannotEditPolicyMessage = translator(messages.policyChangeCannotBeStarted);
            }

            cannotEditPolicyMessage = cannotEditPolicyMessage.concat('<br/><br/>'.concat(WMICRichTextUtil.safeUnescape(translator(messages.youCanReachUsAt))));

            if (billingDelinqNotice) {
                history.push(`/account-policy-details/${policyNumber}/${termNumber}`);
                modalApi.showModal(
                    <WMICDelinquencyModal
                        termNumber={termNumber}
                        billingData={billingDetails}
                        policyData={policyDetails}
                        onPayNowClick={paymentModal}
                    />
                ).catch(() => {
                    _.noop();
                });
            } else if (policyCanBeChanged) {
                history.push(`/edit-policy-self-service/${policyNumber}`);
            } else {
                history.push(`/account-policy-details/${policyNumber}/${termNumber}`);
                modalApi.showModal(
                    <WMICModal
                        id="baseModal"
                        modalHeader={translator(messages.delinquencyModalTitle)}
                        modalBody={cannotEditPolicyMessage}
                        onConfirmCallback={() => _.noop()}
                        confirmButtonText={translator(messages.close)}
                    />
                ).catch(() => {
                    _.noop();
                });
            }
        })
            .finally(() => {
                setLoadingState(false);
            });
    };

    const getOrderedPaymentNotices = () => {
        return _.orderBy(paymentNotices, ['type']);
    };

    const getPaymentNotices = () => {
        return (
            <WMICNoticesComponent notices={getOrderedPaymentNotices()} />
        );
    };

    const overrideProps = {
        wmicLoader: {
            visible: loadingState
        },
        pageWrapper: {
            visible: !loadingState
        },
        pageTitle: {
            content: getPageTitle(policyDetails)
        },
        editCoverageButtonPA: {
            visible: canShowPAEditPolicyButton(),
            content: paPolicyChangeButtonLabel,
            trackButtonIdentifier: translator(messages.editPolicyTrackButtonIdentifier),
            onClick: () => editPAPolicy(),
            'aria-label': translator(messages.editPolicyNumber, {policyNumber})
        },
        editCoverageButtonHO: {
            visible: canShowHOEditPolicyButton(),
            trackButtonIdentifier: translator(messages.editPolicyTrackButtonIdentifier),
            onClick: () => history.push(`/account-policy-change/${policyNumber}/${termNumber}/${getPolicyType()}/select-type`),
            'aria-label': translator(messages.editPolicyNumber, {policyNumber})
        },
        wmicPolicyNotice: {
            visible: canShowPolicyNotice(),
            content: policyNotice(messages.viewRenewalTerm)
        },
        wmicPaymentNotice: {
            visible: hasNotification && paymentNotices.length > 0,
            content: getPaymentNotices()
        },
        wmicPolicyInfoBox: {
            policyDocs: policyDocs,
            visible: !!billingDetails && !!policyDetails && !!getPrimaryLOB(policyDetails),
            billingData: billingDetails,
            policyData: policyDetails,
            isRenewal: isRenewal,
        },
        wmicBillingInfoBox: {
            billingData: WMICBillingUtil.populateBillingData(billingDetails, termNumber),
            policyData: policyDetails,
            policyTransactions: policyTransactionsInfo,
            mostRecentBillingDocument,
            visible: billingDetails != null && policyDetails != null,
            updateBillingDetails: setBillingDetails,
            fetchPolicyData: fetchPolicyDetails,
            ldFlags: ldFlags,
            isRenewal: isRenewal,
        },
        wmicPolicyCoverageInfoBox: {
            visible: isPA(policyDetails),
            policyCoverageData: getPrimaryLOB(policyDetails)?.vehicleDTOs,
            jurisdiction: getPeriod(policyDetails)?.jurisdiction_WMIC,
            policyNumber: policyNumber
        },
        wmicVehiclesInfoBox: {
            visible: isPA(policyDetails),
            lobData: getPrimaryLOB(policyDetails),
            jurisdiction: getPeriod(policyDetails)?.jurisdiction_WMIC,
            policyData: getPeriod(policyDetails)
        },
        wmicDriversInfoBox: {
            visible: isPA(policyDetails),
            policyDrivers: getPrimaryLOB(policyDetails)?.driverDTOs
        },
        wmicLocationInfoBox: {
            visible: isHO(policyDetails),
            lobData: getPrimaryLOB(policyDetails),
            jurisdiction: getPeriod(policyDetails)?.jurisdiction_WMIC
        },
        policyMenuContent: {
            content: getSideMenuLinks(policyDetails)
        },
        wmicPolicyDocuments: {
            policyDocs: policyDocs,
            visible: policyDocs.length > 0,
            additionalPolicyDocs,
            isAdditionalDocumentsAvailable: additionalDocumentsFeatureFlagAvailability(ldFlags).isAvailable,
            cardHeaderMessage: translator(messages.policyDocuments).toUpperCase()
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICLoaderComponent: WMICLoader,
            wmicpolicyinfobox: WMICPolicyInfoBox,
            wmicbillinginfobox: WMICBillingInfoBox,
            wmicvehiclesinfobox: WMICVehiclesInfoBox,
            wmicdriversinfobox: WMICDriversInfoBox,
            wmicpolicycoverageinfobox: WMICPolicyCoverageInfoBox,
            wmiclocationinfobox: WMICLocationInfoBox,
            WMICNoticesComponent: WMICNoticesComponent,
            wmicPolicyDocuments: WMICPolicyDocuments,
            WMICButton
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyDetails}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}
WMICPolicyDetails.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.shape({})
    }).isRequired
};

export default withRouter(WMICPolicyDetails);