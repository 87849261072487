/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ModalNext } from '@jutro/components/widgets/modal-next/ModalNext';
import { useHistory } from 'react-router';
import { WMICRemoteLogger, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import WMICPolicyNumbersModal from './WMICPolicyNumbersModal';
import WMICAddPolicyGenericFailureModal from './WMICAddPolicyGenericFailureModal';
import WMICAddPolicySuccessModal from './WMICAddPolicySuccessModal';
import WMICAddPolicyConsentModal from './WMICAddPolicyConsentModal';

import messages from './WMICAddPolicyModal.messages';

const STEPS = {
    ADD_POLICES_PAGE: 1,
    CONSENT: 2,
    GENERIC_FAILURE: 3,
    SUCCESS: 4
};

const WMICAddPolicyModal = (props) => {
    const [step, updateStep] = useState(STEPS.ADD_POLICES_PAGE);
    const [enteredPolicyNumbers, updateEnteredPolicyNumbers] = useState([]);
    const [foundPolicies, updateFoundPolicies] = useState([]);
    const genericFailureMessage = useRef('');
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const translator = useTranslator();
    const history = useHistory();
    const [logger, setLogger] = useState({});
    const focusRef = useRef(null);

    const setFocusDialog = useCallback(() => {
        setTimeout(() => {
            focusRef?.current?.focus()
        }, 0);
    }, []);

    const updateStepAndFocus = useCallback((nextStep) => {
        updateStep(nextStep);
        setFocusDialog();
    }, [setFocusDialog]);

    const updateLogger = (fFlags) => {
        const updatedLogger = WMICRemoteLogger.getWMICRemoteLogger(
            'AddPolicyModal',
            history.location.pathname,
            window.location,
            fFlags,
            authHeader
        );
        
        setLogger(updatedLogger);
    };

    const {
        isOpen,
        onReject,
        policies,
        updateAddedPolicies
    } = props;

    const onRejectClick = useCallback(() => {
        logger.error(translator(messages.logUnhandledError));
        onReject();
    }, [logger, onReject, translator]);

    const handleClose = () => {
        onReject();
    };

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        updateLogger(rFlags);
    }

    const goBack = useCallback(() => {
        updateStepAndFocus(STEPS.ADD_POLICES_PAGE);
        updateFoundPolicies([]);
        genericFailureMessage.current = '';
    }, [updateStepAndFocus]);

    useEffect(() => {
        initFeatureFlags();
    },[]);


    const doesPolicyAlreadyExist = useCallback((updatedPolicyNumbers) => {
        let policyExists = false;

        updatedPolicyNumbers.forEach((number) => {
            for (const key in policies) {
                if (policies.hasOwnProperty(key)) {
                    const existingPolicyNumber = policies[key].policyId;

                    if(number === existingPolicyNumber) {
                        policyExists = true;
                    }
                }
            }
        });
        
        return policyExists;
    }, [policies]);

    const modal = useMemo(() => {
        switch (step) {
            case STEPS.ADD_POLICES_PAGE:
                return (
                    <WMICPolicyNumbersModal
                        onReject={onRejectClick}
                        policyNumbers={enteredPolicyNumbers}
                        updatePolicyNumbers={updateEnteredPolicyNumbers}
                        onFindPoliciesSuccess={(policies) => {
                            const policyNumbers = policies.map((val) => val.policyNumber);

                            if(doesPolicyAlreadyExist(policyNumbers)) {
                                const message = translator(messages.youEnteredTheFollowing, { info: policyNumbers.join(', ') });

                                genericFailureMessage.current = `${message}\n${translator(messages.unableToAddPolicies)}`;
                                updateStepAndFocus(STEPS.GENERIC_FAILURE);
                            } else {
                                logger.debug(translator(messages.logAddingPolicyNumbers, { policyNumbers: policies.map((policy) => policy.policyNumber).join(', ') }));
                                genericFailureMessage.current = '';
                                updateFoundPolicies(policies);
                                updateStepAndFocus(STEPS.CONSENT);  
                            }
                        }}
                        onFindPoliciesFailure={(message) => {
                            genericFailureMessage.current = message;
                            updateStepAndFocus(STEPS.GENERIC_FAILURE);
                        }}
                    />
                );
            case STEPS.CONSENT:
                return (
                    <WMICAddPolicyConsentModal
                        policies={foundPolicies}
                        goBack={goBack}
                        onFindPoliciesSuccess={(policies) => {
                            genericFailureMessage.current = '';
                            updateFoundPolicies(policies);
                            updateStepAndFocus(STEPS.SUCCESS);
                        }}
                        onFindPoliciesFailure={(message) => {
                            genericFailureMessage.current = message;
                            updateStepAndFocus(STEPS.GENERIC_FAILURE);
                        }}
                    />
                );
            case STEPS.GENERIC_FAILURE:
                return (
                    <WMICAddPolicyGenericFailureModal
                        onReject={onReject}
                        message={genericFailureMessage.current}
                        onAddMorePolicies={() => {
                            updateStepAndFocus(STEPS.ADD_POLICES_PAGE);
                        }}
                    />
                );
            case STEPS.SUCCESS:
                return (
                    <WMICAddPolicySuccessModal
                        onReject={(policies) => {
                            onReject()
                            updateAddedPolicies(policies);
                        }}
                        enteredPolicyNumbers={enteredPolicyNumbers}
                        foundPolicies={foundPolicies}
                        onAddMorePolicies={() => {
                            updateEnteredPolicyNumbers([]);
                            updateStepAndFocus(STEPS.ADD_POLICES_PAGE);
                        }}
                    />
                );
            default:
                return null;
        }
    }, [step, onRejectClick, enteredPolicyNumbers, foundPolicies, goBack, onReject, doesPolicyAlreadyExist, translator, updateStepAndFocus, logger, updateAddedPolicies]);

    return (
        <ModalNext 
            isOpen={isOpen}
            contentLayout={{
                component: 'Grid',
                componentProps: {
                    rows: ['auto'],
                },
            }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc="true"
            onRequestClose={handleClose}
        >
            <div ref={focusRef} tabIndex={1} aria-live="assertive">{modal}</div>
        </ModalNext>
    );
};

WMICAddPolicyModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
};


export default WMICAddPolicyModal;