import React, { useState, Fragment, useEffect } from 'react';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import { WMICButton, WMICDropdownSelect } from 'wmic-components-platform-react';
import { MetadataForm } from '@jutro/uiconfig';

import WMICUpdateFinanceFormModal from '../WMICUpdateFinanceFormModal/WMICUpdateFinanceFormModal';

import metadata from './WMICUpdateFinanceFormComponent.metadata.json5';
import messages from './WMICUpdateFinanceFormComponent.messages';
import styles from './WMICUpdateFinanceFormComponent.module.scss';

function WMICUpdateFinanceFormComponent(props) {
    const modalApi = useModal();
    const {
        policyVM,
        onCancel,
        vehicleData,
        onSubmit
    } = props;
    const translator = useTranslator();

    const initialData = {
        vehicle: `${_.get(vehicleData, 'vin') || ''}`,
        company: _.get(vehicleData, 'additionalInterests_WMIC[0].displayName'),
        account: '',
        street: _.get(vehicleData, 'additionalInterests_WMIC[0].primaryAddress.addressLine1'),
        city: _.get(vehicleData, 'additionalInterests_WMIC[0].primaryAddress.city'),
        state: _.get(vehicleData, 'additionalInterests_WMIC[0].primaryAddress.state'),
        zip: _.get(vehicleData, 'additionalInterests_WMIC[0].primaryAddress.postalCode') || '',
    };

    const [data, setData] = useState(initialData);

    const [vehicleFormVisible, setVehicleFormVisible] = useState(true);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (vehicleData) setVehicleFormVisible(false);
    }, [vehicleData]);

    const hasBeenUpdated = () => {
        return !_.isEqual(initialData, data);
    };

    const getVehicleFormAvailableValues = () => {
        const vehicles = _.get(policyVM, 'currentPeriod.lobs.personalAuto.vehicleDTOs');
        const res = vehicles.map((v) => {
            return ({
                code: v.vin,
                id: `${v.year} ${v.make} ${v.model} (${v.vehicleNumber})`,
                displayName: `${v.year} ${v.make} ${v.model} (${v.vehicleNumber})`,
            });
        });
        return res;
    };

    const handleCancelForm = () => {
        if (hasBeenUpdated()) {
            modalApi.showModal(<WMICUpdateFinanceFormModal
                title={translator(messages.updateFinanceFormCancelModalTitle)}
                subtitle={translator(messages.updateFinanceFormCancelModalSubtitle)}
                primaryButtonMessage={translator(messages.updateFinanceFormCancelModalCancel)}
                secondaryButtonMessage={
                    translator(messages.updateFinanceFormCancelModalKeepEditing)
                }
                onPrimaryAction={onCancel}
            />).catch(_.noop);
        } else {
            onCancel();
        }
    };

    const handleContinueForm = () => {
        modalApi.showModal(<WMICUpdateFinanceFormModal
            title={translator(messages.updateFinanceFormConfirmModalTitle)}
            subtitle={translator(messages.updateFinanceFormConfirmModalSubtitle)}
            primaryButtonMessage={translator(messages.updateFinanceFormConfirmModalSubmit)}
            secondaryButtonMessage={translator(messages.updateFinanceFormConfirmModalCancel)}
            onPrimaryAction={() => onSubmit(data)}
        />).catch(_.noop);
    };

    const dataChangeHandler = (value, path) => {
        const newData = { ...data };
        newData[path] = value;
        setData(newData);
    };

    const getUpdateFinanceFormButtonSection = () => (
        <Fragment>
            <WMICButton type="outlined" onClick={handleCancelForm}>
                { translator(messages.cancel) }
            </WMICButton>
            <WMICButton
                type="primary"
                disabled={!isValid || data.zip.toString().length === 1}
                onClick={handleContinueForm}
            >
                { translator(messages.continue) }
            </WMICButton>
        </Fragment>
    );

    const overrideProps = {
        '@all': (id) => ({
            messageProps: {
                requiredField: `${translator(messages[`${id}Label`])} field is required`
            }
        }),
        updateFinanceFormSelectVehicleContainer: {
            visible: vehicleFormVisible,
        },
        updateFinanceFormSelectVehicleAriaLabel: {
            content: translator(messages.updateFinanceFormVehicleDropdownPlaceholder)
        },
        updateFinanceFormSelectVehicle: {
            label: translator(messages.updateFinanceFormVehicleDropdownLabel),
            labelClassName: 'wmic-dropdown-label',
            placeholder: translator(messages.updateFinanceFormVehicleDropdownPlaceholder),
            availableValues: getVehicleFormAvailableValues(),
            onValueChange: (value) => dataChangeHandler(value, 'vehicle'),
            value: data.vehicle,
            required: true,
            'aria-hidden': true
        },
        updateFinanceButtonSection: {
            content: getUpdateFinanceFormButtonSection(),
        },
        updateFinanceFormZIP: {
            validator: {
                pattern: '^\\d{5}(-\\d{4})?$',
                message: translator(messages.zipCodeIncorrect)
            },
            messageProps: {
                requiredField: translator(messages.zipCodeIncorrect)
            }
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICDropdownSelect: WMICDropdownSelect,
        }
    };

    return (
        <MetadataForm
            data={data}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrideProps}
            model={policyVM}
            onValidationChange={(value) => {
                setIsValid(value);
            }}
            onDataChange={(value, path) => dataChangeHandler(value, path)} />
    );
}

WMICUpdateFinanceFormComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    vehicleData: PropTypes.shape({}).isRequired
};

export default WMICUpdateFinanceFormComponent;