import { useEffect, useCallback } from 'react';

export function useTokenExpiration({ tokenManager, onAccessTokenExpired }) {
    const onTokenExpired = useCallback((tokenType) => {
        if (tokenType === 'accessToken') {
            onAccessTokenExpired();
        }
    }, [onAccessTokenExpired]);

    useEffect(() => {
        if (tokenManager) {
            tokenManager.on('expired', onTokenExpired);
        }
        return () => {
            if (tokenManager) {
                tokenManager.off('expired', onTokenExpired);
            }
        };
    }, [onTokenExpired, tokenManager]);
}
