import Crypto from 'crypto-browserify';
import _ from 'lodash';

function getEmailHash(authData) {
    const email = _.get(authData, 'email');
    return Crypto.createHash('SHA256').update(email).digest('hex');
}

function hasAddPolicyReminderCookie(authData, cookies) {
    const hash = getEmailHash(authData);
    const cookie = cookies[hash];
    return cookie !== undefined;
}

function setAddPolicyReminderCookie(authData, onSetCookies) {
    const now = new Date();
    const expiryDate = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
    const hash = getEmailHash(authData);
    onSetCookies(
        hash, 'true', { expires: expiryDate }
    );
}

function getConsentHash(authData) {
    const consentText = 'AMPEmailConsentRequestedFor';
    const email = _.get(authData, 'email');
    return Crypto.createHash('SHA256').update(consentText + email).digest('hex');
}

function getEmailPolicyNumberHash(authData, policyNumber) {
    const email = _.get(authData, 'email');
    return Crypto.createHash('SHA256').update(email + policyNumber).digest('hex');
}

function hasShowConsentCookie(authData, cookies) {
    const hash = getConsentHash(authData);
    const cookie = cookies[hash];
    return cookie !== undefined;
}

function setShowConsentCookie(authData, onSetCookies) {
    const now = new Date();
    const expiryDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const hash = getConsentHash(authData);
    onSetCookies(
        hash, 'true', { expires: expiryDate }
    );
}

function removeShowConsentCookie(authData, cookies) {
    const hash = getConsentHash(authData);
    cookies.remove(hash);
}


function hasDontShowAgainCookie(authData, cookies, policyNumber) {
    const hash = getEmailPolicyNumberHash(authData, policyNumber);
    const cookie = cookies[hash];
    return cookie !== undefined;
}

function setDontShowAgainCookie(authData, onSetCookies, policyNumber) {
    const now = new Date();
    const expiryDate = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
    const hash = getEmailPolicyNumberHash(authData, policyNumber);
    onSetCookies(
        hash, 'true', { expires: expiryDate }
    );
}

export default {
    setAddPolicyReminderCookie,
    hasAddPolicyReminderCookie,
    setShowConsentCookie,
    hasShowConsentCookie,
    removeShowConsentCookie,
    hasDontShowAgainCookie,
    setDontShowAgainCookie
};
