import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { Wizard } from 'wmic-pe-portals-custom-wizard-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { CONSTANTS, MODAL_CONSTANTS, WIZARD_STEP_PATHS, WMICValidationUtil } from 'wmic-pe-portals-utils-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { WizardModalsProvider, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import { messages as commonRenewalMessages} from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import { TranslatorContext } from '@jutro/locale';
import wizardConfig from './config/pup-renewal-wizard-config.json5';
import wizardStepToFieldMapping from './config/pup-wizard-step-to-field-mapping.json5';
import messages from './WMICPEPUPRenewalWizard.messages';

function checkValidity(jobVM, extractVMObject, currentStep) {
    const checkValidityPathsForCurrentStep = _.get(currentStep, 'checkValidityPaths', []);

    // Cloning checkValidityPaths to avoid adding duplicates to array
    const checkValidityPaths = _.clone(checkValidityPathsForCurrentStep);

    // For Underlying Policies & Household Members step, only validate if user has started editing the page
    if (currentStep.path === WIZARD_STEP_PATHS.PUP.HOUSEHOLD_MEMBERS || currentStep.path === WIZARD_STEP_PATHS.PUP.UNDERLYING_POLICIES) {
        if (!_.get(jobVM, 'isEditingPage.value', false)) {
            return true;
        }
    }

    return WMICValidationUtil.checkValidity(jobVM, checkValidityPaths);
}

function getTargetPageIndex(steps) {
    const WMICQuotePageRegex = /(QuotePage)$/;

    for (let stepIndex = 0; stepIndex < steps.length; stepIndex += 1) {
        if (WMICQuotePageRegex.test(steps[stepIndex].id)) {
            return stepIndex;
        }
    }

    return undefined;
}

function WMICPEPUPRenewalWizard() {
    const { steps } = wizardConfig;
    const [initialRenewal, setInitialRenewal] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isCustomStepsInvalidated, setIsCustomStepsInvalidated] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const history = useHistory();
    const location = useLocation();
    const { authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const { RenewalService } = useDependencies('RenewalService');
    const { showConfirm, showWarning } = useWizardModals();

    useEffect(() => {
        if (!location.state) {
            history.push('/');

            return;
        }

        const { state: { policyNumber, selectedTerm } } = location;
        const { state } = location;

        if (!policyNumber) {
            return;
        }

        const errorModalBox = (errorMessage) => {
            showConfirm(errorMessage).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }

                setIsLoading(false);

                return history.push(`/contactAgent/${policyNumber}/${selectedTerm}`);
            }, _.noop);
        };

        RenewalService.retrieve(state.renewalentry.jobID, authHeader)
            .then((response) => {
                const renewal = viewModelService.create(
                    response,
                    'pc',
                    'wmic.edge.ca.capabilities.renewal.dto.RenewalDataDTO'
                );

                steps.forEach((step) => _.set(step, 'stepToFieldMapping', wizardStepToFieldMapping[step.id]))

                setInitialRenewal(renewal);
                setIsLoading(false);
            })
            .catch(() => {
                errorModalBox({
                    title: commonMessages.unableToLoadDraftPolicy,
                    message: commonMessages.somethingWentWrong,
                    status: MODAL_CONSTANTS.STATUS.WARNING,
                    icon: MODAL_CONSTANTS.ICON.WARNING,
                });
            })
            .catch(() => {
                errorModalBox({
                    title: commonMessages.unableToStartPolicy,
                    message: commonMessages.anErrorOccurred,
                    status: MODAL_CONSTANTS.STATUS.WARNING,
                    icon: MODAL_CONSTANTS.ICON.WARNING,
                });
            });
    },
    // Disabled so we don't rerun this function on every rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const handleOnCancel = useCallback((cancelParams) => {
        const {
            wizardData: policyChangeVM,
            wizardSnapshot,
            currentStepIndex,
            steps: wizardSteps,
            param: nextPath
        } = cancelParams;
        const confirmationPageIndex = wizardSteps.length - 1;

        let lastPathName = "";

        if (!_.isEmpty(nextPath)) {
            lastPathName = nextPath.replace(/\/?.*\//g, '');
        }

        if ((!_.isEmpty(lastPathName) && (CONSTANTS.RENEWAL_SKIP_CANCEL_MODAL_URLS.includes(lastPathName))) 
            || currentStepIndex === confirmationPageIndex || hasErrorOccurred
        ) {
            return true;
        }

        return showConfirm({
            title: commonRenewalMessages.leaveWithoutSaving,
            message: commonRenewalMessages.infoWillBeSavedAsDraft,
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.WARNING,
            cancelButtonText: platformMessages.cancelModel
        }).then((results) => {
            if (results === 'cancel') {
                return _.noop();
            }

            setIsLoading(true);

            const isValid = policyChangeVM.aspects.valid && policyChangeVM.aspects.subtreeValid;
            const payload = (isValid) ? _.get(policyChangeVM, 'value') : _.get(wizardSnapshot, 'value');
            const jobID = _.get(policyChangeVM.value, 'jobID');
            const quoteStatus = _.get(wizardSnapshot, 'baseData.periodStatus.value.code')
                || _.get(policyChangeVM.value, 'status');
            const isStatusQuoted = quoteStatus !== CONSTANTS.QUOTED;
            const policyNumber = _.get(policyChangeVM.value, 'policyNumber');
            let exitPromise;
            let redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/renewal/${jobID}/summary`;

            if (appConfig.persona === 'policyholder') {
                redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/account-policy-details/${policyNumber}`;
            }

            if (appConfig.persona === 'policyholder') {
                if (quoteStatus !== CONSTANTS.BOUND) {
                    exitPromise = RenewalService.saveWithNoValidationRuleCheck(payload, authHeader);
                } else {
                    return history.push(redirectLobPath);
                }
            } else if (isStatusQuoted) {
                exitPromise = RenewalService.saveWithNoValidationRuleCheck(payload, authHeader);
            } else {
                return history.push(redirectLobPath);
            }

            exitPromise.then(() => {
                history.push(redirectLobPath);
            }).catch(() => {
                showConfirm({
                    title: commonRenewalMessages.unableToDraftRenewal,
                    message: commonRenewalMessages.anErrorOccurred,
                    status: MODAL_CONSTANTS.STATUS.WARNING,
                    icon: MODAL_CONSTANTS.ICON.WARNING
                });
            }).finally(() => {
                setIsLoading(false);
            });

            return true;
        }, _.noop);
    }, [RenewalService, authHeader, hasErrorOccurred, history, showConfirm]);

    const handleError = useCallback((error) => {
        const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
        const jobIDFromLocation = _.get(location, 'state.policyNumber');
        const termNumberFromLocation = _.get(location, 'state.selectedTerm')
        let redirectPath = `/contactAgent/${jobIDFromLocation}/${termNumberFromLocation}`;

        if (!_.isEmpty(jobIDFromWizard)) {
            redirectPath = `/renewal/${jobIDFromWizard}/summary`;
        }

        const state = {
            pathname: redirectPath,
            state: {
                redirectPath: '/home',
            }
        };

        return showWarning({
            title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
            message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.ERROR,
            confirmButtonText: platformMessages.ok
        }).then(() => {
            setHasErrorOccurred(true);

            return <Redirect to={state} />;
        }, _.noop);
    }, [location, showWarning]);

    if (isLoading) {
        return (
            <Loader loaded={!isLoading} />
        );
    }

    if (!initialRenewal) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <WizardModalsProvider defaultMessage={translator(messages.savingTransactionDetails)}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={translator(messages.progress)}
                    initialData={initialRenewal}
                    onCancel={handleOnCancel}
                    onPreviousModalProps={{
                        title: commonRenewalMessages.wantToJump,
                        message: commonRenewalMessages.wantToJumpMessage,
                        status: MODAL_CONSTANTS.STATUS.WARNING,
                        icon: MODAL_CONSTANTS.ICON.WARNING,
                        confirmButtonText: platformMessages.yes,
                        cancelButtonText: platformMessages.no
                    }}
                    checkValidity={checkValidity}
                    skipCompletedSteps
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    maintainFurtherStepsVisitedSubmitted={{
                        flag: !isCustomStepsInvalidated,
                        targetStep: getTargetPageIndex(steps),
                    }}
                    setIsCustomStepsInvalidated={setIsCustomStepsInvalidated}
                />
            </WizardModalsProvider>
        </ErrorBoundary>
    );
}

WMICPEPUPRenewalWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            requestType: PropTypes.string,
            policyDetails: PropTypes.shape({
                effective: PropTypes.instanceOf(Date)
            })
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
};

export default withViewModelService(WMICPEPUPRenewalWizard);
