import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from 'wmic-digital-auth-react';
import { MetadataContent } from '@jutro/uiconfig';
import { withModalContext } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { withDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import DocumentComponent from '../../components/DocumentsComponent/DocumentsComponent';
import documentMessages from '../../Document.messages';
import metadata from './DocumentsPage.metadata.json5';
import messages from './DocumentsPage.messages';

const DOCUMENTS_KEY = 'Documents';

class DocumentsPageWithoutModalContext extends Component {
    static propTypes = {
        DocumentPageService: PropTypes.shape({
            generateUploadToken: PropTypes.func,
            uploadDocument: PropTypes.func,
            getDocuments: PropTypes.func,
            removeDocument: PropTypes.func,
            downloadDocument: PropTypes.func,
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        jobNumber: PropTypes.string,
        accountNumber: PropTypes.string,
        policyNumber: PropTypes.string,
        setDocumentCount: PropTypes.func,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired
    };

    static defaultProps = {
        jobNumber: undefined,
        policyNumber: undefined,
        accountNumber: undefined,
        setDocumentCount: undefined
    };

    state = {
        documentsData: [],
        isLoading: true
    };

    componentDidMount() {
        this.getDocuments();
    }

    getCorrectNumber = () => {
        const { jobNumber, accountNumber, policyNumber } = this.props;
        return jobNumber || accountNumber || policyNumber;
    }

    getDocuments = async () => {
        const {
            authHeader, setDocumentCount, DocumentPageService
        } = this.props;

        this.setState({ isLoading: true });
        await DocumentPageService.getDocuments(this.getCorrectNumber(), authHeader)
            .then((availableDocumentsData) => {
                this.setState({
                    documentsData: availableDocumentsData
                });
                if (setDocumentCount) {
                    setDocumentCount(availableDocumentsData.length, DOCUMENTS_KEY);
                }
            }).catch(() => {
                this.props.modalContext.showAlert({
                    title: messages.modalError,
                    message: messages.errorLoadingDocument,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }).finally(() => {
                this.setState({ isLoading: false });
            });
    };

    generateUploadToken = () => {
        const { DocumentPageService, authHeader } = this.props;
        return DocumentPageService.generateUploadToken(authHeader).catch(() => {
            return this.props.modalContext.showAlert({
                title: commonMessages.errorUploadTitle,
                message: commonMessages.errorGenerateUploadToken,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        });
    };

    uploadDocument = async (file) => {
        const {
            authHeader,
            jobNumber,
            accountNumber,
            policyNumber,
            DocumentPageService
        } = this.props;
        const documentMetaDataTemplate = {
            jobNumber: jobNumber,
            accountNumber: accountNumber,
            policyNumber: policyNumber,
            name: file.name,
            sessionID: await this.generateUploadToken()
        };

        this.setState({ isLoading: true });
        await DocumentPageService.uploadDocument(
            file, documentMetaDataTemplate, authHeader
        ).then((documentUploadData) => {
            if (!_.isEmpty(documentUploadData)) {
                return this.getDocuments();
            }
            return null;
        }).catch(() => {
            this.props.modalContext.showAlert({
                title: commonMessages.errorUploadTitle,
                message: commonMessages.uploadFailedMessage,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    };

    deleteDocument = async (publicID) => {
        const { authHeader, DocumentPageService } = this.props;

        this.setState({ isLoading: true });
        await DocumentPageService.removeDocument(publicID, authHeader).then((isDeleteItem) => {
            if (isDeleteItem) {
                this.getDocuments();
            }
        }).catch(() => {
            this.props.modalContext.showAlert({
                title: documentMessages.documentDeletionFailed,
                message: documentMessages.documentCannotDeleted,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    };

    downloadDocument = (publicID, sessionID) => {
        const { DocumentPageService } = this.props;
        return DocumentPageService.downloadDocument(
            publicID,
            sessionID
        );
    };

    static contextType = TranslatorContext;


    render() {
        const { documentsData, isLoading } = this.state;
        const translator = this.context;
        const overrides = {
            documents: {
                initialDocumentsData: documentsData,
                uploadDocument: this.uploadDocument,
                deleteDocument: this.deleteDocument,
                isLoading: isLoading,
                downloadDocument: this.downloadDocument,
                noDataMessage: translator(messages.noPolicyChangeDocuments)
            }
        };
        const resolvers = {
            resolveComponentMap: {
                documentscomponent: DocumentComponent
            }
        };
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

const DocumentsPage = withModalContext(DocumentsPageWithoutModalContext);

export default withAuthenticationContext(withDependencies(['DocumentPageService'])(DocumentsPage));
