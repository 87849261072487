/* eslint-disable no-secrets/no-secrets */
import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import {
    CONSTANTS,
    LINE_OF_BUSINESS,
    POLICY_DETAILS,
    WMICDateTimeService,
    WMICCreditConsentUtil,
    DISTRIBUTION_METHODS,
    LOBConstants,
    JobType,
    WMICLogger
} from 'wmic-pe-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICDropdownMenuButton, WMICListView } from 'wmic-pe-components-platform-react';
import { Flex } from '@jutro/layout';
import { Icon } from '@jutro/components';

import WMICPolicyChangeUtil from 'wmic-pe-capability-gateway-common-react/utils/WMICPolicyChangeUtil';
import WMICNamedInsuredDetailView from './WMICNamedInsuredDetailView/WMICNamedInsuredDetailView';

import styles from './WMICNamedInsuredComponent.module.scss';
import messages from './WMICNamedInsuredComponent.messages.js';

const PNI_PATH = 'baseData.primaryNamedInsured_WMIC';
const ANIS_PATH = 'baseData.additionalNamedInsureds_WMIC';

function WMICNamedInsuredComponent(props) {

    const {
        id,
        onValidate,
        jobVM,
        updateWizardData,
        showAdditionalInsured,
        updateShowAdditionalInsured,
        lob,
        authHeader,
        isCreditInfoWithdrawalConsentApplicable,
        isPNI,
        showCreditConsentInfoPNI,
        isRPCEffective,
        showErrors: pShowErrors,
        isReadOnly,
        setLinkedContact
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const { RenewalService } = useDependencies('RenewalService');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const translator = useContext(TranslatorContext);
    const { showConfirm, setWizardLoading } = useWizardModals();

    const [showErrors, setShowErrors] = useState(false);
    const [relationshipOptions, setRelationshipOptions] = useState([]);
    const [showPNI, setShowPNI] = useState(true);
    const [pniChangePublicID, setPniChangePublicID] = useState(null);
    const isPolicyRenewal = _.get(jobVM, 'baseData.jobType.value.code') === JobType.RENEWAL;
    const isPolicyChange = _.get(jobVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE;
    const isSubmission = _.get(jobVM, 'baseData.jobType.value.code') === JobType.SUBMISSION;

    useEffect(() => {
        getRelationshipValues();
        setTogglePrimaryInsuredValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isPA = lob === LINE_OF_BUSINESS.PERSONAL_AUTO;

    const isHO = lob === LINE_OF_BUSINESS.HOME_OWNER;

    const isCommercialSmallBusiness = lob === LINE_OF_BUSINESS.GENERAL_LIABILITY;

    const setTogglePrimaryInsuredValues = useCallback(() => {
        _.set(jobVM, `${PNI_PATH}.value.togglePrimaryInsuredValue`, true);
        _.get(jobVM, `${ANIS_PATH}.value`).forEach((item) => {
            _.set(item, 'togglePrimaryInsuredValue', false);
        });
    }, [jobVM])

    const saveJob = useCallback(async (job) => {
        try {
            setWizardLoading(true);

            const newJobVM = _.cloneDeep(job.value);

            if (isPolicyRenewal) {
                jobVM.value = await RenewalService.saveRenewal(
                    [newJobVM],
                    authHeader
                );
            } 
            else if (isSubmission) {
                if (isCommercialSmallBusiness) {
                    const result = await LoadSaveService.saveWithNoValidationRuleCheck(
                        newJobVM,
                        authHeader
                    );
                    _.extend(jobVM.value, result);
                }
            } else {
                jobVM.value = await EndorsementService.saveEndorsement(
                    [newJobVM],
                    authHeader
                );
            }

            // add back the togglePrimaryInsuredValue, as it's getting lost after save (needed to display the right value without refreshing the page)
            setTogglePrimaryInsuredValues();
            updateWizardData(jobVM);

            return true;
        } catch (err) {
            WMICLogger.error("Save job failed", err);

            return false;
        } finally {
            setWizardLoading(false);
        }
    }, [EndorsementService, RenewalService, isPolicyRenewal, authHeader, jobVM, setTogglePrimaryInsuredValues, setWizardLoading, updateWizardData, isSubmission]);

    const createVM = useCallback((model) => {
        const newVM = viewModelService.create(
            model,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.draft.dto.AdditionalNamedInsuredDTO_WMIC',
            jobVM.aspects.context()
        );

        return newVM;
    }, [jobVM.aspects, viewModelService]);

    const onEditInsured = useCallback(() => {
        _.set(jobVM, 'isEditingPage.value', true);
        // To invalidate Quote step when adding a new insured
        updateWizardData(jobVM);
    }, [jobVM, updateWizardData])

    const toCreateInsured = useCallback(async (subtype) => {
        onEditInsured();

        let skeleton;
        
        if (isPolicyChange) {
            skeleton = await EndorsementService.getSkeletonStructure(
                jobVM.value,
                authHeader
            );
        }

        const additionalInsuredAddress = {
            country: CONSTANTS.COUNTRY.CA,
            city: '',
            state: '',
            postalCode: '',
        };
        
        const pniAddress = _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.primaryAddress.value');

        const newVM = createVM({
            'metaDataMap': _.get(skeleton, `${ANIS_PATH}[0].${CONSTANTS.METADATAMAP}`),
            'subtype': _.lowerCase(subtype),
            'relationshipToPrimaryInsured_WMIC': isCommercialSmallBusiness || _.upperFirst(subtype) === CONSTANTS.Company ? POLICY_DETAILS.NOTRELATED : '',
            'locationAddress': _.upperFirst(subtype) === CONSTANTS.Company ? additionalInsuredAddress : pniAddress
        });

        setDefaultDistributionMethod(newVM);
        updateShowAdditionalInsured(true);

        jobVM.baseData.additionalNamedInsureds_WMIC.value.push(newVM.value);

        return newVM;
    }, [EndorsementService, authHeader, createVM, isCommercialSmallBusiness, isPolicyChange, jobVM, onEditInsured, updateShowAdditionalInsured]);

    const toUndoCreateInsured = useCallback(() => {
        const insureds = _.get(jobVM.value, ANIS_PATH);

        insureds.splice(insureds.length - 1, 1);
        _.set(jobVM.value, ANIS_PATH, insureds);
        updateShowAdditionalInsured(false);
        _.set(jobVM, 'isEditingPage.value', false);
        updateWizardData(jobVM);
    }, [jobVM, updateShowAdditionalInsured, updateWizardData]);

    const onCancelInsured = () => {
        _.set(jobVM, 'isEditingPage.value', false);
        updateWizardData(jobVM);
    }

    const isDriver = useCallback((publicID) => {
        const drivers = _.get(jobVM, 'lobData.personalAuto.coverables.drivers.value', [])

        const driverMatch = drivers.some((driver) => _.get(driver, 'person.publicID') === publicID);

        return driverMatch;
    }, [jobVM]);

    const setDOBRequired = useCallback((ani) => {
        if (isHO) {
            if (showCreditConsentInfoPNI()) {
                _.set(
                    jobVM,
                    'primaryNamedInsured_WMIC.dateOfBirthIsRequired.value',
                    _.get(jobVM, 'baseData.creditConsentReceived.value.code') === CONSTANTS.CREDIT_CONSENT_CODES.YES
                );
            }
        } else if (isPA) {
            if (showPNI) {
                const primaryPublicId = pniChangePublicID || _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.publicID.value');
                const requireDOBWhenDriver = isDriver(primaryPublicId);

                if (isRPCEffective('1296') && !isRPCEffective('1576')) {
                    _.set(jobVM, 'baseData.primaryNamedInsured_WMIC.dateOfBirthIsRequired.value', requireDOBWhenDriver)
                        || _.get(jobVM, 'baseData.creditConsentReceived.value.code') === CONSTANTS.CREDIT_CONSENT_CODES.YES;
                } else {
                    _.set(jobVM, 'baseData.primaryNamedInsured_WMIC.dateOfBirthIsRequired.value', requireDOBWhenDriver);
                }

                setPniChangePublicID(null);
            } else if (showAdditionalInsured) {
                _.set(ani, 'dateOfBirthIsRequired.value', isDriver(_.get(ani, 'contactPublicID.value')));
            }
        }
    }, [isDriver, isHO, isPA, isRPCEffective, jobVM, pniChangePublicID, showAdditionalInsured, showCreditConsentInfoPNI, showPNI])

    const changePrimaryInsured = useCallback((ani) => {
        if (_.get(jobVM, 'baseData.additionalNamedInsureds_WMIC.value', []).length > 1) {
            _.get(jobVM, 'baseData.additionalNamedInsureds_WMIC.value').forEach((item) => {
                if (item.publicID !== ani.publicID) {
                    _.set(item, 'relationshipToPrimaryInsured_WMIC', 'unknown');
                }
            });
        }

        WMICPolicyChangeUtil.changePrimaryInsured(jobVM, ani, viewModelService);

        setShowPNI(true);
        updateShowAdditionalInsured(false);
        setPniChangePublicID(ani.contactPublicID.value);
        setDOBRequired(ani);

    }, [jobVM, setDOBRequired, updateShowAdditionalInsured, viewModelService]);

    const updateDrivers = useCallback((namedInsured) => {
        const allDrivers = _.get(jobVM, 'lobData.personalAuto.coverables.drivers.value', []);

        if (WMICCreditConsentUtil.isAniCreditConsentable(namedInsured)
            && allDrivers
            && allDrivers.length > 0) {
            namedInsured = namedInsured.value;

            let found = false;

            for (let driverCounter = 0; !found && driverCounter < allDrivers.length; driverCounter++) {
                const driver = allDrivers[driverCounter];

                if (driver.person.publicID === namedInsured.contactPublicID || driver.person.publicID === namedInsured.publicID) {
                    driver.dateOfBirth = namedInsured.dateOfBirth;
                    driver.creditConsentReceived = namedInsured.creditConsentReceived;
                    driver.creditConsentDate = namedInsured.creditConsentDate;
                    driver.creditInfoWithdrawalConsent = namedInsured.creditInfoWithdrawalConsent;
                    driver.personalInfoConsentForm = namedInsured.personalInfoConsentForm;
                    found = true;
                }
            }
        }
    }, [jobVM]);

    const isInsuredValid = (insured) => insured?.aspects?.valid && insured?.aspects?.subtreeValid

    const onSaveInsured = useCallback(async (insured, index) => {
        // Policy Renewal includes PNI as first array item
        if (!isSubmission) {
            index -= 1;
        }

        const isANI = insured._dtoName === "wmic.edge.ca.capabilities.policyjob.draft.dto.AdditionalNamedInsuredDTO_WMIC";
        const togglePrimaryInsuredValue = _.get(insured.value, 'togglePrimaryInsuredValue', false);

        // save ANI
        if (isANI) {
            if (togglePrimaryInsuredValue) {
                changePrimaryInsured(insured);
                setTogglePrimaryInsuredValues();

                if (isPA) {
                    setLinkedContact(true);
                }

                return saveJob(jobVM);
            }

            const currentNamedInsureds = _.get(jobVM, `${ANIS_PATH}.value`);

            currentNamedInsureds[index] = insured.value;

            const displayName = `${insured.value.firstName} ${insured.value.lastName}`

            _.set(jobVM, `${ANIS_PATH}.value`, currentNamedInsureds);
            _.set(jobVM, `${ANIS_PATH}.value[${currentNamedInsureds.length - 1}].displayName`, displayName)

            if (isPA) {
                updateDrivers(insured);
                setLinkedContact(true);
            }

            updateShowAdditionalInsured(false);

            _.set(jobVM, 'isEditingPage.value', false);
            return saveJob(jobVM);
        } else {
            // save PNI
            _.set(jobVM, `${PNI_PATH}.value`, insured.value);
            _.set(jobVM, 'isEditingPage.value', false);
            return saveJob(jobVM);
        }
        
    }, [changePrimaryInsured, isPA, isSubmission, jobVM, saveJob, setLinkedContact, setTogglePrimaryInsuredValues, updateDrivers, updateShowAdditionalInsured, updateWizardData]);

    const checkForLinkedContact = useCallback((contact) => {
        const linkedDriver = _.get(jobVM, 'lobData.personalAuto.coverables.drivers.value', []).some((driver) => driver.person.publicID === contact.contactPublicID);

        const linkedOwner = _.get(jobVM, 'lobData.personalAuto.coverables.vehicles.value', []).some((vehicle) => vehicle.registeredOwners_WMIC.some(
            (registeredOwner) => registeredOwner.contactPublicID === contact.contactPublicID)
        );

        return (linkedDriver || linkedOwner);
    }, [jobVM]);

    const onDeleteInsured = useCallback(async (item, index) => {
        // Policy Renewal includes PNI as first array item
        if (!isSubmission) {
            index -= 1;
        }

        const name = item.subtype.value.code === CONSTANTS.Company.toLowerCase() ? item.companyName.value : item.firstName.value;

        const response = await showConfirm({
            title: translator(messages.removeANIShort),
            message: translator(messages.removeANILong, { displayName: name })
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            setLinkedContact(false);

            if (isPA && checkForLinkedContact(item)) {
                setLinkedContact(true);
            }

            const allAnis = _.get(jobVM, `${ANIS_PATH}.value`);

            allAnis.splice(index, 1);
            _.set(jobVM, `${ANIS_PATH}.value`, allAnis);

            return saveJob(jobVM);
        }
    }, [checkForLinkedContact, isPA, isSubmission, jobVM, saveJob, setLinkedContact, showConfirm, translator, updateWizardData]);

      
    const setDefaultDistributionMethod = (aniViewModel) => {
        aniViewModel.prefDistributionMethod_WMIC.value =
            _.find(
                aniViewModel.prefDistributionMethod_WMIC.aspects.availableValues,
                (distributionMethod) => distributionMethod.code === DISTRIBUTION_METHODS.MAIL
            );
    };

    const getInsuredDisplayName = (insuredVM) => {
        const insured = insuredVM.value;
        const insuredType = _.upperFirst(_.get(insured, 'subtype'));

        if (insuredType === CONSTANTS.Company) {
            return _.get(insured, 'displayName', '');
        }

        if (insuredType === CONSTANTS.Person) {
            const firstName = _.get(insured, 'firstName', '');
            const lastName = _.get(insured, 'lastName', '');

            return (<React.Fragment>
                {_.get(jobVM, 'isFromBMS_WMIC.value', false)
                    && !_.get(jobVM, 'baseData.additionalNamedInsureds_WMIC', []).findFirstElement(insured).aspects.subtreeValid
                    && <Icon icon="mi-error" />}
                {`${firstName} ${lastName}`}
            </React.Fragment>
            );
        }
    };

    const getRelationshipStatus = (row, path, rowIndex) => !isSubmission && rowIndex === 0
        ? translator(messages.insured) :
        {
            id: row.relationshipToPrimaryInsured_WMIC.value?.name,
            defaultMessage: row.relationshipToPrimaryInsured_WMIC.value?.name
        };

    const getAddress = (ani) => {
        const location = _.get(ani.value, 'locationAddress');

        let result = '';

        if (location && location.addressLine1) {
            result = `${location.addressLine1}`;
        }

        return result;
    };

    const getRelationshipValues = () => {
        const APPLICATION_RELATION_TYPELIST = viewModelService.productMetadata.get('pc').types
            .getTypelist('ApplicantRelation_wmic').getFilter('AddNamedInsuredFilter').codes;

        const categoryCode = isPA ? LOBConstants.PA : LOBConstants.PERSONAL_PROPERTY;
        const filteredRelations = _.filter(APPLICATION_RELATION_TYPELIST, (applicantRelationType) => applicantRelationType.belongsToCategory({ code: categoryCode, typelist: { name: 'PolicyType_WMIC' } }))

        const filteredRelationsKey = filteredRelations.map((aiTypeKey) => ({
            ...aiTypeKey,
            name: {
                id: aiTypeKey.name,
                defaultMessage: aiTypeKey.name
            }
        }));

        setRelationshipOptions(filteredRelationsKey);
    }

    const displayDate = (data, path) => {
        const dateValue = _.get(data.value, path);

        return dateValue ? <span>{WMICDateTimeService.toMidnightDate(dateValue)}</span> : '';
    };

    const renderAddAIButton = useCallback(({ isEditing, onClick }) => (
        <Flex className="gw-mb-6" justifyContent="left">
            <WMICDropdownMenuButton
                id="addNewAdditionalInterestButton"
                type="secondary"
                size="medium"
                buttonText={!isSubmission ? translator(messages.addNamedInsured) : translator(messages.nit)}
                alignRight
                menuClassName={styles.newAIDropdownMenuButton}
                onItemClick={onClick}
                items={[
                    { id: 'newPerson', text: translator(messages.person), icon: 'mi-person', code: CONSTANTS.Person },
                    { id: 'newCompany', text: translator(messages.company), icon: 'mi-home', code: CONSTANTS.Company },
                ]}
                disabled={isEditing}
            />
        </Flex>
    ), [isSubmission, translator]);


    const pniPath = _.get(jobVM, PNI_PATH, {});
    const anisPath = _.get(jobVM, `${ANIS_PATH}.children`, []);

    const listViewValue = useMemo(() =>
        !isSubmission
            ? [pniPath, ...anisPath]
            : anisPath
        // watch for changes in publicID of pni (it happens on switching the insured from additional to primary)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [isSubmission, pniPath, pniPath.publicID.value, anisPath])

    return (
        <WMICListView
            id={id}
            VMData={[
                {
                    headerText: translator(messages.name),
                    getData: getInsuredDisplayName
                },
                {
                    headerText: translator(messages.relationship),
                    getData: getRelationshipStatus,
                    visibilityCondition: () => !isCommercialSmallBusiness
                },
                {
                    headerText: translator(messages.nit),
                    path: 'subtype',
                    visibilityCondition: () => isSubmission
                },
                {
                    headerText: translator(messages.address),
                    getData: getAddress,
                    visibilityCondition: () => isCommercialSmallBusiness
                },
                {
                    headerText: translator(messages.dob),
                    getData: displayDate,
                    path: 'dateOfBirth',
                    visibilityCondition: () => !isSubmission
                },
                {
                    headerText: translator(messages.primaryNamedInsured),
                    getData: (row, path, rowIndex) => translator(rowIndex === 0 ? messages.primaryNamedInsuredYes : messages.primaryNamedInsuredNo),
                    visibilityCondition: () => !isSubmission
                },
            ]}
            value={listViewValue}
            detailViewComponent={WMICNamedInsuredDetailView}
            detailViewComponentProps={{
                authHeader,
                onValidate,
                jobVM,
                relationshipOptions,
                isCommercialSmallBusiness,
                lob,
                isCreditInfoWithdrawalConsentApplicable,
                isPNI
            }}
            renderAddButton={renderAddAIButton}
            flatCards
            clickable
            readOnly={isReadOnly}
            startOpen={false}
            onValidate={onValidate}
            toCreate={toCreateInsured}
            toUndoCreate={toUndoCreateInsured}
            onCancel={onCancelInsured}
            showCancelModal
            onSave={onSaveInsured}
            onDelete={onDeleteInsured}
            canDelete={(index, value) => value?._dtoName === "wmic.edge.ca.capabilities.policyjob.draft.dto.AdditionalNamedInsuredDTO_WMIC"}
            showErrors={showErrors || pShowErrors}
            toEdit={onEditInsured}
        />
    )
}

WMICNamedInsuredComponent.propTypes = {
    jobVM: PropTypes.object.isRequired,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    updateWizardData: PropTypes.func.isRequired,
    isCreditInfoWithdrawalConsentApplicable: PropTypes.func.isRequired,
    lob: PropTypes.string.isRequired,
    updateShowAdditionalInsured: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool,
};

WMICNamedInsuredComponent.defaultProps = {
    jobVM: {},
    onValidate: undefined,
    updateWizardData: undefined,
    isEditMode: false,
    showErrors: false
};

export default WMICNamedInsuredComponent;