/* eslint-disable no-param-reassign */
import React, {
    useContext,
    useState,
    useMemo,
    useCallback
} from 'react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { Link } from '@jutro/components';
import WMICClickableIconTooltipComponent from 'gw-capability-policy-common-react/components/WMICClickableIconTooltipComponent/WMICClickableIconTooltipComponent';

import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICRentalVehicleInfoBox.metadata.json5';
import messages from './WMICRentalVehicleInfoBox.messages';
import styles from './WMICRentalVehicleInfoBox.module.scss';

const WMICRentalVehicleInfoBox = (props) => {
    const { claimData: claimInfo } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    // eslint-disable-next-line camelcase
    const [rentalDetails] = useState(claimInfo?.lobs?.personalAuto?.rentalInfo_WMIC || []);

    const showExceedDailyLimit = (dailyRate, dailyLimit) => dailyRate > dailyLimit;

    const showDailyRateExceedWarning = useCallback((dailyRate, dailyLimit) => {
        if (dailyRate && dailyLimit && !showExceedDailyLimit(dailyRate, dailyLimit)) {
            return (100 * dailyRate) / dailyLimit > 90;
        }

        return false;
    }, []);

    const showVehicleOverdue = (rentalEndDate, rentalStartDate, numDaysApproved) => {
        if (rentalEndDate) {
            return false;
        }

        if (rentalStartDate && numDaysApproved) {
            rentalEndDate = new Date(rentalStartDate);
            rentalEndDate.setDate(rentalEndDate.getDate() + numDaysApproved);

            return (new Date()).getTime() > rentalEndDate.getTime();
        }

        return false;
    };

    const getRentalLocationAndContact = useCallback((rental) => {
        const getRentalPhoneNumber = (r) => {
            const phoneNumber = r.rentalStartDate ? translator(messages.PhoneNumber18552669565) : translator(messages.PhoneNumber18552669289);

            return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link onClick={() => { window.location.href = `tel:${phoneNumber}`; }}>
                    <p>
                        { phoneNumber }
                    </p>
                </Link>
            );
        };

        const getVendorAddress = (r) => {
            if (r.vendorAddress) {
                return r.vendorAddress;
            }

            return [];
        };

        return (
            <div>
                <p className="ww-card-details-no-space ww-card-details-no-wrap">
                    <strong>{ translator(messages.RentalLocationAndContact) }</strong>
                    <br />
                </p>
                <div>
                    <div>{ translator(messages.EnterpriseRentACar) }</div>
                    <div>{ getRentalPhoneNumber(rental) }</div>
                    <div>{ getVendorAddress(rental) }</div>
                </div>
            </div>
        );
    }, [translator]);

    const getReservationNumber = useCallback((rental) => (
            <p className="ww-card-details-no-space ww-card-details-no-wrap">
                <strong>{ translator(messages.ReservationNumber) }</strong>
                <br />
                { rental.reservationNumber }
            </p>
        ), [translator]);

    const shouldCalculateRentalEndDate = (rental) => !!rental.rentalStartDate && (!rental.rentalEndDate || rental.rentalEndDate === null || rental.rentalEndDate === '');

    const getCalculatedRentalEndDate = (rentalStartDate, numDaysApproved) => {
        const calculatedDate = new Date(rentalStartDate);

        calculatedDate.setDate(calculatedDate.getDate() + numDaysApproved);

        return calculatedDate;
    };

    const getScreenReaderMessage = useCallback((rental) => {
        let message = '';

        if (!rental.rentalStartDate) {
            message += translator(messages.AvailabilityNotice);
        }

        if (showDailyRateExceedWarning(rental.dailyRate, rental.dailyLimit?.amount)) {
            message += ` ${  translator(messages.PolicyLimitNotice)}`;
        }

        if (showVehicleOverdue(rental.rentalEndDate, rental.rentalStartDate, rental.numDaysApproved)) {
            message += ` ${  translator(messages.OverdueNotice)}`;
        }

        if (showExceedDailyLimit(rental.dailyRate, rental.dailyLimit?.amount)) {
            message += ` ${  translator(messages.DailyLimitNotice)}`;
        }

        message += ` ${  translator(messages.DailyLimitNotice)}`;
        message += ` ${  translator(messages.RentalLocationAndContactAria, {
            phoneNumber: rental.rentalStartDate ? translator(messages.PhoneNumber18552669565) : translator(messages.PhoneNumber18552669289),
            vendorAddress: rental.vendorAddress

        })}`;
        
        if (rental.reservationNumber) {
            message += ` ${  translator(messages.ReservationNumberAria, {reservationNumber: rental.reservationNumber})}`;
        }

        message += ` ${  translator(messages.PolicyLimitAria, {
            policyLimit: rental.policyLimit?.amount
        })}`;
 
        message += ` ${  translator(messages.DailyLimitAria, {
            dailyLimit: rental.dailyLimit?.amount
        })}`;

        message += ` ${  translator(messages.ApprovedForAria, {
            days: `${rental.numDaysApproved} ${rental.numDaysApproved === 1 ? translator(messages.Day) : translator(messages.Days)}`
        })}`;
        
        if (rental.rentalStartDate) {
            message += ` ${  translator(messages.RentalStartDateAria, {
                startDate: intl.formatDate(new Date(rental.rentalStartDate), { year: 'numeric', month: 'numeric', day: 'numeric' })
            })}`;
        }
        
        if (shouldCalculateRentalEndDate(rental)) {
            message += ` ${  translator(messages.ExpectedReturnDateAria, {
                date: intl.formatDate(getCalculatedRentalEndDate(rental.rentalStartDate, rental.numDaysApproved), { year: 'numeric', month: 'numeric', day: 'numeric' })
            })}`;
        } else {
            message += ` ${  translator(messages.RentalEndDateAria, {
                date: intl.formatDate(new Date(rental.rentalEndDate), { year: 'numeric', month: 'numeric', day: 'numeric' })
            })}`;
        }

        if (!!rental.year && !!rental.make && !!rental.model) {
            message += ` ${  translator(messages.VehicleMakeAndModelAria, {
                vehicle: `${rental.year} ${rental.make} ${rental.model}`
            })}`; 
        }

        if (rental.dailyRate) {
            message += ` ${  translator(messages.VehicleDailyRateAria, {
                dailyRate: rental.dailyRate?.amount
            })}`; 
        }

        return message;
    }, [intl, showDailyRateExceedWarning, translator])

    const iterableOverrides = useMemo(() => {

        const overrides = rentalDetails.map((rental, index) => {
            const srMessage = getScreenReaderMessage(rental);

            return {
                [`availabilityNotice${index}`]: {
                    visible: !rental.rentalStartDate
                },
                [`availabilityNoticeText${index}`]: {
                    content: translator(messages.AvailabilityNotice)
                },
                [`policyLimitNotice${index}`]: {
                    visible: showDailyRateExceedWarning(rental.dailyRate, rental.dailyLimit?.amount)
                },
                [`policyLimitNoticeText${index}`]: {
                    content: translator(messages.PolicyLimitNotice)
                },
                [`overdueNotice${index}`]: {
                    visible: showVehicleOverdue(rental.rentalEndDate, rental.rentalStartDate, rental.numDaysApproved)
                },
                [`overdueNoticeText${index}`]: {
                    content: translator(messages.OverdueNotice)
                },
                [`dailyLimitNotice${index}`]: {
                    visible: showExceedDailyLimit(rental.dailyRate, rental.dailyLimit?.amount)
                },
                [`dailyLimitText${index}`]: {
                    content: translator(messages.DailyLimitNotice)
                },
                [`detailsRowRentalLocationAndContact${index}`]: {
                    content: getRentalLocationAndContact(rental)
                },
                [`detailsRowReservationNumber${index}`]: {
                    visible: !!rental.reservationNumber,
                    content: getReservationNumber(rental)
                },
                [`policyLimitTooltip${index}`]: {
                    tooltipMessage: translator(messages.PolicyLimitTooltip)
                },
                [`policyLimitValue${index}`]: {
                    value: rental.policyLimit,
                    showFractions: true
                },
                [`dailyLimitTooltip${index}`]: {
                    tooltipMessage: translator(messages.DailyLimitTooltip)
                },
                [`dailyLimitValue${index}`]: {
                    value: rental.dailyLimit,
                    showFractions: true
                },
                [`numDaysApprovedTooltip${index}`]: {
                    tooltipMessage: translator(messages.ApprovedForTooltip)
                },
                [`numDaysApprovedValue${index}`]: {
                    content: `${rental.numDaysApproved} ${rental.numDaysApproved === 1 ? translator(messages.Day) : translator(messages.Days)}`
                },
                [`rentalStartDateColumn${index}`]: {
                    visible: !!rental.rentalStartDate
                },
                [`rentalStartDateValue${index}`]: {
                    content: intl.formatDate(new Date(rental.rentalStartDate), { year: 'numeric', month: 'numeric', day: 'numeric' })
                },
                [`expectedReturnDateColumn${index}`]: {
                    visible: shouldCalculateRentalEndDate(rental)
                },
                [`expectedReturnDateValue${index}`]: {
                    content: intl.formatDate(getCalculatedRentalEndDate(rental.rentalStartDate, rental.numDaysApproved), { year: 'numeric', month: 'numeric', day: 'numeric' })
                },
                [`rentalEndDateColumn${index}`]: {
                    visible: !shouldCalculateRentalEndDate(rental)
                },
                [`rentalEndDateValue${index}`]: {
                    content: intl.formatDate(new Date(rental.rentalEndDate), { year: 'numeric', month: 'numeric', day: 'numeric' })
                },
                [`vehicleMakeAndModelColumn${index}`]: {
                    visible: !!rental.year && !!rental.make && !!rental.model
                },
                [`vehicleMakeAndModelValue${index}`]: {
                    content: `${rental.year} ${rental.make} ${rental.model}`
                },
                [`vehicleDailyRateColumn${index}`]: {
                    visible: !!rental.dailyRate
                },
                [`vehicleDailyRateValue${index}`]: {
                    value: rental.dailyRate,
                    showFractions: true
                },
                [`rentalVehicleInfoBoxAria${index}`]: {
                    content: srMessage
                },
            };
        });
        
        return Object.assign({}, ...overrides);
    }, [getRentalLocationAndContact, getReservationNumber, getScreenReaderMessage, intl, rentalDetails, showDailyRateExceedWarning, translator]);

    const overrides = {
        rentalVehicleInfoBoxIterable: {
            data: rentalDetails
        },
        ...iterableOverrides
    };

    const resolvers = {
        resolveComponentMap: {
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={claimInfo}
        />
    );
};

WMICRentalVehicleInfoBox.propTypes = {
    claimData: PropTypes.shape({})
};

WMICRentalVehicleInfoBox.defaultProps = {
    claimData: {}
};

export default WMICRentalVehicleInfoBox;
