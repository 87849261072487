/* eslint-disable consistent-return */
import React, {
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { Loader } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';

import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import { WMICIcon } from 'wmic-pe-components-platform-react';
import { WMICPremiumCosts } from 'wmic-pe-capability-gateway-common-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { WMICLogger, WMICVariousUtil, WMICDateTimeService, QUOTE_STATUS, UW_BLOCKING_POINT, CONSTANTS, TRANSACTION_STATUS, parseErrorMessage, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';

import cx from 'classnames';

import metadata from './WMICCancellationDetailsPage.metadata.json5';
import messages from './WMICCancellationDetailsPage.messages';
import styles from './WMICCancellationDetailsPage.module.scss';

const AGENCYBILL_CODE = 'AgencyBill';

const WMICCancellationDetailsPage = (props) => {
    const {
        match: {
            params: { jobNumber }
        },
        viewModelService,
        history
    } = props;

    const { showConfirm, setWizardLoading } = useWizardModals();
    const translator = useContext(TranslatorContext);
    const { authHeader, authUserData } = useAuthentication();
    const { CancellationService } = useDependencies('CancellationService');
    const { BillingService } = useDependencies('BillingService');

    const [cancellation, setCancellation] = useState({});
    const [cancellationSummaryVM, setCancellationSummaryVM] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState('');

    const [quotedWithUW, setQuotedWithUW] = useState(false);
    const [approvedUWIssues, setApprovedUWIssues] = useState();
    const [unapprovedUWIssues, setUnapprovedUWIssues] = useState();

    const [formData, updateFormData] = useState({});
    const [isTransactionPendingWithUW, setIsTransactionPendingWithUW] = useState(false);

    const canEditAccount = WMICUserAccessUtil.canEditAccount(authUserData.roles);

    useEffect(() => {
        getJobByJobNumber();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    

    // Call the CancellationService method to find cancellation job and fetch summary
    const getJobByJobNumber = async () => {
        try {
            setIsLoading(true);

            const policyCancellationResponse = await CancellationService.findJobByJobNumber(jobNumber, authHeader);

            setStatus(policyCancellationResponse?.statusCode);
            setIsTransactionPendingWithUW(policyCancellationResponse?.isPendingWithUW ?? false);

            if (policyCancellationResponse) {
                const cancellationSummaryResult = await CancellationService.getCancellationSummary(jobNumber, authHeader);
                const summaryVM = viewModelService.create(cancellationSummaryResult, 'pc', 'wmic.edge.ca.capabilities.gateway.job.cancellation.dto.CancellationSummaryDTO');
                const paymentMethod = _.get(policyCancellationResponse, 'policy.policyPeriodBillingSummary_WMIC.billingMethod');
                if (paymentMethod !== AGENCYBILL_CODE) {
                    const billingSummaryResponse = await BillingService.getPolicyBillingSummaryInfo(
                        policyCancellationResponse.policy.policyNumber,
                        policyCancellationResponse.policy.latestPeriod.termNumber_WMIC,
                        authHeader);

                    if (!_.isEmpty(billingSummaryResponse)) {
                        _.set(policyCancellationResponse, 'policy.latestPeriod.totalPremium', billingSummaryResponse.totalPremium);
                        _.set(policyCancellationResponse, 'policy.latestPeriod.taxes', billingSummaryResponse.taxes);
                        _.set(policyCancellationResponse, 'policy.latestPeriod.fees', billingSummaryResponse.fees);
                        _.set(policyCancellationResponse, 'policy.latestPeriod.totalCost', billingSummaryResponse.totalCost);
                    }
                }


                setCancellationSummaryVM(summaryVM);
                setCancellation(policyCancellationResponse);
            }

        } catch (error) {
            WMICLogger.error('Error retrieving job', error);
            WMICErrorHandler.processAsModal(parseErrorMessage(error));
            history.push("/");
        } finally {
            setIsLoading(false);
        }
    }

    // Update UW issues and determine approved and unapproved issues
    const updateUnderwritingIssues = useCallback(() => {
        const UWIssues = _.get(cancellation, 'underwritingIssues', []);
        const approvedIssues = UWIssues.filter((issue) => issue.hasApprovalOrRejection);
        const unapprovedIssues = UWIssues.filter((issue) => !issue.hasApprovalOrRejection);

        // eslint-disable-next-line no-unused-expressions
        unapprovedIssues.length > 0 && _.get(cancellation, 'statusCode') === QUOTE_STATUS.QUOTED && setQuotedWithUW(true);
        setApprovedUWIssues(approvedIssues);
        setUnapprovedUWIssues(unapprovedIssues);
    }, [cancellation]);

    useEffect(() => {
        updateUnderwritingIssues();
    }, [cancellation, updateUnderwritingIssues]);    

    // Generates Local Date String in required format
    const getLocaleDateString = (date) => {
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const newDate = new Date(date);

        return newDate.toLocaleDateString(localStorage.getItem('selectedLocale'), dateOptions);
    }

    // Gets string to display policy term
    const getPolicyTermString = () => {
        const policyEffectiveDate = getLocaleDateString(WMICDateTimeService.localDate2Date(_.get(cancellation, 'policy.latestPeriod.effectiveDate')));
        const policyExpirationDate = getLocaleDateString(WMICDateTimeService.localDate2Date(_.get(cancellation, 'policy.latestPeriod.expirationDate')));

        return `${policyEffectiveDate} - ${policyExpirationDate}`;
    }

    // Gets string to display policy cancel effective date
    const getPolicyCancelEffectiveDateString = () => {
        const effectiveDate = _.get(cancellation, 'effectiveDate');
        const policyCancelEffectiveDate = WMICDateTimeService.localDate2Date(effectiveDate);

        return getLocaleDateString(policyCancelEffectiveDate);
    }

    // Get account name based on availability of different properties
    const getAccountName = () => {
        const getFirstName = _.get(cancellation, 'policy.account.accountHolder.firstName') || '';
        const getLastName = _.get(cancellation, 'policy.account.accountHolder.lastName') || '';
        const getDisplayName = _.get(cancellation, 'policy.account.accountHolder.displayName');
        const getAccountDisplayName = !getDisplayName
            ? `${getFirstName} ${getLastName}`
            : getDisplayName;
        const accountName = _.trim(getAccountDisplayName)
            || _.get(cancellation, 'policy.account.accountHolder.contactName')
            || _.get(cancellation, 'policy.account.accountName');

        return accountName;
    }

    // Get URL route path to redirect to account summary
    const getAccountSummaryRoutePath = () => {
        const accountNumber = _.get(cancellation, 'policy.account.accountNumber');

        return `/accounts/${accountNumber}/summary`
    }

    // Get icon to be displayed in page title depending on product code
    const getTitleIcon = () => {
        const productCode = _.get(cancellation, 'productCode');

        return WMICVariousUtil.getLobIcon(productCode);
    }

    // Get status icon for UW issues
    const getStatusIcon = () => {
        if (quotedWithUW) {
            return 'mi-warning';
        }

        if (_.get(cancellation, 'status') === QUOTE_STATUS.QUOTED) {
            return 'mi-check';
        }

        return 'mi-info';
    };

    // Get account holder address for display
    const getAddress = () => _.get(cancellation, 'policy.account.accountHolder.primaryAddress.displayName') || ''

    // Get premium data for PremiumCostsCard component
    const getCurrentPremiumData = () => ({
            totalBeforeTaxes: _.get(cancellation, 'policy.latestPeriod.totalPremium'),
            taxes: _.get(cancellation, 'policy.latestPeriod.taxes'),
            fees: _.get(cancellation, 'policy.latestPeriod.fees'),
            total: _.get(cancellation, 'policy.latestPeriod.totalCost')
        })

    // Get approved UW issue items
    const getApprovedItems = () => approvedUWIssues?.map((issue) => {
            const isDeclined = issue.hasApprovalOrRejection && issue.approvalBlockingPoint === UW_BLOCKING_POINT.REJECTED;

            return(
                <li>
                    <div className={cx(styles.uwBadge, isDeclined ? styles.uwUnapproved : styles.uwApproved)}>
                        <WMICIcon icon={isDeclined ? "mi-close" : "mi-check"} className={styles.uwApprovedItemsIcon}/>
                        <span>{isDeclined ? translator(messages.issueDeclined) : translator(messages.issueApproved)}</span>
                    </div>
                    <span>{issue.longDescription}</span>
                </li>
            );
        })

    // Get status message heading for UW issues
    const statusMessageHeading = useMemo(() => {
        if (['Quoted: Underwriter Approval Pending'].includes(cancellationSummaryVM?.displayStatus_WMIC) || isTransactionPendingWithUW) {
            return translator(messages.underwriterApprovalPending);
        }

        return quotedWithUW ? translator(messages[`policyCancel${status}UWHeading`]) : translator(messages[`policyCancel${status}Heading`]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quotedWithUW, status, isTransactionPendingWithUW]);

    // Updates the cancellation
    const updateCancellation = (newCancellation) => {
        setCancellation((currentState) => ({ ...currentState, ...newCancellation }));
    }

    // Update when withdraw cancellation is successful
    const updateOnWithdrawCancellation = (newCancellation) => {
        setStatus(QUOTE_STATUS.WITHDRAWN);

        updateCancellation(newCancellation);
    }

    // Update when bind cancellation is successful
    const updateOnBindCancellation = () => {
        const policyNumber = _.get(cancellation, 'policy.policyNumber');
        const termNumber = _.get(cancellation, 'policy.latestPeriod.termNumber_WMIC');

        history.push(`/policies/${policyNumber}/${termNumber}/summary`)
    }

    // Update when quote cancellation is successful
    const updateOnQuoteCancellation = (newCancellation) => {
        setStatus(QUOTE_STATUS.QUOTED);

        updateCancellation(newCancellation);
    }

    // Opens the Modal to confirm withdraw for cancellation
    const onWithdrawCancellation = () => {
        showConfirm({
            title: translator(messages.withdrawCancellation),
            message: translator(messages.withdrawCancellationMessage),
            confirmButtonText: translator(commonMessages.yes),
            cancelButtonText: translator(commonMessages.noModel),
        }).then(async (result) => {
            if (result === CONSTANTS.MODAL_RESULT.CANCEL || result === CONSTANTS.MODAL_RESULT.CLOSE) {
                return _.noop();
            }

            withdrawCancellation();
        }, _.noop);
    }

    // Opens the Modal to confirm submit for cancellation
    const onSubmitCancellation = () => {
        const canBind = _.get(cancellation, 'canBind');
        const canQuote = _.get(cancellation, 'canQuote_WMIC');

        let submitModalMessage;

        if (canBind) {
            submitModalMessage = messages.bindCancellationMessage;
        } else if (canQuote) {
            submitModalMessage = messages.quoteCancellationMessage;
        }

        showConfirm({
            title: translator(messages.submitCancellation),
            message: translator(submitModalMessage),
            confirmButtonText: translator(commonMessages.yes),
            cancelButtonText: translator(commonMessages.noModel),
        }).then(async (result) => {
            if (result === CONSTANTS.MODAL_RESULT.CANCEL || result === CONSTANTS.MODAL_RESULT.CLOSE) {
                return _.noop();
            }

            if (canBind) {
                bindCancellation();
            } else if (canQuote) {
                quoteCancellation();
            }
        }, _.noop);
    }

    // Calls the CancellationService method to withdraw the cancellation
    const withdrawCancellation = () => {
        setIsLoading(true);
        CancellationService.withdrawCancellation(jobNumber, authHeader).then((newCancellation) => {
            updateOnWithdrawCancellation(newCancellation);
        }).catch((error) => {
            WMICErrorHandler.processAsModal(error);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    // Calls the CancellationService method to bind the cancellation
    const bindCancellation = () => {
        setIsLoading(true);
        CancellationService.bindCancellation(jobNumber, authHeader).then(() => {
            updateOnBindCancellation();
        }).catch((error) => {
            WMICErrorHandler.processAsModal(error);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    // Calls the CancellationService method to quote the cancellation
    const quoteCancellation = () => {
        setIsLoading(true);
        CancellationService.quoteCancellation(jobNumber, authHeader).then((newCancellation) => {
            updateOnQuoteCancellation(newCancellation);
        }).catch((error) => {
            WMICErrorHandler.processAsModal(error);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    // Calls the CancellationService method to refer cancellation to underwriter
    const referToUnderwriter = () => {
        const { notes } = formData;
        setWizardLoading(true);
        CancellationService.referToUnderwriter(jobNumber, notes, authHeader).then((newCancellation) => {
            setIsTransactionPendingWithUW(true);
            updateCancellation(newCancellation);
            writeValue('', 'notes');
        }).catch((error) => {
            WMICErrorHandler.processAsModal(error);
        }).finally(() => setWizardLoading(false));
    }

    // Update Form data when value changes
    const writeValue = (value, path) => {
        const nextFormData = _.cloneDeep(formData);

        _.set(nextFormData, path, value);
        updateFormData(nextFormData);
    }

    // Display loader if page is loading awaiting any service calls
    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    // Override Props
    const overrideProps = {
        premiumAmount: {
            value: _.get(cancellation, 'cancellationPremium_WMIC'),
            label: _.get(cancellation, 'cancellationRefundMethod') === CONSTANTS.CANCELLATION_REFUND_METHODS.SHORT_RATE ? translator(messages.shortRatedPremium) : translator(messages.proRatedPremium),
        },
        receivedAmount: {
            value: _.get(cancellation, 'amountReceived_WMIC')
        },
        differenceAmount: {
            value: _.get(cancellation, 'difference_WMIC')
        },
        policyCancellationJobDetailsIcon: {
            icon: getTitleIcon()
        },
        policyCancellationJobDetailsTitle: {
            title: translator(messages[_.camelCase(_.get(cancellation, 'productCode'))], {
                jobNumber: _.get(cancellation, 'jobNumber')
            })
        },
        accountHolderName: {
            content: getAccountName()
        },
        address: {
            content: getAddress()
        },
        editAccountLink: {
            to: getAccountSummaryRoutePath(),
            visible: canEditAccount
        },
        editAccount: {
            content: translator(messages.editAccount)
        },
        statusComponentContainer: {
            visible: _.get(cancellation, 'statusCode') !== TRANSACTION_STATUS.CANCELING
        },
        statusMessageHeading: {
            content: statusMessageHeading
        },
        statusIcon: {
            icon: getStatusIcon(),
        },
        submitCancellation: {
            visible: (_.get(cancellation, 'canBind') && !quotedWithUW) || _.get(cancellation, 'canQuote_WMIC')
        },
        premiumCostsCard: {
            visible: _.get(cancellation, 'statusCode') === QUOTE_STATUS.QUOTED,
            premium: getCurrentPremiumData(),
            jobVM: cancellation,
            currentCostsTitle: true
        },
        refundCostsCard: {
            visible: _.get(cancellation, 'statusCode') === QUOTE_STATUS.QUOTED,
        },
        actionButtonsContainer: {
            visible: _.get(cancellation, 'statusCode') !== QUOTE_STATUS.WITHDRAWN
        },
        withdrawCancellation: {
            visible: _.get(cancellation, 'canWithdraw') && statusMessageHeading !== translator(messages.underwriterApprovalPending)
        },
        UWIssuesRaisedContainer: {
            visible: unapprovedUWIssues?.length > 0
        },
        noOutstandingUWIssuesContainer: {
            visible: unapprovedUWIssues?.length === 0
        },
        UWIssuesRaisedSubTitle: {
            title: translator(messages.underwriterMustReview, {count: unapprovedUWIssues?.length})
        },
        raisedUWIssuesItems: {
            data: unapprovedUWIssues
        },
        approvedUWIssuesItems: {
            content: getApprovedItems()
        },
        underwritingIssuesCard: {
            visible: _.get(cancellation, 'statusCode') === QUOTE_STATUS.QUOTED
        },
        UWFormInput: {
            placeholder: translator(messages.notesPlaceholder),
            value: _.get(formData, 'notes', '')
        },
        referToUWForm: {
            visible: !_.isEmpty(unapprovedUWIssues) && !isTransactionPendingWithUW
        },
        policyNumber: {
            value: _.get(cancellation, 'policy.policyNumber')
        },
        policyTerm: {
            value: getPolicyTermString()
        },
        policyCancellationStatus: {
            value: _.get(cancellation, 'status')
        },
        policyCancelEffectiveDate: {
            value: getPolicyCancelEffectiveDateString()
        }
    }

    // Resolvers
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICPremiumCosts
        },
        resolveCallbackMap: {
            onWithdrawCancellation,
            onSubmitCancellation,
            referToUnderwriter
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    )
}

export default withRouter(withViewModelService(WMICCancellationDetailsPage));
