/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/** Calculate the default payment amount for a policy
 * @param policyBilling policy billing info
 * @return default payment amount
 */
import _ from 'lodash';
import { Decimal } from 'decimal.js-light';
import { WMICBillingUtil } from 'wmic-portals-utils-js';
import CONSTANTS from './StringConstants';

const MAX_CREDIT_CARD_PAYMENT_AMOUNT = 1500;
const zero = new Decimal(0.00);

function hasAmount(amount) {
    return amount && amount.amount > 0;
}

function isMARPayment(data) {
    return data ? data.paymentPlan === CONSTANTS.MONTHLY_PAY : false;
}

function shouldReturnAmountDue(data) {
    return hasAmount(data.amountOverDue)
    || (hasAmount(data.outstandingAmount) && !hasAmount(data.amountOverDue) && hasAmount(data.amountDue));
}

function shouldReturnNextPlannedAmount(data) {
    return hasAmount(data.outstandingAmount)
    && !hasAmount(data.amountOverDue)
    && !hasAmount(data.amountDue)
    && hasAmount(data.nextPlannedAmount);
}

function isPolicyInactive(data) {
    const status = data.policyStatus.toLowerCase();
    return status === CONSTANTS.CANCELED || status === CONSTANTS.EXPIRED;
}

function shouldReturnOutstandingAmount(data) {
    return isPolicyInactive(data)
    || (hasAmount(data.outstandingAmount)
    && !hasAmount(data.amountOverDue)
    && !hasAmount(data.amountDue)
    && !hasAmount(data.nextPlannedAmount));
}

function isListBill(billingData) {
    let response = false;
    const activeHasListBill = billingData.billingMethod === CONSTANTS.LIST_BILL;
    let renewalHasListBill = false;
    if (billingData.renewalPaymentDetails) {
        const renewal = billingData.renewalPaymentDetails;
        renewalHasListBill = renewal.billingMethod === CONSTANTS.LIST_BILL;
    }
    if (activeHasListBill || renewalHasListBill) {
        response = true;
    }
    return response;
}

function calculateRemainingBalance(billingData) {
    const nextPayment = hasAmount(billingData.nextPlannedAmount) ? new Decimal(billingData.nextPlannedAmount.amount) : zero;
    const unbilled = hasAmount(billingData.unbilledAmount) ? new Decimal(billingData.unbilledAmount.amount) : zero;
    const unbilledFees = hasAmount(billingData.unbilledFeesAmount) ? new Decimal(billingData.unbilledFeesAmount.amount) : zero;

    return (unbilled.plus(unbilledFees)).minus(nextPayment);
}

function canPayAgainstActivePolicy(billingData) {
    if (hasAmount(billingData.outstandingAmount)) {
        return hasAmount(billingData.amountOverDue) || !isMARPayment(billingData);
    }
    return false;
}

function canPayAgainstRenewalPolicy(billingData, status) {
    const renewalBillingData = billingData.renewalPaymentDetails ? billingData.renewalPaymentDetails : {};
    return !hasAmount(billingData.outstandingAmount)
    && status === CONSTANTS.RENEWING
    && hasAmount(renewalBillingData.outstandingAmount)
    && !isMARPayment(renewalBillingData);
}

function round(amount) {
    // eslint-disable-next-line no-restricted-globals
    if (amount === null || amount === '' || isNaN(amount) || amount === undefined) {
        return null;
    }
    const num = new Decimal(amount);
    return num.toDecimalPlaces(2);
}

function amountRemainingInvalid(amountDue, payment) {
    const amountRemainingAfterPayment = parseFloat(amountDue) - payment;
    return amountRemainingAfterPayment > 0 && amountRemainingAfterPayment < 1;
}

function isPeriodRenewing(period) {
    return period && period.renewal_Details_WMIC && period.renewal_Details_WMIC.displayStatus_WMIC.toLowerCase() === CONSTANTS.SCHEDULED;
}

function getAmount(amount) {
    return amount && amount.amount ? new Decimal(amount.amount) : zero;
}

function getTotalAmount(remainingBalanceDisplayData, amount) {
    let returnAmount = getAmount(amount);
    if (remainingBalanceDisplayData.totalUnbilled && remainingBalanceDisplayData.totalUnbilled > 0) {
        const newTotal = returnAmount - remainingBalanceDisplayData.totalUnbilled;
        returnAmount = newTotal === 0.0 ? newTotal : returnAmount;
    }
    return returnAmount;
}

function checkPaymentDueInvalid(amountDue, nextPlannedAmount, payment) {
    amountDue = amountDue ? new Decimal(amountDue) : zero;
    nextPlannedAmount = nextPlannedAmount ? new Decimal(nextPlannedAmount) : zero;
    payment = payment ? new Decimal(payment) : zero;
    let amountDueInvalid = amountRemainingInvalid(amountDue, payment);
    if (payment > amountDue) {
        const amountIncludingNextPlannedAmount = amountDue.plus(nextPlannedAmount);
        amountDueInvalid = amountDueInvalid === true ? true : amountRemainingInvalid(amountIncludingNextPlannedAmount, payment);
    }
    return amountDueInvalid;
}

export default {
    canShowPayNowButton: (billingData, currentPolicyStatus, isViewingCurrentTerm) => {
        const status = currentPolicyStatus.toLowerCase();
        billingData = WMICBillingUtil.populateDelinquencyInfo(billingData);
        if (isListBill(billingData)) {
            return billingData.delinquencyInfo?.hasOpenDelinquency;
        }
        if (status === CONSTANTS.INACTIVE) {
            return hasAmount(billingData.outstandingAmount);
        }
        if (isViewingCurrentTerm) {
            return canPayAgainstActivePolicy(billingData);
        }
        return canPayAgainstActivePolicy(billingData) || canPayAgainstRenewalPolicy(billingData, status);
    },
    getSuggestedPaymentAmount: (billingData) => {
        let amount = null;
        if (isMARPayment(billingData)) {
            if (hasAmount(billingData.amountOverDue)) {
                amount = billingData.amountOverDue;
            }
        } else {
            if (shouldReturnAmountDue(billingData)) {
                amount = billingData.amountDue;
            }
            if (shouldReturnNextPlannedAmount(billingData)) {
                amount = billingData.nextPlannedAmount;
            }
            if (shouldReturnOutstandingAmount(billingData)) {
                amount = billingData.outstandingAmount;
            }
        }
        return amount;
    },
    getMaximumPaymentAmount: (billingData) => {
        const activeTerm = billingData;
        const renewalTerm = billingData.renewalPaymentDetails;

        let maxAmount = activeTerm.outstandingAmount.amount;
        const status = billingData.policyStatus.toLowerCase();
        const isInactive = status === CONSTANTS.CANCELED || status === CONSTANTS.EXPIRED;
        if (isInactive) {
            maxAmount = activeTerm.outstandingAmount.amount;
        } else if (isMARPayment(activeTerm)) {
            if (renewalTerm && !isMARPayment(renewalTerm)) {
                if (hasAmount(activeTerm.unbilledAmount)) {
                    maxAmount = activeTerm.amountOverDue.amount;
                } else {
                    maxAmount = new Decimal(renewalTerm.outstandingAmount.amount).plus(activeTerm.outstandingAmount.amount);
                }
            } else {
                maxAmount = activeTerm.amountOverDue.amount;
            }
        } else if (renewalTerm && !isMARPayment(renewalTerm)) {
            maxAmount = new Decimal(renewalTerm.outstandingAmount.amount).plus(activeTerm.outstandingAmount.amount);
        } else if (hasAmount(activeTerm.nextPlannedAmount) && new Decimal(activeTerm.nextPlannedAmount.amount).greaterThan(activeTerm.outstandingAmount.amount)) {
            maxAmount = new Decimal(activeTerm.nextPlannedAmount.amount);
        }
        return round(maxAmount);
    },
    getPaymentStatus: (billingData, isInactive) => {
        let result = '';
        if (hasAmount(billingData.amountOverDue) && !billingData.isRenewal) {
            result = CONSTANTS.OVERDUE;
        } else if (hasAmount(billingData.amountDue)) {
            result = CONSTANTS.PAYMENT_DUE;
        } else if (hasAmount(billingData.nextPlannedAmount) && !billingData.isRenewal && !isInactive) {
            result = CONSTANTS.NEXT_PAYMENT;
        }
        return result;
    },
    getPolicyStatusName: (status, renewal) => {
        let statusName = '';
        status = status.toLowerCase();

        if (status === CONSTANTS.IN_FORCE && renewal !== undefined) {
            statusName = CONSTANTS.RENEWING;
        } else if (status === CONSTANTS.IN_FORCE && renewal === undefined) {
            statusName = CONSTANTS.ACTIVE;
        } else if ((status === CONSTANTS.SCHEDULED || status === CONSTANTS.FUTURE) && renewal === undefined) {
            statusName = CONSTANTS.NEW;
        } else if (status === CONSTANTS.CANCELED || status === CONSTANTS.EXPIRED) {
            statusName = CONSTANTS.INACTIVE;
        }
        return statusName;
    },
    getOverdueAmount: (billingData) => {
        let amount = 0;
        const status = _.get(billingData, 'policyStatus', '').toLowerCase();
        const isInactive = status === CONSTANTS.CANCELED || status === CONSTANTS.EXPIRED;
        if (isInactive) {
            amount = _.get(billingData, 'outstandingAmount.amount');
        } else {
            amount = hasAmount(billingData.amountOverDue) ? billingData.amountOverDue.amount : 0;
        }
        const priorTermBalance = hasAmount(billingData.priorTermBalance) ? billingData.priorTermBalance.amount : 0;
        return round(amount).plus(priorTermBalance);
    },
    getPaymentDue: (billingData) => {
        let amount = 0;
        const status = billingData.policyStatus.toLowerCase();
        const isActive = status === CONSTANTS.INFORCE || status === CONSTANTS.IN_FORCE || (status === CONSTANTS.SCHEDULED|| status === CONSTANTS.FUTURE);
        if (isActive) {
            if (!isMARPayment(billingData)) {
                const amountOverDue = hasAmount(billingData.amountOverDue) ? billingData.amountOverDue.amount : 0;
                amount = new Decimal(billingData.amountDue.amount).minus(amountOverDue);
            }
        }
        return round(amount);
    },
    getRenewalPaymentDue: (billingData) => {
        let amount = 0;
        const renewalTerm = billingData.renewalPaymentDetails;
        const status = billingData.policyStatus.toLowerCase();
        const isActive = status === CONSTANTS.INFORCE || status === CONSTANTS.IN_FORCE || (status === CONSTANTS.SCHEDULED || status === CONSTANTS.FUTURE);
        if (isActive && renewalTerm && !(isMARPayment(renewalTerm))) {
            if (!(isMARPayment(billingData) && hasAmount(billingData.unbilledAmount))) {
                amount = _.get(renewalTerm, 'amountDue.amount');
            }
        }
        return round(amount);
    },
    getRemainingBalance: (billingData) => {
        let amount = 0;
        const renewalTerm = billingData.renewalPaymentDetails;
        const status = billingData.policyStatus.toLowerCase();
        const isActive = status === CONSTANTS.INFORCE || status === CONSTANTS.IN_FORCE || (status === CONSTANTS.SCHEDULED || status === CONSTANTS.FUTURE);
        if (isActive) {
            if (renewalTerm && !isMARPayment(renewalTerm)) {
                amount = calculateRemainingBalance(renewalTerm);
            } else if (!isMARPayment(billingData)) {
                amount = calculateRemainingBalance(billingData);
            }
        }
        return round(amount);
    },
    getTotalRemainingBalance: (billingData) => {
        return calculateRemainingBalance(billingData);
    },
    getNextPayment: (billingData) => {
        let amount = 0;
        const renewalTerm = billingData.renewalPaymentDetails;
        const status = billingData.policyStatus.toLowerCase();
        const isActive = status === CONSTANTS.INFORCE || status === CONSTANTS.IN_FORCE || (status === CONSTANTS.SCHEDULED || status === CONSTANTS.FUTURE);
        if (isActive) {
            if (renewalTerm && !isMARPayment(renewalTerm)) {
                if (!(isMARPayment(billingData) && hasAmount(billingData.unbilledAmount))) {
                    amount = hasAmount(renewalTerm.nextPlannedAmount) ? renewalTerm.nextPlannedAmount.amount : 0;
                }
            } else if (hasAmount(billingData.outstandingAmount) && hasAmount(billingData.nextPlannedAmount) && !isMARPayment(billingData)) {
                amount = _.get(billingData, 'nextPlannedAmount.amount');
            }
        }
        return round(amount);
    },
    getNextPaymentDate: (billingData) => {
        let date = '';
        const renewalTerm = billingData.renewalPaymentDetails;
        const status = billingData.policyStatus.toLowerCase();
        const isActive = status === CONSTANTS.INFORCE || status === CONSTANTS.IN_FORCE || (status === CONSTANTS.SCHEDULED || status === CONSTANTS.FUTURE);
        if (isActive) {
            if (renewalTerm && !isMARPayment(renewalTerm)) {
                date = renewalTerm.nextPlannedAmountPaymentDate;
            } else if (hasAmount(billingData.outstandingAmount) && hasAmount(billingData.nextPlannedAmount) && !isMARPayment(billingData)) {
                date = billingData.nextPlannedAmountPaymentDate;
            }
        }
        return date;
    },
    shouldDisplayInactivePolicyMessage: (billingData, policyStatus) => {
        const status = policyStatus ? policyStatus.toLowerCase() : _.get(billingData, 'policyStatus').toLowerCase();
        const isInactive = status === CONSTANTS.CANCELED || status === CONSTANTS.EXPIRED;
        return isInactive && hasAmount(billingData.outstandingAmount);
    },
    isAmountOverCreditCardLimit: (amount) => {
        return amount > MAX_CREDIT_CARD_PAYMENT_AMOUNT;
    },
    round: (amount) => {
        return round(amount);
    },
    isMARPayment: (data) => {
        return isMARPayment(data);
    },
    isListBill: (billingData) => {
        return isListBill(billingData);
    },
    isPolicyInactive: (data) => {
        return isPolicyInactive(data);
    },
    isPaymentAmountRemainingLessThanOneDollar: (isRenewal, paymentAmount, billingData) => {
        const amountAsFloat = parseFloat(paymentAmount);
        const policyStatusForInvoiceStream = isRenewal ? CONSTANTS.RENEWING : billingData.policyStatus;

        let amountDue = getAmount(billingData.amountDue);
        let amountOverDue = getAmount(billingData.amountOverDue);
        let nextPlannedAmount = getAmount(billingData.nextPlannedAmount);
        let outstandingAmount = getAmount(billingData.outstandingAmount);

        if (canPayAgainstRenewalPolicy(billingData, policyStatusForInvoiceStream)) {
            amountDue = getAmount(billingData.renewalPaymentDetails.amountDue);
            amountOverDue = getAmount(billingData.renewalPaymentDetails.amountOverDue);
            nextPlannedAmount = getAmount(billingData.renewalPaymentDetails.nextPlannedAmount);
            outstandingAmount = getAmount(billingData.renewalPaymentDetails.outstandingAmount);
        }

        let amountDueInvalid = false;
        let amountOverDueInvalid = false;
        let nextPlannedAmountInvalid = false;

        if (amountDue > 0) {
            amountDueInvalid = checkPaymentDueInvalid(amountDue, nextPlannedAmount, amountAsFloat);
        }
        if (amountOverDue > 0) {
            amountOverDueInvalid = checkPaymentDueInvalid(amountOverDue, nextPlannedAmount, amountAsFloat);
        }
        if (!(amountDue > 0) && !(amountOverDue > 0)) {
            nextPlannedAmountInvalid = amountRemainingInvalid(nextPlannedAmount, amountAsFloat);
        }

        let amountInvalid = amountDueInvalid || amountOverDueInvalid || nextPlannedAmountInvalid;

        if (outstandingAmount > 0) {
            const outstandingAmtRemainingInvalid = amountRemainingInvalid(outstandingAmount, amountAsFloat);
            amountInvalid = amountInvalid === true ? true : outstandingAmtRemainingInvalid;
        }

        return amountInvalid;
    },
    getRemainingBalanceDisplayData: (billingData, currentStatus) => {
        const model = {
            totalUnbilled: '',
            nextPayment: ''
        };
        const unbilled = hasAmount(billingData.unbilledAmount) ? new Decimal(billingData.unbilledAmount.amount) : zero;
        const unbilledFees = hasAmount(billingData.unbilledFeesAmount) ? new Decimal(billingData.unbilledFeesAmount.amount) : zero;

        const totalUnbilled = unbilled.plus(unbilledFees);
        if (hasAmount(billingData.nextPlannedAmount)) {
            const nextPayment = new Decimal(billingData.nextPlannedAmount.amount);
            const status = currentStatus.toLowerCase();
            if (status === CONSTANTS.RENEWING && totalUnbilled.lessThan(nextPayment)) {
                model.nextPayment = zero;
                model.totalUnbilled = totalUnbilled;
                return model;
            }
            model.nextPayment = nextPayment;
            return model;
        }
        model.nextPayment = zero;
        return model;
    },
    isPeriodRenewing: (period) => {
        return isPeriodRenewing(period);
    },
    getAmount: (amount) => {
        return getAmount(amount);
    },
    getTotalAmount: (remainingBalanceDisplayData, amount) => {
        return getTotalAmount(remainingBalanceDisplayData, amount);
    },
    getInvoiceStreamId: (period, billingData, currentStatus) => {
        const policyStatusForInvoiceStream = isPeriodRenewing(period) ? CONSTANTS.RENEWING : currentStatus;
        return canPayAgainstRenewalPolicy(billingData, policyStatusForInvoiceStream) ? billingData.renewalPaymentDetails.invoiceStreamId : billingData.invoiceStreamId;
    },
    canPayAgainstRenewalPolicy: (billingData, currentStatus) => {
        const status = currentStatus.toLowerCase();
        return canPayAgainstRenewalPolicy(billingData, status);
    },
    isDelinquent: (billingData) => {
        if (billingData.delinquencyInfo) {
            return billingData.delinquencyInfo.hasOpenDelinquency;
        }
        return billingData.isPolicyDelinquent;
    }
};
