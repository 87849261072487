/* eslint-disable indent */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { useTranslator } from '@jutro/locale';
import { DropdownSelectField } from '@jutro/components';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getVehicleName, getTomorrowDate, getTodayDate, getElementByXpath } from '../WMICRemoveVehicleWizardUtils';
import { REASONS_FOR_DELETING, CHANGES_TAKE_EFFECT_AFTER_MIDNIGHT, STATE_KEYS } from '../WMICRemoveVehicleWizardConsts';

export const getAndUpdateInitialStepReasonSelectionError = (modelState, modelStateDispatch, hasClickedContinue) => {
    modelState[STATE_KEYS.REASON_SELECTION_ERROR] = hasClickedContinue && (modelState[STATE_KEYS.REASON] === null || modelState[STATE_KEYS.REASON] === undefined);
    
    return modelState[STATE_KEYS.REASON_SELECTION_ERROR];
};

const WMICRemoveVehicleWizardInitialStep = (props) => {
    const { modelState, modelStateDispatch, ...otherProps } = props; 
    const translator = useTranslator();
    const [firstLoad, setFirstLoad] = useState(true); //used so react redraw due to value change would not trigger reset of focus  

    const shouldShowValidationMsg = () => modelState[STATE_KEYS.REASON_SELECTION_ERROR] === true;

    const focusOnValidationMsg = () => {   
        if (shouldShowValidationMsg()) {
            const reasonDropdown = getElementByXpath("//input[contains(@id,'remove_vehicle_reason_dropdown')]");
            reasonDropdown.setAttribute('tabindex', '0');
            reasonDropdown.focus({preventScroll: false});
        }
    };
    
    const focusRemoveVehicleReasonLabel = () => {
        const headerTag = document.getElementById('WMICRemoveVehicleReasonLabel');
        if (headerTag) {
            headerTag.setAttribute('tabindex', '0');
            headerTag.focus({preventScroll: false});
        }
    };

    useEffect(() => {   
        if (firstLoad) {
            setFirstLoad(false);
            focusRemoveVehicleReasonLabel();
        }
        focusOnValidationMsg();
    });

    return (
        <div {...otherProps}>
            <p className="mb-5">
                <div className={styles['wmic-field-label-fit-to-content']} id='WMICRemoveVehicleReasonLabel'>
                    {WMICRichTextUtil.translateRichText(translator(messages.indicateTheReasonMessage, { vehicleName: getVehicleName(modelState[STATE_KEYS.VEHICLE_TO_REMOVE]) }))}
                </div>
            </p>
        
            <div className={`${styles['wmic-field-fit-to-content']} ${styles['wmic-input-box']}`}>
                <DropdownSelectField
                    id="remove_vehicle_reason_dropdown"
                    availableValues={REASONS_FOR_DELETING}
                    className={styles['wmic-field-fit-to-content']}
                    //label is hidden to provide better user experience for accessibility purpose. secondary label is displayed in UI.
                    label={shouldShowValidationMsg()?translator(messages.reasonForDeletionLabelWithWarningAria):translator(messages.reasonForDeletionLabelAria)}
                    labelClassName={styles['wmicHidden']}
                    secondaryLabel={translator(messages.reasonForDeletionLabel)}
                    secondaryLabelClassName={styles['wmic-field-label-fit-to-content']}
                    value={modelState[STATE_KEYS.REASON]}
                    placeholder={translator(messages.assignDriversPleaseSelect)}
                    onValueChange={(v) => {
                        modelStateDispatch({ 
                            value: {
                                [STATE_KEYS.REASON] : v, 
                                [STATE_KEYS.EFFECTIVE_DATE] : CHANGES_TAKE_EFFECT_AFTER_MIDNIGHT.includes(v) ? getTomorrowDate() : getTodayDate()
                            }});
                        modelState[STATE_KEYS.REASON] = v;
                        
                        getAndUpdateInitialStepReasonSelectionError(modelState, modelStateDispatch, true);
                    }}
                />
            </div>
            <div className={shouldShowValidationMsg() === true ? cx(styles.wmicInputBoxNotes, styles.errorRed) : styles['wmicHidden']} >
                {translator(messages.pleaseSelectTheReasonForRemoval)} 
            </div>
        </div>
    );
}

WMICRemoveVehicleWizardInitialStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    modelStateDispatch: PropTypes.func.isRequired
};

export default WMICRemoveVehicleWizardInitialStep;
