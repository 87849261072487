import React, {
    useEffect, useState, useContext, useCallback, useMemo
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { Wizard } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/prefer-default-export
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { messages as commonPolicyChangeMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import { CONSTANTS, MODAL_CONSTANTS, WMICValidationUtil, WIZARD_STEP_PATHS, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { WMICWizardHandler } from 'wmic-pe-capability-gateway-common-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react'
import messages from './WMICPEPAPolicyChangeWizard.messages';
import wizardConfig from './config/pa-policychange-wizard-config.json5';
import wizardStepToFieldMapping from './config/pa-wizard-step-to-field-mapping.json5';
import styles from './WMICPEPAPolicyChangeWizard.module.scss';

function checkValidity(jobVM, extractVMObject, currentStep) {
    // For steps that have Edit/Add buttons, only validate if user has started editing the page
    switch (currentStep.path) {
        case WIZARD_STEP_PATHS.COMMON.POLICY_DETAILS:
        case WIZARD_STEP_PATHS.PA.DRIVERS:
        case WIZARD_STEP_PATHS.PA.VEHICLES:
        case WIZARD_STEP_PATHS.COMMON.INSURANCE_HISTORY:
            if (!_.get(jobVM, 'isEditingPage.value', false)) {
                return true;
            }
            break;
        default:
            break;
    }

    // Change Summary page is read-only step and doesn't need validations
    if (currentStep.path === WIZARD_STEP_PATHS.COMMON.CHANGE_SUMMARY) {
        return true;
    }

    const checkValidityPathsForCurrentStep = _.get(currentStep, 'checkValidityPaths', []);

    // Cloning checkValidityPaths to avoid adding duplicates to array
    const checkValidityPaths = _.clone(checkValidityPathsForCurrentStep);

    return WMICValidationUtil.checkValidity(jobVM, checkValidityPaths);
}

function getTargetPageIndex(steps) {
    const WMICQuotePageRegex = /(QuotePage)$/;

    for (let stepIndex = 0; stepIndex < steps.length; stepIndex += 1) {
        if (WMICQuotePageRegex.test(steps[stepIndex].id)) {
            return stepIndex;
        }
    }

    return undefined;
}

function WMICPEPAPolicyChangeWizard() {
    const { steps } = wizardConfig;
    const [initialPolicyChange, setInitialPolicyChange] = useState({});
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isRenderWizard, setIsRenderWizard] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isCustomStepsInvalidated, setIsCustomStepsInvalidated] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const history = useHistory();
    const location = useLocation();
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const { EndorsementService } = useDependencies('EndorsementService');
    const translator = useContext(TranslatorContext);
    const { showConfirm, showWarning, setDefaultMessage } = useWizardModals();

    const canEditPolicyChange = useMemo(
        () => WMICUserAccessUtil.canEditPolicyChange(currentUser.roles),
        [currentUser]
    );

    useEffect(
        () => {
            if (!location.state) {
                history.push('/');

                return;
            }

            setDefaultMessage(translator(messages.savingTransactionDetails))

            const {
                state: { policyNumber, selectedTerm, policyChangeEntry }
            } = location;

            if (!policyNumber) {
                return;
            }

            const errorModalBox = (errorMessage) => {
                showConfirm(errorMessage).then((results) => {
                    if (results === CONSTANTS.MODAL_RESULT.CANCEL || results === CONSTANTS.MODAL_RESULT.CLOSE) {
                        return _.noop();
                    }

                    setIsLoading(false);

                    return history.push(`/contactAgent/${policyNumber}/${selectedTerm}`);
                }, _.noop);
            };

            EndorsementService.getAvailablePolicy(policyNumber, authHeader).then((response) => {
                EndorsementService.loadEndorsementWithEffectiveDate([response.policyNumber, policyChangeEntry.effectiveDate], authHeader)
                    .then((responseData) => {
                        const policyChange = viewModelService.create(
                            responseData,
                            'pc',
                            'wmic.edge.ca.capabilities.policychange.dto.PolicyChangeDataDTO'
                        );

                        steps.forEach((step) => _.set(step, 'stepToFieldMapping', wizardStepToFieldMapping[step.id]))

                        setInitialPolicyChange(policyChange);
                        setIsLoading(false);
                    })
                    .catch(() => {
                        errorModalBox({
                            title: commonPolicyChangeMessages.unableToLoadDraftPolicy,
                            message: commonPolicyChangeMessages.somethingWentWrong,
                            status: MODAL_CONSTANTS.STATUS.WARNING,
                            icon: MODAL_CONSTANTS.ICON.WARNING
                        });
                    });
            });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleOnCancel = useCallback(({ wizardData: policyChangeVM, wizardSnapshot, param: nextPath = '' }) => {
        const status = _.get(policyChangeVM.value, 'status');
        const jobID = _.get(policyChangeVM.value, 'jobID');
        const policyNumber = _.get(policyChangeVM.value, 'policyNumber');

        if ((status === 'Bound' && !_.isNil(jobID)) || (nextPath.startsWith('/change') || nextPath.startsWith('/contactAgent')) || hasErrorOccurred) {
            return true;
        }

        return showConfirm({
            title: commonPolicyChangeMessages.leaveWithoutSaving,
            message: commonPolicyChangeMessages.infoWillBeSavedAsDraft,
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.WARNING,
            cancelButtonText: platformMessages.cancelModel
        }).then((results) => {
            if (results === 'cancel') {
                return _.noop();
            }

            setIsLoading(true);

            const isValid = policyChangeVM.aspects.subtreeValid && policyChangeVM.aspects.valid;
            const payload = isValid ? policyChangeVM.value : _.get(wizardSnapshot, 'value');

            const quoteStatus = _.get(wizardSnapshot, 'baseData.periodStatus.value.code')
                || _.get(policyChangeVM.value, 'status');
            const isStatusQuoted = quoteStatus !== 'Quoted';
            let redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/change/${jobID}/summary`;

            if (!jobID) {
                redirectLobPath = `/policies/${policyNumber}/summary`;
            }

            let exitPromise;

            if (isStatusQuoted && canEditPolicyChange) {
                exitPromise = EndorsementService.saveEndorsement([payload], authHeader);
            } else {
                return history.push(redirectLobPath);
            }

            exitPromise.then(() => {
                history.push(redirectLobPath);
            }).catch((error) => {
                if (typeof error === 'object') {
                    return WMICErrorHandler.processAsModal(error);
                }

                showWarning({
                    title: commonPolicyChangeMessages.unableToDraftPolicy,
                    message: commonPolicyChangeMessages.anErrorOccurred,
                    status: MODAL_CONSTANTS.STATUS.WARNING,
                    icon: MODAL_CONSTANTS.ICON.WARNING
                });
            }).finally(() => {
                setIsLoading(false);
            });

            return true;
        }, _.noop);
    }, [hasErrorOccurred, showConfirm, canEditPolicyChange, EndorsementService, authHeader, history, showWarning]);

    const handleError = useCallback((error) => {
        WMICWizardHandler.handlePolicyChangeErrorRender(error, setIsRenderWizard);
        WMICWizardHandler.handlePolicyChangeError(error, location, history, setHasErrorOccurred);
    }, [history, location]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialPolicyChange) {
        return null;
    }

    return (
        <div className={styles.paPolicyChangeWizardContainer}>
            <ErrorBoundary onError={handleError}>
                {isRenderWizard &&
                    <Wizard
                        initialSteps={steps}
                        wizardTitle={translator(messages.progress)}
                        initialData={initialPolicyChange}
                        onCancel={handleOnCancel}
                        wizardStepToFieldMapping={wizardStepToFieldMapping}
                        onPreviousModalProps={{
                            title: commonPolicyChangeMessages.wantToJump,
                            message: commonPolicyChangeMessages.wantToJumpMessage,
                            status: MODAL_CONSTANTS.STATUS.WARNING,
                            icon: MODAL_CONSTANTS.ICON.WARNING,
                            confirmButtonText: platformMessages.yes,
                            cancelButtonText: platformMessages.no
                        }}
                        checkValidity={checkValidity}
                        skipCompletedSteps
                        maintainFurtherStepsVisitedSubmitted={{
                            flag: !isCustomStepsInvalidated,
                            targetStep: getTargetPageIndex(steps),
                        }}
                        setIsCustomStepsInvalidated={setIsCustomStepsInvalidated}
                    />
                }
            </ErrorBoundary>
        </div>
    );
}

WMICPEPAPolicyChangeWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            requestType: PropTypes.string,
            policyDetails: PropTypes.shape({
                effective: PropTypes.instanceOf(Date)
            })
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired
};

export default withViewModelService(WMICPEPAPolicyChangeWizard);
