// eslint doesn't seem to play nicely with the webpack aliases
// eslint-disable-next-line import/no-unresolved
import { capabilities, env } from 'app-config';
import { WMICApplicationUtil, APP_NAMES } from 'wmic-portals-utils-js';

function ensureSingleTrailingSlash(url) {
    return `${url}/`.replace(/(\/){2,}$/, '/');
}

function transformServiceNameWmic(serviceName) {
    const appName = WMICApplicationUtil.getAppName();
    let edgeVariant = '';
    switch (appName) {
        case APP_NAMES.CE:
        case APP_NAMES.AMP:
            edgeVariant = 'US';
            break;
        case APP_NAMES.PE:
            edgeVariant = 'CA';
            break;
        default:
            console.debug('Unknown application name. it is expected for PE and thus can be ignored');
            break;
    }
    return serviceName + edgeVariant;
}

/**
 * Returns the Real service url (not proxied)
 * @param {string} serviceName the name of the service to invoke
 *                  (the service name is defined in the ServiceEnpoint of each capability)
 * @returns {string} the real service url
 */
export function getProxiedServiceUrl(serviceName) {
    const transformedServiceName = transformServiceNameWmic(serviceName);
    const endpoint = capabilities[transformedServiceName] || capabilities[serviceName];
    const baseUrl = ensureSingleTrailingSlash(env.API_GATEWAY_URL);
    return baseUrl + endpoint.service;
}

/**
 * Returns a url which will be proxied depending on the base url
 *
 * This is generally meant for *non-EDGE* URLs.
 * If you you want to proxy an edge capability, you should probably use
 * `getProxiedServiceUrl`
 *
 * @see {@link getProxiedServiceUrl}
 * @param {string} urlToProxy the url that should be proxied
 * @returns {string} the real url which accounts for the deployment url
 */
export function getProxiedUrl(urlToProxy) {
    const baseUrl = ensureSingleTrailingSlash(env.API_GATEWAY_URL);
    return baseUrl + urlToProxy;
}
