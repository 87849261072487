/* eslint-disable max-len */

import React, {
    Fragment, useContext, useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import {
    CurrencyValue, ModalHeader, ModalBody, ModalFooter
} from '@jutro/components';
import { WMICPaymentUtil } from 'wmic-portals-utils-js';
import { WMICCurrencyField, WMICButton, WMICCheckbox } from 'wmic-components-platform-react';
import classNames from 'classnames';
import messages from './WMICPaymentModal.messages';
import style from './WMICPaymentModal.module.scss';

const DISPLAY_MODE = {
    edit: 'edit',
    default: 'default',
    custom: 'custom'
};

const PAYMENT_TYPE = {
    amountOverdue: 'amountOverdue',
    amountDue: 'amountDue',
    renewalPaymentDue: 'renewalPaymentDue',
    nextPlannedAmount: 'nextPlannedAmount',
    remainingBalance: 'remainingBalance'
};
const currencyType = 'USD';

const WMICConfirmPaymentModal = (props) => {
    const {
        onReject, onContinue, data, updateSelectedPaymentAmount
    } = props;
    const intl = useContext(IntlContext);
    const translator = useTranslator();
    const [displayMode, changeDisplayMode] = useState(DISPLAY_MODE.default);
    const [hasBeenEdited, updateEdited] = useState(false);
    const [optionalPaymentItems, setOptionalPaymentItems] = useState([]);
    const [localSelectedPaymentAmount, updateLocalSelectedPaymentAmount] = useState({});

    const billingData = _.get(data, 'billingData', {});

    const localeService = ServiceManager.getService('locale-service');
    // eslint-disable-next-line no-unused-vars
    const defaultCurrencyCode = localeService.getDefaultCurrencyCode();
    const customPayment = displayMode === DISPLAY_MODE.custom;
    const defaultPayment = displayMode === DISPLAY_MODE.default;
    const editPayment = displayMode === DISPLAY_MODE.edit;

    const amountOverdue = WMICPaymentUtil.getOverdueAmount(billingData);
    const paymentDue = WMICPaymentUtil.getPaymentDue(billingData);
    const renewalPaymentDue = WMICPaymentUtil.getRenewalPaymentDue(billingData);
    const nextPlannedAmount = WMICPaymentUtil.getNextPayment(billingData);
    const remainingBalance = WMICPaymentUtil.getRemainingBalance(billingData);
    const maxPaymentAmount = WMICPaymentUtil.getMaximumPaymentAmount(billingData);
    const isPolicyCanceled = WMICPaymentUtil.shouldDisplayInactivePolicyMessage(billingData, data.policyData.displayStatus_WMIC);
    const { amount: selectedAmount } = localSelectedPaymentAmount;
    const isPaymentValid = _.gte(selectedAmount, 1);
    const isInsufficientOverdueAmount = isPaymentValid && _.lt(selectedAmount, amountOverdue);
    const isPaymentAmountOverpay = isPaymentValid && _.gt(selectedAmount, maxPaymentAmount);
    const isAmountOverCreditCardLimit = WMICPaymentUtil.isAmountOverCreditCardLimit(selectedAmount);
    const isListBill = WMICPaymentUtil.isListBill(billingData);
    const isPaymentAmountRemainingLessThanOneDollar = WMICPaymentUtil.isPaymentAmountRemainingLessThanOneDollar(false, selectedAmount, billingData);


    const onBtnClick = useCallback(() => {
        changeDisplayMode(DISPLAY_MODE.edit);
        updateEdited(true);
    }, []);

    const onSaveChanges = () => {
        changeDisplayMode(DISPLAY_MODE.custom);
    };

    const generateDate = useCallback((date) => {
        return intl.formatDate(
            new Date(date), { year: 'numeric', month: '2-digit', day: '2-digit' }
        );
    }, [intl]);

    const requiredPaymentItems = useMemo(() => {
        const items = [];
        if (amountOverdue.isPositive()) {
            items.push(
                {
                    type: PAYMENT_TYPE.amountOverdue,
                    required: true,
                    name: translator(messages.overdueAmount),
                    amount: amountOverdue.toString()
                }
            );
        }
        if (paymentDue.isPositive()) {
            const paymentDueDate = billingData.nextDuePaymentDate;
            items.push(
                {
                    type: PAYMENT_TYPE.amountDue,
                    required: true,
                    name: translator(messages.paymentDue, { date: generateDate(paymentDueDate) }),
                    amount: paymentDue.toString()
                }
            );
        }
        if (renewalPaymentDue.isPositive()) {
            const renewalPaymentDueDate = billingData.renewalPaymentDetails.nextDuePaymentDate;
            items.push(
                {
                    type: PAYMENT_TYPE.renewalPaymentDue,
                    required: true,
                    name: translator(messages.renewPaymentDue, { date: generateDate(renewalPaymentDueDate) }),
                    amount: renewalPaymentDue.toString()
                }
            );
        }

        return items;
    }, [amountOverdue, billingData, paymentDue, renewalPaymentDue, translator, generateDate]);

    const generateOptionalItems = useMemo(() => {
        const items = [];
        if (nextPlannedAmount.isPositive()) {
            let selected = false;
            let disabled = false;
            if (requiredPaymentItems.length === 0) {
                selected = true;
                disabled = true;
            }
            const nextPlannedAmountDate = WMICPaymentUtil.getNextPaymentDate(billingData);
            items.push(
                {
                    type: PAYMENT_TYPE.nextPlannedAmount,
                    required: false,
                    name: translator(messages.nextPayment, { date: generateDate(nextPlannedAmountDate) }),
                    amount: nextPlannedAmount.toString(),
                    selected: selected,
                    disabled: disabled
                }
            );
        }

        if (remainingBalance.isPositive()) {
            let selected = false;
            let disabled = true;
            if (requiredPaymentItems.length === 0) {
                disabled = false;
            }
            if (requiredPaymentItems.length === 0 && nextPlannedAmount.isZero()) {
                selected = true;
                disabled = true;
            }

            items.push(
                {
                    type: PAYMENT_TYPE.remainingBalance,
                    required: false,
                    name: translator(messages.futurePayments),
                    amount: remainingBalance.toString(),
                    selected: selected,
                    disabled: disabled
                }
            );
        }

        return items;
    }, [billingData, nextPlannedAmount, remainingBalance, requiredPaymentItems.length, translator, generateDate]);

    const allPaymentItems = requiredPaymentItems.concat(optionalPaymentItems);

    const onOptionalPaymentChange = useCallback((value, index) => {
        const nextIndex = index + 1;
        const newItems = _.cloneDeep(optionalPaymentItems);
        if (newItems.length > nextIndex) {
            if (value) {
                _.set(newItems, `${nextIndex}.disabled`, false);
            } else {
                _.set(newItems, `${nextIndex}.selected`, false);
                _.set(newItems, `${nextIndex}.disabled`, true);
            }
        }
        _.set(newItems, `${index}.selected`, value);
        setOptionalPaymentItems([...newItems]);
    }, [optionalPaymentItems]);

    const shouldShowPaymentRow = useCallback((paymentItem) => {
        return (paymentItem.required) || (!paymentItem.required && paymentItem.selected);
    }, []);

    const initialPaymentAmount = useMemo(() => {
        const sum = allPaymentItems.reduce((memory, item) => {
            return memory + (shouldShowPaymentRow(item) ? parseFloat(item.amount) : 0);
        }, 0);
        return sum;
    }, [allPaymentItems, shouldShowPaymentRow]);

    const totalAmount = useCallback((amtAry) => {
        return amtAry.reduce((memory, item) => {
            return memory + parseFloat(item.amount);
        }, 0);
    }, []);


    const getDescription = useMemo(() => {
        let messageKey = 'clickEdit';

        if (displayMode === DISPLAY_MODE.default && !hasBeenEdited && requiredPaymentItems.length === 0) {
            messageKey = 'clickEditAmount';
        }

        if ((displayMode === DISPLAY_MODE.default || displayMode === DISPLAY_MODE.custom) && hasBeenEdited) {
            messageKey = 'modifyAmount';
        }

        if (displayMode === DISPLAY_MODE.edit && requiredPaymentItems.length > 0) {
            messageKey = 'canBePaid';
        }

        return translator(messages[messageKey]);
    }, [translator, displayMode, hasBeenEdited, requiredPaymentItems]);

    const showWarning = useCallback((message, isError, additionalText) => {
        const className = classNames(style.warningText, { 'ww-error': isError });
        return (
            <p className={className}>
                <i className={`fa fa-exclamation-circle ${style.warningIcon}`} />
                &nbsp;
                {message}
                {additionalText && ` (${additionalText}).`}
            </p>
        );
    }, []);

    useEffect(() => {
        updateSelectedPaymentAmount({ currency: currencyType, amount: parseFloat(initialPaymentAmount) });
        updateLocalSelectedPaymentAmount({ currency: currencyType, amount: parseFloat(initialPaymentAmount) });
    }, [initialPaymentAmount, updateSelectedPaymentAmount]);

    useEffect(() => {
        setOptionalPaymentItems([...generateOptionalItems]);
    }, []);

    return (
        <Fragment>
            <h3 className='wmic-modal-header-title'>{translator(displayMode === DISPLAY_MODE.edit ? messages.editPaymentAmount : messages.confirmPaymentAmount)}</h3>
            <ModalBody>
                <div className={style.paymentModalBody}>
                    <p>{getDescription}</p>
                    <div className={style.wwPaymentLedger}>
                        {
                            !editPayment && (
                                <div>
                                    {
                                        defaultPayment && (
                                            allPaymentItems.map((paymentItem) => shouldShowPaymentRow(paymentItem) && (
                                                <div className={style.wwPaymentLedgerRow}>
                                                    <span className={style.wwPaymentLedgerValue}>
                                                        {paymentItem.name}
                                                    </span>
                                                    <CurrencyValue
                                                        id="PaymentItemValue"
                                                        className={style.currencyValue}
                                                        showFractions
                                                        tag="span"
                                                        value={paymentItem.amount}
                                                    />
                                                </div>
                                            ))
                                        )
                                    }

                                    <div className={style.wwPaymentLedgerSubtotal}>
                                        <span>
                                            { customPayment && translator(messages.yourCustomPayment) }
                                            { hasBeenEdited && !customPayment && translator(messages.yourSelectedPayment) }
                                            {
                                                !hasBeenEdited && defaultPayment && translator(messages.totalPayment)
                                            }
                                        </span>
                                        <CurrencyValue
                                            id="CurrencyValue"
                                            className={style.currencyValue}
                                            showFractions
                                            tag="span"
                                            value={localSelectedPaymentAmount.amount}
                                        />
                                    </div>
                                    <div className={`${style.paymentLedgerEdits}`}>
                                        <WMICButton
                                            onClick={onBtnClick}
                                            size="small"
                                            type="secondary"
                                        >
                                            {translator(messages.editAmount)}
                                        </WMICButton>
                                    </div>
                                </div>
                            )
                        }
                        {
                            editPayment && (
                                <div>
                                    { requiredPaymentItems.length > 0 && (
                                        <div>
                                            <h4 className={style.wwPaymentSubHeader}>
                                                {translator(messages.requiredPayments)}
                                            </h4>
                                            {
                                                requiredPaymentItems.map((paymentItem) => (
                                                    <div className={style.wwPaymentLedgerRow}>
                                                        <span className={style.wwPaymentLedgerValue}>
                                                            {paymentItem.name}
                                                        </span>
                                                        <CurrencyValue
                                                            id="requiredPaymentItemValue"
                                                            className={style.currencyValue}
                                                            showFractions
                                                            tag="span"
                                                            value={paymentItem.amount}
                                                        />
                                                    </div>
                                                ))
                                            }
                                            <div className={`${style.wwPaymentLedgerRow} ${style.wwPaymentLedgerHighlight}`}>
                                                <span>{ translator(messages.totalRequiredPayments)}</span>
                                                <CurrencyValue
                                                    id="requiredPaymentItems"
                                                    className={style.currencyValue}
                                                    showFractions
                                                    tag="span"
                                                    value={totalAmount(requiredPaymentItems)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    { optionalPaymentItems.length > 0 && (
                                        <div>
                                            <h4 className={style.wwPaymentSubHeader}>
                                                { translator(messages.upcomingPayments) }
                                            </h4>
                                            <div>
                                                {
                                                    optionalPaymentItems.map((optionalItem, index) => (
                                                        <div className={style.wwPaymentLedgerRow}>
                                                            <div className={style.withCheckbox}>
                                                                <WMICCheckbox
                                                                    id="CheckboxField"
                                                                    value={optionalItem.selected}
                                                                    disabled={optionalItem.disabled}
                                                                    onValueChange={(value) => onOptionalPaymentChange(value, index)}
                                                                />
                                                                <span>{optionalItem.name}</span>
                                                            </div>
                                                            <CurrencyValue
                                                                id="OptionalItemValue"
                                                                className={style.currencyValue}
                                                                showFractions
                                                                tag="span"
                                                                value={optionalItem.amount}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )}
                                    <div className={style.wwPaymentLedgerSubtotal}>
                                        <span>{translator(messages.totalRemainingBalance)}</span>
                                        <CurrencyValue
                                            id="allPaymentItems"
                                            className={style.currencyValue}
                                            showFractions
                                            tag="span"
                                            value={totalAmount(allPaymentItems)}
                                        />
                                    </div>

                                    <div className={style.wwPaymentLedgerSubtotalEdit}>
                                        <span>{translator(messages.yourSelectedPayment)}</span>
                                        <span>
                                            <WMICCurrencyField
                                                id="currency"
                                                hideLabel
                                                value={localSelectedPaymentAmount}
                                                onValueChange={(newValue) => {
                                                    updateSelectedPaymentAmount({ amount: newValue.amount, currency: currencyType });
                                                    updateLocalSelectedPaymentAmount({ amount: newValue.amount, currency: currencyType });
                                                }}
                                            />
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className={style.warningMessages}>
                        {isPolicyCanceled && showWarning(
                            translator(messages.reinstateCancelledPolicy)
                        )}
                        {isInsufficientOverdueAmount && showWarning(
                            translator(messages.overDueAmountPaid, { overDueAmount: intl.formatNumber(amountOverdue, {
                                style: 'currency',
                                currency: currencyType,
                                currencyDisplay: 'symbol'
                            }) }), false
                        )}
                        {!isPaymentValid && showWarning(
                            translator(messages.amountMore), true
                        )}
                        {isAmountOverCreditCardLimit && showWarning(
                            translator(messages.multipleTransactions)
                        )}
                        {isListBill && showWarning(
                            translator(messages.paidByThirdParty)
                        )}
                        {isPaymentAmountOverpay && showWarning(translator(messages.outstandingPolicy), true, intl.formatNumber(
                            maxPaymentAmount,
                            {
                                style: 'currency',
                                currency: currencyType,
                                currencyDisplay: 'symbol'
                            }
                        ))}
                        {isPaymentAmountRemainingLessThanOneDollar && showWarning(
                            translator(messages.afterPayment), true
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="ww-btn-group-gap-3">
                    <WMICButton
                        type="outlined"
                        onClick={() => { onReject(); }}
                    >
                        {translator(messages.cancel)}
                    </WMICButton>
                    { displayMode !== DISPLAY_MODE.edit && (
                        <WMICButton
                            type="primary"
                            onClick={() => onContinue()}
                        >
                            {translator(messages.continue)}
                        </WMICButton>
                    )}
                    { displayMode === DISPLAY_MODE.edit && (
                        <WMICButton
                            type="primary"
                            onClick={() => onSaveChanges()}
                            disabled={!isPaymentValid || isPaymentAmountOverpay}
                        >
                            {translator(messages.saveChanges)}
                        </WMICButton>
                    )}
                </div>
            </ModalFooter>
        </Fragment>
    );
};

WMICConfirmPaymentModal.propTypes = {
    onReject: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    data: PropTypes.shape({}),
    updateSelectedPaymentAmount: PropTypes.shape({}).isRequired
};

WMICConfirmPaymentModal.defaultProps = {
    data: {}
};

export default WMICConfirmPaymentModal;