/* eslint-disable max-len */
import React, { useContext } from 'react';
import { IntlContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICPaymentHistoryElement.metadata.json5';
import styles from './WMICPaymentHistoryElement.module.scss';


const WMICPaymentHistoryElement = (props) => {
    const { data: elementData } = props;

    const intl = useContext(IntlContext);

    const paymentDate = intl.formatDate(new Date(elementData.receivedDate), { year: 'numeric', month: '2-digit', day: '2-digit' });
    const paymentMethod = elementData.paymentMethod;
    const paymentAmount = elementData.amount;
    const policyNumber = elementData.policyNumber;

    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrides = {
        paymentDate: {
            content: paymentDate
        },
        paymentMethod: {
            content: paymentMethod
        },
        paymentAmount: {
            value: paymentAmount,
            showFractions: true
        },
        paymentHistorySrOnly: {
            content: "A payment of $" + paymentAmount + "  was made on "+ paymentDate + ", account reference "+ paymentMethod + ". Policy #" + policyNumber 
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrides}
            model={elementData}
        />
    );
};

WMICPaymentHistoryElement.propTypes = {
    data: PropTypes.shape({})
};

WMICPaymentHistoryElement.defaultProps = {
    data: {}
};

export default WMICPaymentHistoryElement;
