/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, { useEffect, useState, Fragment } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import { Loader, Link } from '@jutro/components';
import { Grid } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from 'wmic-digital-auth-react';
import { ClaimService } from 'gw-capability-claim';
import { WMICPolicyDocuments, WMICPolicyDetailsUtil } from 'gw-capability-policy-react';

import {
    WMICClaimAndVehicleInfoBox,
    WMICAdjusterInfoBox,
    WMICEstimateAndRepairFacilityInfoBox,
    WMIAdditionalVehicleInfoBox,
    WMICLocationAndIncidentInfoBox,
    WMICPaymentInfoBox,
    WMICRentalVehicleInfoBox
} from 'gw-capability-claim-react';


import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICPageNavigationUtil, WMICFeatureFlagUtil, SUITES } from 'wmic-portals-utils-js';
import metadata from './WMICClaimDetails.metadata.json5';
import messages from './WMICClaimDetails.messages';
import styles from './WMICClaimDetails.module.scss';

function WMICClaimDetails(props) {
    const { match } = props;
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const history = useHistory();
    const [claimDetails, setClaimDetails] = useState(undefined);
    const [loadingState, setLoadingState] = useState(true);
    const [claimFFAvailability, setClaimFFAvailability] = useState({});  
    const [claimDocs, setClaimDocs] = useState([]);  
    const [ldFlags, setLdFlags] = useState([]);
    const translator = useTranslator();
    const claimNumber = _.get(match, 'params.claimNumber');
    const DOM_ELEMENT_IDS = {
        CLAIM_AND_VEHICLE_INFO: 'claimAndVehicleInfoBoxContainer',
        ADJUSTER_INFO: 'adjusterInfoBoxContainer',
        ESTIMATE_AND_REPAIR_FACILITY_INFO: 'estimateAndRepairFacilityInfoBoxContainer',
        ADDITIONAL_VEHICLE_INFO: 'additionalVehicleInfoBoxContainer',
        LOCATION_AND_INCIDENT_INFO: 'locationAndIncidentInfoBoxContainer',
        PAYMENT_INFO: 'paymentInfoBoxContainer',
        RENTAL_INFO: 'rentalVehicleInfoBoxContainer',
        CLAIM_DOCUMENTS: 'claimDocumentInfoBoxContainer'
    };
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const rFlagsPromise = WMICFeatureFlagUtil.useFlags(authUserData);

    const VALID_DOCUMENTS = [
        'BENEFITSLETTER',
        'BENEFITSLETTERUMPD',
        'BENEFITSLETTERNOCOLLISSIONORCOMP',
        'BENEFITSLETTERTHEFTORGLASSLOSS',
        'OREGONBENEFITLETTER',
        'OREGONBENEFITSLETTERUMPD',
        'MEDPAYBENEFITSLETTERDOCTOR',
        'OREGONBENEFITSLETTERGLASSLOSS'
    ];

    useEffect(() => {
        const claimAvailable = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.CLAIMS);
        setClaimFFAvailability(claimAvailable);
        if (!claimAvailable.isAvailable && ldFlags.length !== 0) {
            history.push('/technicalErrorPage/error');
        }
    }, [ldFlags]);

    const initFeatureFlags = async () => {
        const rFlags = await rFlagsPromise;
        setLdFlags(rFlags);
    }

    useEffect(() => {
        initFeatureFlags();
        setLoadingState(true);
        Promise.all([
            ClaimService.getClaimDetail(claimNumber, authHeader).then((claimDetailsResponse) => {
                populateClaimDocuments(claimDetailsResponse);
                setClaimDetails(claimDetailsResponse);
            })
        ]).finally(() => {
            setLoadingState(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const getDocumentName = (documentIdentifier, defaultValue) => {
        const translationKey = messages[`${documentIdentifier.toLowerCase()}`];
        let documentName = translator(translationKey);
        
        if (documentName === translationKey) {
            documentName = defaultValue;
        }
        return documentName;
    }

    const populateClaimDocuments = (refreshedClaimDetails) => {
        const claimDocuments = [];

        if (refreshedClaimDetails.documents.length > 0) {
            const foundDocuments = _.filter(refreshedClaimDetails.documents, (doc) => VALID_DOCUMENTS.indexOf(doc.documentPatternID_WMIC.toUpperCase()) !== -1);

            for (const docDTO of foundDocuments) {
                const currentDoc = {
                    id: docDTO.workingPublicID,
                    friendlyName: translator(messages.viewDocument),
                    sessionID: docDTO.sessionID,
                    localDateCompleted: docDTO.localDateCreated_WMIC,
                    fileName: docDTO.name,
                    name: getDocumentName(docDTO.documentPatternID_WMIC, docDTO.name),
                    type: SUITES.CC
                };

                claimDocuments.push(currentDoc);
            }

            setClaimDocs(claimDocuments);
        }
    }

    const showEstimateAndRepairFacilityBox = (info) => info && info.lobs && info.lobs.personalAuto && info.lobs.personalAuto.vehicleIncidents
            && info.lobs.personalAuto.vehicleIncidents.length > 0
            && !!info.lobs.personalAuto.vehicleIncidents[0].damageRepairProgram_WMIC
    const showAdditionalVehicleBox = (info) => {
        // return info && info.lobs && info.lobs.personalAuto && info.lobs.personalAuto.vehicleIncidents
        //     && info.lobs.personalAuto.vehicleIncidents.length > 1; 
        return false; // AMPDM-2784 - says that this section should never show
    }
    const showPaymentBox = (info) => info && info.lobs && info.lobs.personalAuto && info.lobs.personalAuto.paymentDetails_WMIC
            && info.lobs.personalAuto.paymentDetails_WMIC.length > 0

    const showRentalBox = (info) => info && info.lobs && info.lobs.personalAuto && info.lobs.personalAuto.rentalInfo_WMIC
            && info.lobs.personalAuto.rentalInfo_WMIC.length > 0
            
    const showAdjuster = (claim) => claim && claim.lobs && claim.lobs.personalAuto && claim.lobs.personalAuto.adjusters_WMIC
            && claim.lobs.personalAuto.adjusters_WMIC.length > 0
            
    const getSideMenuLinks = (claim) => {
        const navigationLinks = [];

        navigationLinks.push(
            <Link onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.CLAIM_AND_VEHICLE_INFO)} to="/">
                {translator(messages.ClaimAndVehicleInformation)}
            </Link>
        );

        if (showAdjuster(claim)) {
            navigationLinks.push(
                <Link onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.ADJUSTER_INFO)} to="/">
                    {translator(messages.AdjusterInformation)}
                </Link>
            );
        }

        if (showEstimateAndRepairFacilityBox(claim)) {
            navigationLinks.push(
                <Link onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.ESTIMATE_AND_REPAIR_FACILITY_INFO)} to="/">
                    {translator(messages.EstimateOrRepairFacilityInformation)}
                </Link>
            );
        }

        if (showAdditionalVehicleBox(claim)) {
            navigationLinks.push(
                <Link onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.ADDITIONAL_VEHICLE_INFO)} to="/">
                    {translator(messages.AdditionalInsuredVehiclesAndDrivers)}
                </Link>
            );
        }

        navigationLinks.push(
            <Link onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.LOCATION_AND_INCIDENT_INFO)} to="/">
                {translator(messages.LocationAndIncidentInformation)}
            </Link>
        );

        if (showPaymentBox(claim)) {
            navigationLinks.push(
                <Link onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.PAYMENT_INFO)} to="/">
                    {translator(messages.ClaimsPaymentHistory)}
                </Link>
            );
        }

        if (showRentalBox(claim)) {
            navigationLinks.push(
                <Link onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.RENTAL_INFO)} to="/">
                    {translator(messages.RentalVehicleInformation)}
                </Link>
            );
        }

        if (Array.isArray(claimDocs) && claimDocs.length > 0) {
            navigationLinks.push(
                <Link onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.CLAIM_DOCUMENTS)} to="/">
                    {translator(messages.claimDocuments)}
                </Link>
            );
        }

        // Uncomment the line below to enable the mobile view of the menu
        // WMICAMPNavigationUtil.setMobileViewLinks(navigationLinks);

        return (
            <Grid
                columns={['1fr']}
                gap="xsmall"
                justifyItems="left"
                className={styles.claimMenuContentGrid}
            >
                {navigationLinks}
            </Grid>
        );
    };

    const getPageTitle = () => (
            <Fragment>
                <i className="fa fa-car" />
                { translator(messages.PaClaimNumber, { claimNumber: claimNumber }) }
            </Fragment>
        );

    const overrideProps = {
        pageTitle: {
            content: getPageTitle()
        },
        claimMenuContent: {
            content: getSideMenuLinks(claimDetails)
        },
        wmicClaimAndVehicleInfoBox: {
            visible: !!claimDetails,
            claimData: claimDetails
        },
        wmicAdjusterInfoBox: {
            visible: showAdjuster(claimDetails),
            claimData: claimDetails
        },
        wmicEstimateAndRepairFacilityInfoBox: {
            visible: showEstimateAndRepairFacilityBox(claimDetails),
            claimData: claimDetails
        },
        wmicAdditionalVehicleInfoBox: {
            visible: showAdditionalVehicleBox(claimDetails),
            claimData: claimDetails
        },
        wmicLocationAndIncidentInfoBox: {
            visible: !!claimDetails,
            claimData: claimDetails
        },
        wmicPaymentInfoBox: {
            visible: showPaymentBox(claimDetails),
            claimData: claimDetails
        },
        wmicRentalVehicleInfoBox: {
            visible: showRentalBox(claimDetails),
            claimData: claimDetails
        },
        wmicClaimDocumentsInfoBox: {
            policyDocs: claimDocs,
            visible: Array.isArray(claimDocs) && claimDocs.length > 0,
            isAdditionalDocumentsAvailable: false,
            cardHeaderMessage: messages.claimDocuments
        }
    };

    const resolvers = {
        resolveComponentMap: {
            wmicclaimandvehicleinfobox: WMICClaimAndVehicleInfoBox,
            wmicadjusterinfobox: WMICAdjusterInfoBox,
            wmicestimateandrepairfacilityinfobox: WMICEstimateAndRepairFacilityInfoBox,
            wmicadditionalvehicleinfobox: WMIAdditionalVehicleInfoBox,
            wmiclocationandincidentinfobox: WMICLocationAndIncidentInfoBox,
            wmicpaymentinfobox: WMICPaymentInfoBox,
            wmicrentalvehicleinfobox: WMICRentalVehicleInfoBox,
            wmicclaimdocuments: WMICPolicyDocuments,
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        }
    };

    return (
        <Loader loaded={!loadingState}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimDetails}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </Loader>
    );
}

WMICClaimDetails.propTypes = {
    match: PropTypes.shape({}).isRequired
};

export default withRouter(WMICClaimDetails);