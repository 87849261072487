import React from 'react';
import PropTypes from 'prop-types';
import { uniqueInnerId } from '@jutro/platform';
import { useTranslator } from '@jutro/locale';
import { MetadataForm } from '@jutro/uiconfig';
import { WMICCheckbox } from 'wmic-components-platform-react';
import styles from './WMICDesignatedPersonDetailsForm.module.scss';
import metadata from './WMICDesignatedPersonDetailsForm.metadata.json5';
import messages from './WMICDesignatedPersonDetailsForm.messages';


const WMICDesignatedPersonDetailsForm = ({
    id,
    contactDetails,
    setAddress,
    setValidity
}) => {
    const translator = useTranslator();
    const ids = uniqueInnerId(id, 'name', 'street', 'unit', 'city', 'state', 'zip', 'permission');

    const componentMap = {
        wmicCheckbox: WMICCheckbox,
    }

    const overrideProps = {
        '@all': (id) => ({
            messageProps: {
                requiredField: `${translator(messages[`${id}`])} field is required`
            }
        }),
        name: {
            id: ids.name,
            label: messages.name
        },
        street: {
            id: ids.street,
            label: messages.street
        },
        unit: {
            id: ids.unit,
            label: messages.unitNumber
        },
        city: {
            id: ids.city,
            label: messages.city
        },
        state: {
            id: ids.state,
            label: messages.state
        },
        zip: {
            id: ids.zip,
            label: messages.zip,
            validator: {
                pattern: '^\\d{5}(-\\d{4})?$',
                message: translator(messages.zipCodeIncorrect)
            },
            messageProps: {
                requiredField: translator(messages.zipCodeIncorrect)
            }
        },
        permission: {
            id: ids.permission,
            label: messages.permission
        }
    };

    return (
        <MetadataForm
            data={contactDetails}
            onDataChange={(value, path) => {
                const newFilters = { ...contactDetails };
                newFilters[path] = value;
                setAddress(newFilters);
            }}
            onValidationChange={(isValid) => {
                setValidity(isValid);
            }}
            uiProps={metadata['edit-address']}
            overrideProps={overrideProps}
            classNameMap={styles}
            componentMap={componentMap}
            showOptional />
    );
};

WMICDesignatedPersonDetailsForm.propTypes = {
    id: PropTypes.string.isRequired,
    contactDetails: PropTypes.shape({
        name: PropTypes.string,
        street: PropTypes.string,
        unit: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
    }).isRequired,
    setAddress: PropTypes.func.isRequired,
    setValidity: PropTypes.func.isRequired
};

export default WMICDesignatedPersonDetailsForm;