/* eslint-disable camelcase */
import React, {
    useState, useEffect, useRef
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { PAConstants } from 'wmic-pe-portals-utils-js';
import { WMICVehiclesUsageComponent, WMICVehicleOwnershipAndAssignmentComponent, WMICVehicleAdditionalInfoComponent, WMICVehicleInfoComponent } from 'wmic-pe-capability-gateway-common-pa-react'
import {  useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';
import { WMICDataList, WMICRequiredText } from 'wmic-pe-components-platform-react';
import './WMICVehiclesDetailView.messages';


import metadata from './WMICVehiclesDetailView.metadata.json5'

const VEHICLES_PATH = 'lobData.personalAuto.coverables.vehicles';

function WMICVehiclesDetailView({
    id, 
    jobVM, 
    updateWizardData,
    showErrors, 
    setShowErrors,
    value: selectedVehicleVM, 
    isEditing, 
    vehicleAction,
    additionalNamedInsureds,
    primaryNamedInsured,
    drivers,
    isReadOnlyUser,
    skeletonStruct,
    onValidate,
    updateModel,
}) {
    const vehicleSearchRef = useRef(null);
    const [reselectVehicle, updateReselectVehicle] = useState(false);

    const { authHeader } = useAuthentication();
    const {
        onValidate: onValidateComponent,
        isComponentValid,
        registerInitialComponentValidation
    } = useValidation('WMICVehiclesDetailView');
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid])

    useEffect(() => {
        registerInitialComponentValidation(() => _.get(jobVM, VEHICLES_PATH).value.length > 0
                && _.get(jobVM, VEHICLES_PATH).value.every((vehicle) => vehicle.drivers?.length > 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isRevalidateVinNotApplicable = () => isReadOnlyUser
            || selectedVehicleVM.vinOverride_WMIC.value
            || !selectedVehicleVM.vin.value
            || selectedVehicleVM.vin.value.length < PAConstants.MAX_VIN_LENGTH
            || !_.get(selectedVehicleVM, 'searchCompleted.value')
            || !vehicleSearchRef?.current;

    const revalidateVIN = () => {
        if (isRevalidateVinNotApplicable()) {
            return Promise.resolve();
        }

        return vehicleSearchRef.current();
    };

    const commonAccordionContentProps = {
        vehicleVM: selectedVehicleVM,
        updateVehicle: updateModel,
        baseData: _.get(jobVM, 'baseData'),
        onValidate: onValidateAccordion,
        isEditMode: isEditing,
        showErrors,
        isReadOnlyUser,
        authHeader,
        jobVM,
        updateWizardData
    };

    const overrideProps = {
        vehicleInfoAccordion: {
            showErrors,
            isValid: isAccordionValid('vehicleInfoComponent'),
        },
        vehicleInfoComponent:{
            ...commonAccordionContentProps,
            vehicleAction,
            reselectVehicle,
            updateReselectVehicle,
            vehicleSearchRef
        },
        usageAccordion: {
            showErrors,
            isValid: isAccordionValid('vehiclesUsageComponent')
        },
        vehiclesUsageComponent: {
            ...commonAccordionContentProps,
            reselectVehicle,
            revalidateVIN
        },
        vehicleOwnershipAccordion: {
            showErrors,
            isValid: isAccordionValid('ownershipAndAssignmentAccordionComponent')
        },
        ownershipAndAssignmentAccordionComponent: {
            ...commonAccordionContentProps,
            assignedDrivers: drivers,
            primaryNamedInsured,
            additionalNamedInsureds,
        },
        additionalInfoAccordion: {
            showErrors,
            isValid: isAccordionValid('additionalInfoAccordionComponent')
        },
        additionalInfoAccordionComponent: {
            ...commonAccordionContentProps,
            skeletonStruct,
            setShowErrors
        },
    }

    const resolvers = {
        resolveComponentMap:{
            WMICDataList,
            WMICRequiredText,
            WMICVehicleAdditionalInfoComponent,
            WMICVehicleInfoComponent,
            WMICVehicleOwnershipAndAssignmentComponent,
            WMICVehiclesUsageComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={jobVM}
            onModelChange={updateModel}
            overrideProps={overrideProps}
            onValidationChange={onValidateComponent}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}


export default WMICVehiclesDetailView;
