import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICEditForm, WMICAddressDetails } from 'wmic-pe-components-platform-react';
import { 
    CONSTANTS,
    WMICAddressDetailsUtil,
    WMICRichTextUtil,
    DeviceBreakpoint,
    Position,
    PAConstants,
    JURISDICTIONS,
    RIDE_SHARING_COMPANIES,
    WMICVehicleUtil
} from 'wmic-pe-portals-utils-js';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import WMICVehiclesUsageCommercial from './WMICVehiclesUsageCommercial/WMICVehiclesUsageCommercial';
import metadata from './WMICVehiclesUsageComponent.metadata.json5';
import messages from './WMICVehiclesUsageComponent.messages.js';
import styles from './WMICVehicleUsageComponent.module.scss';

let _revertedBusinessSegment = false;

function WMICVehiclesUsageComponent({
    id,
    vehicleVM,
    onValidate,
    isEditMode,
    showErrors,
    jobVM,
    reselectVehicle,
    revalidateVIN,
    updateVehicle,
    updateWizardData
}) {
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const translator = useContext(TranslatorContext);

    const createAddressVM = (model) => {
        return viewModelService.create(
            model,
            'pc',
            'wmic.edge.ca.capabilities.address.dto.AddressDTO'
        );
    }

    const [newPrimaryLocation, setNewPrimaryLocation] = useState(createAddressVM({ country: CONSTANTS.COUNTRY.CA }));
    const [newRatedGarageLocation, setNewRatedGarageLocation] = useState(createAddressVM({ country: CONSTANTS.COUNTRY.CA }));
    const [allPrimaryLocationAddresses, setAllPrimaryLocationAddresses] = useState(_.get(jobVM, 'baseData.accountAddresses_WMIC.value', []));
    const [allGarageLocationAddresses, setAllGarageLocationAddresses] = useState(_.get(jobVM, 'baseData.accountAddresses_WMIC.value', []));
    const [selectedPrimaryAddress, setSelectedPrimaryAddress] = useState(_.get(vehicleVM, 'primaryLocation_WMIC.value') || _.get(jobVM, 'baseData.accountAddresses_WMIC.value[0]', {}));
    const [selectedGarageAddress, setSelectedGarageAddress] = useState(_.get(vehicleVM, 'garageLocation_WMIC.value') || _.get(jobVM, 'baseData.accountAddresses_WMIC.value[0]', {}));
    const [isNewLocationFormVisible, setNewLocationFormVisible] = useState(false);
    const [isNewRatedGarageFormVisible, setNewRatedGarageFormVisible] = useState(false);
    const [businessSegmentErrorMsg, setBusinessSegmentErrorMsg] = useState();
    const [availablePrimaryUses, setAvailablePrimaryUses] = useState(_.get(vehicleVM, 'primaryUse.aspects.availableValues', []));
    const [availableRideSharingCompanies, setAvailableRideSharingCompanies] = useState(_.get(vehicleVM, 'rideSharingCompany_WMIC.aspects.availableValues', []));
    const [oldBusinessSegment, setOldBusinessSegment] = useState(_.get(vehicleVM, 'businessSegment_WMIC'));

    const isPrimaryUseDisabled = _.get(vehicleVM, 'vehicleType.value.code') === PAConstants.motorhomeVehicleType;

    useEffect(() => {
        setAddressCodes();
        setLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleVM.publicID.value]);

    useEffect(() => {
        if (!_.get(vehicleVM, 'usedForRideSharing_WMIC.value')) {
            _.set(vehicleVM, 'rideSharingCompany_WMIC.value', null);
            _.set(vehicleVM, 'rideSharingOtherDesc_WMIC.value', null);
            updateVehicle(vehicleVM);
            setAvailableRideSharingCompanies([]);
        } else {
            getShareCompanies();
        }
    }, [_.get(vehicleVM, 'primaryLocation_WMIC.value.state'), _.get(vehicleVM, 'usedForRideSharing_WMIC.value')]);

    const componentValidMap = useMemo(() => ({}), [])

    const componentValidation = useCallback((valid, componentId) => {
        componentValidMap[componentId] = valid;
        if (!(_.get(vehicleVM, 'businessSegment_WMIC.value.code') === PAConstants.vehicleBusinessSegmentCommercial)) {
            onValidate(componentValidMap.vehiclesUsageComponent, id);
        } else {
            const isCommercialVehicleContainerVisible = _.get(vehicleVM, 'commercialVehicle_WMIC.commercialVehicleContainer.aspects.ocular', false);
            onValidate(componentValidMap.vehiclesUsageComponent && 
                (isCommercialVehicleContainerVisible ? componentValidMap.vehiclesUsageCommercial : true), id);
        }
    }, [onValidate, componentValidMap])

    useEffect(() => {
        if (onValidate) {
            componentValidation(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const setCommercialVM = (newCommercialVM) => {
        _.set(vehicleVM, 'commercialVehicle_WMIC', newCommercialVM.value);
        updateVehicle(vehicleVM);
    }

    useEffect(() => {
        populateAvailablePrimaryUse();
    }, [_.get(vehicleVM, 'publicID.value'), _.get(vehicleVM, 'businessSegment_WMIC.value.code')]);

    // TODO BPN-5825 - move to BE
    const populateAvailablePrimaryUse = () => {
        let primaryUseCodes = [];
        // TODO BPN-5825 - move to BE (used in Vehicle Info component)
        // setValidTrailerSubTypes();

        if (isVehicleTypeMotorCycle() || isVehicleTypeMoped()) {
            primaryUseCodes = [
                CONSTANTS.VEHICLE_PRIMARY_USE.COMMUTING,
                CONSTANTS.VEHICLE_PRIMARY_USE.PLEASURE,
                CONSTANTS.VEHICLE_PRIMARY_USE.TOURING
            ];
        } else if (isGarageLocationQC()) {
            if (_.get(vehicleVM, 'businessSegment_WMIC.value.code') === PAConstants.vehicleBusinessSegmentCommercial) {
                primaryUseCodes = [CONSTANTS.VEHICLE_PRIMARY_USE.BUSINESS];
            } else if (_.get(vehicleVM, 'vehicleType.value.code') === PAConstants.personalAutoVehicleType) {
                primaryUseCodes = [
                    CONSTANTS.VEHICLE_PRIMARY_USE.BUSINESS,
                    CONSTANTS.VEHICLE_PRIMARY_USE.COMMUTING,
                    CONSTANTS.VEHICLE_PRIMARY_USE.PLEASURE,
                    CONSTANTS.VEHICLE_PRIMARY_USE.PARK_RIDE
                ];
            } else {
                primaryUseCodes = [
                    CONSTANTS.VEHICLE_PRIMARY_USE.BUSINESS,
                    CONSTANTS.VEHICLE_PRIMARY_USE.COMMUTING,
                    CONSTANTS.VEHICLE_PRIMARY_USE.PLEASURE
                ];
            }
        } else if (isCommercialVehicle()) {
            primaryUseCodes = [
                CONSTANTS.VEHICLE_PRIMARY_USE.BUSINESS,
                CONSTANTS.VEHICLE_PRIMARY_USE.FARM
            ];
        } else {
            primaryUseCodes = [
                CONSTANTS.VEHICLE_PRIMARY_USE.BUSINESS,
                CONSTANTS.VEHICLE_PRIMARY_USE.CLERGYMAN,
                CONSTANTS.VEHICLE_PRIMARY_USE.COMMUTING,
                CONSTANTS.VEHICLE_PRIMARY_USE.FARM,
                CONSTANTS.VEHICLE_PRIMARY_USE.PLEASURE
            ];
        }

        const rawPrimaryUses = _.filter(
            _.get(vehicleVM, 'primaryUse.aspects.availableValues'),
            (primaryUse) => {
                return _.includes(primaryUseCodes, primaryUse.code);
            }
        );

        setAvailablePrimaryUses(_.map(rawPrimaryUses, mapTypelistNames));

        // if the current value isn't in the new set then set it to null
        if (_.get(vehicleVM, 'primaryUse.value')
            && !_.includes(primaryUseCodes, _.get(vehicleVM, 'primaryUse.value.code'))) {
            _.set(vehicleVM, 'primaryUse.value', null);
            updateVehicle(vehicleVM);
        } else if (!_.get(vehicleVM, 'primaryUse.value')) {
            selectDefaultPrimaryUse();
        }
    }

    const mapTypelistNames = (type) => {
        return {
            ...type,
            name: {
                id: type.name,
                defaultMessage: type.name
            }
        }
    }

    const isGarageLocationOntario = () => {
        return _.get(vehicleVM, 'garageLocation_WMIC.state.value.code') === JURISDICTIONS.ONTARIO;
    };

    const isGarageLocationMaritime = () => {
        return JURISDICTIONS.MARITIMES.includes(_.get(vehicleVM, 'garageLocation_WMIC.state.value.code'));
    };

    const isGarageLocationPE = () => {
        return _.get(vehicleVM, 'garageLocation_WMIC.state.value.code') === JURISDICTIONS.PRINCE_EDWARD_ISLAND;
    };

    const isGarageLocationQC = () => {
        return _.get(vehicleVM, 'garageLocation_WMIC.state.value.code') === JURISDICTIONS.QUEBEC;
    };

    const isCommercialVehicle = () => {
        return _.get(vehicleVM, 'businessSegment_WMIC.value.code') === PAConstants.vehicleBusinessSegmentCommercial;
    };

    const isVehicleType = (typeCode) => {
        return _.get(vehicleVM, 'vehicleType.value.code') === typeCode;
    };

    const isVehicleTypeAuto = () => {
        return isVehicleType(PAConstants.personalAutoVehicleType);
    };

    const isVehicleTypeMotorCycle = () => {
        return isVehicleType(PAConstants.motorcycleVehicleType);
    };

    const isVehicleTypeMotorhome = () => {
        return isVehicleType(PAConstants.motorhomeVehicleType);
    };

    const isVehicleTypeMoped = () => {
        return isVehicleType(PAConstants.mopedVehicleType);
    };

    const isVehicleTypeAutoMotorCyleOrMotorHome = () => {
        return isVehicleTypeAuto() || isVehicleTypeMotorCycle() || isVehicleTypeMotorhome();
    };


    const selectDefaultPrimaryUse = () => {
        let defaultPrimaryUse = null;

        // if there is no value then default it
        if (!_.get(vehicleVM, 'primaryUse.value')) {
            if (_.get(vehicleVM, 'vehicleType.value')) {
                // eslint-disable-next-line default-case
                switch (_.get(vehicleVM, 'vehicleType.value.code')) {
                    case PAConstants.motorcycleVehicleType:
                        defaultPrimaryUse = CONSTANTS.VEHICLE_PRIMARY_USE.COMMUTING;
                        break;
                    case PAConstants.motorhomeVehicleType:
                    case PAConstants.trailerVehicleType:
                    case PAConstants.atvVehicleType:
                    case PAConstants.snowVehicleType:
                        defaultPrimaryUse = CONSTANTS.VEHICLE_PRIMARY_USE.PLEASURE;
                        break;
                }
            }
        }

        if (defaultPrimaryUse) {
            const tempPrimaryUse = _.find(
                _.get(vehicleVM, 'primaryUse.aspects.availableValues'),
                (primaryUse) => {
                    return primaryUse.code.toLowerCase() === defaultPrimaryUse;
                });
            _.set(vehicleVM, 'primaryUse.value', tempPrimaryUse);
            updateVehicle(vehicleVM);
        }
    }

    const setLocations = () => {
        if (_.get(vehicleVM, 'primaryLocation_WMIC.value') === undefined && selectedPrimaryAddress) {
            _.set(vehicleVM, 'primaryLocation_WMIC', selectedPrimaryAddress);
        }

        if (_.get(vehicleVM, 'garageLocation_WMIC.value') === undefined && selectedGarageAddress) {
            _.set(vehicleVM, 'garageLocation_WMIC', selectedGarageAddress);
        }
        updateVehicle(vehicleVM);
    }

    const addCodeName = (address) => {
        address.code = address.publicID;
        address.name = address.displayName;
        return address;
    }

    const setAddressCodes = () => {
        let tempPrimaryAddress = _.get(vehicleVM, 'primaryLocation_WMIC.value', {}) || _.get(jobVM, 'baseData.accountAddresses_WMIC.value[0]', {});
        let tempGarageAddress = _.get(vehicleVM, 'garageLocation_WMIC.value', {}) || _.get(jobVM, 'baseData.accountAddresses_WMIC.value[0]', {});

        if (tempPrimaryAddress.code === undefined || tempPrimaryAddress.name === undefined) {
            tempPrimaryAddress = addCodeName(tempPrimaryAddress);
            setSelectedPrimaryAddress(tempPrimaryAddress);
        }

        if (tempGarageAddress.code === undefined || tempGarageAddress.name === undefined) {
            tempGarageAddress = addCodeName(tempGarageAddress);
            setSelectedGarageAddress(tempGarageAddress);
        }

        if (allPrimaryLocationAddresses.some((address) => address.code === undefined || address.name === undefined)) {
            const allPrimaryLocationAddressesDisplay = allPrimaryLocationAddresses.map((address) => {
                if (address.code === undefined || address.name === undefined) {
                    address = addCodeName(address);
                }
                return address;
            });
            setAllPrimaryLocationAddresses(allPrimaryLocationAddressesDisplay);
        }

        if (allGarageLocationAddresses.some((address) => address.code === undefined || address.name === undefined)) {
            const allGarageLocationAddressesDisplay = allGarageLocationAddresses.map((address) => {
                if (address.code === undefined || address.name === undefined) {
                    address = addCodeName(address);
                }
                return address;
            });
            setAllGarageLocationAddresses(allGarageLocationAddressesDisplay);
        }
    };

    const isCommercialPassengerVehicle = () => {
        return _.get(vehicleVM, 'businessSegment.value.code') === PAConstants.vehicleBusinessSegmentCommercial
            && _.get(vehicleVM, 'commercialVehicle_WMIC')
            && _.get(vehicleVM, 'vehicleType.value.code') === PAConstants.personalAutoVehicleType;
    };

    const isBusinessSegmentReadOnly = () => {
        return [PAConstants.motorhomeVehicleType, PAConstants.motorcycleVehicleType].includes(_.get(vehicleVM, 'vehicleType.value.code'))
    };

    const handleBusinessSegmentChange = (value, path) => {
        _.set(vehicleVM, path, value);

        if (isEditMode) {
            if (_revertedBusinessSegment) {
                _revertedBusinessSegment = false;
            } else {
                revalidateVIN().then((vehicleSearchErrors) => {
                    setBusinessSegmentErrorMsg(vehicleSearchErrors);

                    if (vehicleSearchErrors && vehicleSearchErrors?.length) {
                        _.set(vehicleVM, `${path}.value`, oldBusinessSegment);
                        _revertedBusinessSegment = true;
                    } else {
                        if (isCommercialPassengerVehicle() && _.get(vehicleVM, 'vinOverride_WMIC.value', false) === true) {
                            _.set(vehicleVM, 'bodyStyle_WMIC', undefined);
                        }

                        _.set(vehicleVM, 'trailerSubType_WMIC', undefined);
                        _.set(vehicleVM, 'autonomousBrakingDiscount_WMIC', undefined);

                        const customizations = _.get(vehicleVM, 'customizations_WMIC');
                        if (customizations.value && customizations.value.length >= 1) {
                            let i = customizations.value.length;
                            while (i--) {
                                if (!customizations.getElement(i).vehicleCustType_WMIC.value.belongsToCategory({
                                    code: value.code,
                                    typelist: { name: 'BusinessSegment_WMIC' }
                                })) {
                                    customizations.value.splice(i, 1);
                                }
                            }
                            _.set(vehicleVM, 'customizations', customizations);
                        }
                    }
                })
            }
            _.set(vehicleVM, 'rateReason_WMIC', undefined);
        }
        updateVehicle(vehicleVM);
    };

    const handleBusinessSegmentPostOnChange = () => {
        WMICVehicleUtil.resetAntiTheftFields(vehicleVM);
    };

    const getReselectVehicleMessage = (vehicleSearchErrors) => {
        return !vehicleSearchErrors?.length ?
            WMICRichTextUtil.translateRichText(translator(messages.reselectVehicle)) :
            translator(messages.businessSegmentCannotBeUpdated)
    }

    const handleClickAddNewPrimaryLocation = () => {
        setNewLocationFormVisible(true);
    }

    const handleClickAddRatedGarageLocation = () => {
        setNewRatedGarageFormVisible(true);
    }

    const handleCancelNewPrimaryLocation = () => {
        setNewPrimaryLocation(createAddressVM({ country: CONSTANTS.COUNTRY.CA }));
        setNewLocationFormVisible(false);
    }

    const handleCancelNewRatedGarageLocation = () => {
        setNewRatedGarageFormVisible(false);
    }

    const handleSaveNewPrimaryLocation = () => {
        const accountAddresses = _.get(jobVM, 'baseData.accountAddresses_WMIC', []);
        const disp = newPrimaryLocation;

        if (disp.value.code === undefined || disp.value.name === undefined) {
            disp.value.name = `${accountAddresses.length + 1}: ${WMICAddressDetailsUtil.formatAddress(disp)}`;
            disp.value.code = `${accountAddresses.length + 1}`;
        }
        accountAddresses.pushElement(disp);
        setAllPrimaryLocationAddresses(accountAddresses.value);
        // making sure we won't display the address in both dropdown fields and save it twice
        setAllGarageLocationAddresses(accountAddresses.value.filter((address) => address.code !== disp.value.code));

        setSelectedPrimaryAddress(disp.value);
        setNewLocationFormVisible(false);
        setNewPrimaryLocation(createAddressVM({ country: CONSTANTS.COUNTRY.CA }));

        _.set(jobVM, 'baseData.accountAddresses_WMIC', accountAddresses.value);
        updateWizardData(jobVM);

        _.set(vehicleVM, 'primaryLocation_WMIC', disp.value);

        if (_.get(vehicleVM, 'rateAtPrimaryLocation_WMIC.value')) {
            _.set(vehicleVM, 'ratingJurisdiction', disp.value.state);
        }
        updateVehicle(vehicleVM);
    }

    const handleSaveNewRatedGarageLocation = () => {
        const accountAddresses = _.get(jobVM, 'baseData.accountAddresses_WMIC', []);
        const disp = newRatedGarageLocation;

        if (disp.value.code === undefined || disp.value.name === undefined) {
            disp.value.name = `${accountAddresses.length + 1}: ${WMICAddressDetailsUtil.formatAddress(disp)}`;
            disp.value.code = `${accountAddresses.length + 1}`;
        }
        accountAddresses.pushElement(disp);
        setAllGarageLocationAddresses(accountAddresses.value);
        // making sure we won't display the address in both dropdown fields and save it twice
        setAllPrimaryLocationAddresses(accountAddresses.value.filter((address) => address.code !== disp.value.code));

        setSelectedGarageAddress(disp.value);
        setNewRatedGarageFormVisible(false);
        setNewRatedGarageLocation(createAddressVM({ country: CONSTANTS.COUNTRY.CA }));

        _.set(jobVM, 'baseData.accountAddresses_WMIC', accountAddresses.value);
        updateWizardData(jobVM);

        _.set(vehicleVM, 'garageLocation_WMIC', disp.value);
        _.set(vehicleVM, 'ratingJurisdiction', disp.value.state);
        updateVehicle(vehicleVM);
    }

    const handleChangePrimaryVehicleLocation = (value) => {
        const newSelectedAddress = allPrimaryLocationAddresses.find((address) => address.publicID === value);
        setSelectedPrimaryAddress(newSelectedAddress);

        _.set(vehicleVM, 'primaryLocation_WMIC', newSelectedAddress);
        if (_.get(vehicleVM, 'rateAtPrimaryLocation_WMIC.value')) {
            _.set(vehicleVM, 'ratingJurisdiction', newSelectedAddress.state);
        }
        updateVehicle(vehicleVM);
    }

    const handleChangeRatedGarageLocation = (value) => {
        const newSelectedAddress = allGarageLocationAddresses.find((address) => address.publicID === value);
        setSelectedGarageAddress(newSelectedAddress);

        _.set(vehicleVM, 'garageLocation_WMIC', newSelectedAddress);
        _.set(vehicleVM, 'ratingJurisdiction', newSelectedAddress.state);
        updateVehicle(vehicleVM);
    }

    const handleChangeRateAtPrimaryLocation = (value) => {
        const garageLocationState = _.get(vehicleVM, 'garageLocation_WMIC.value.state');
        const primaryLocationState = _.get(vehicleVM, 'primaryLocation_WMIC.value.state');

        _.set(vehicleVM, 'rateAtPrimaryLocation_WMIC', value);
        if (value) {
            _.set(vehicleVM, 'ratingJurisdiction', primaryLocationState);
        } else if (garageLocationState) {
            _.set(vehicleVM, 'ratingJurisdiction', garageLocationState);
        }
        updateVehicle(vehicleVM);
    }

    const getShareCompanies = () => {
        let rideSharingCompanyValues = _.get(vehicleVM, 'rideSharingCompany_WMIC.aspects.availableValues');
        if (!isGarageLocationOntario()) {
            rideSharingCompanyValues = _.filter(rideSharingCompanyValues, (company) => company.code !== RIDE_SHARING_COMPANIES.URIDE);
        }

        if (!isGarageLocationMaritime() && !isGarageLocationOntario()) {
            rideSharingCompanyValues = _.filter(rideSharingCompanyValues, (company) => company.code !== RIDE_SHARING_COMPANIES.LYFT);
        }

        if (!isGarageLocationPE()) {
            rideSharingCompanyValues = _.filter(rideSharingCompanyValues, (company) => company.code !== RIDE_SHARING_COMPANIES.REDRIDE_KARI);
        }

        setAvailableRideSharingCompanies(_.map(rideSharingCompanyValues, mapTypelistNames));
    }

    const vehiclePrimaryAddress = _.get(vehicleVM, 'primaryLocation_WMIC.value');
    const garageLocationAddress = _.get(vehicleVM, 'garageLocation_WMIC.value');

    const overrideProps = {
        '@field': {
            parentNode: vehicleVM,
            readOnly: !isEditMode
        },
        businessSegment: {
            readOnly: !isEditMode || isBusinessSegmentReadOnly(),
            onPostOnChange: handleBusinessSegmentPostOnChange
        },
        commercialVehicleComponentContainer: {
            visible: _.get(vehicleVM, 'commercialVehicle_WMIC.commercialVehicleContainer.aspects.ocular', false)
        },
        primaryUse: {
            availableValues: availablePrimaryUses,
            readOnly: !isEditMode || isPrimaryUseDisabled
        },
        rideSharingCompany: {
            availableValues: availableRideSharingCompanies
        },
        mileageContainer: {
            visible: _.get(vehicleVM, 'mileageContainer.aspects.ocular', false)
        },
        odometerReading: {
            visible: isVehicleTypeAutoMotorCyleOrMotorHome()
        },
        dateReadingTaken: {
            visible: isVehicleTypeAutoMotorCyleOrMotorHome()
        },
        rideSharingContainer: {
            visible: _.get(vehicleVM, 'rideSharingContainer.aspects.ocular', false)
        },
        motorHomeRiskContainer: {
            visible: _.get(vehicleVM, 'motorhomeRisksContainer.aspects.ocular', false)
        },
        addLocationBtnContainer: {
            visible: isEditMode
        },
        addLocationBtn: {
            disabled: isNewLocationFormVisible
        },
        primaryAddressDetailsContainer: {
            visible: isNewLocationFormVisible && isEditMode
        },
        primaryVehicleLocationDropdown: {
            availableValues: allPrimaryLocationAddresses.map((elem) => { return {
                code: elem.publicID,
                description: elem.displayName,
                ...elem
            }}),
            value: {
                code: vehiclePrimaryAddress?.publicID,
                description: vehiclePrimaryAddress?.displayName,
                ...vehiclePrimaryAddress
            },
            path: "primaryLocation_WMIC",
            onValueChange: handleChangePrimaryVehicleLocation,
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
            showRequired: true
        },
        primaryAddressDetailsComponent: {
            onValidate: setComponentValidation,
            addressVM: newPrimaryLocation,
            onCancel: handleCancelNewPrimaryLocation,
            onSave: handleSaveNewPrimaryLocation,
            showErrors,
            readOnlyCountry: true
        },
        addRatedGarageLocationBtnContainer: {
            visible: isEditMode && !_.get(vehicleVM, 'rateAtPrimaryLocation_WMIC.value', true)
        },
        addRatedGarageLocationBtn: {
            disabled: isNewRatedGarageFormVisible
        },
        ratedGarageAddressDetailsContainer: {
            visible: isNewRatedGarageFormVisible && isEditMode
        },
        ratedGarageLocationDropdown: {
            availableValues: allGarageLocationAddresses.map((elem) => { return {
                code: elem.publicID,
                description: elem.displayName,
                ...elem
            }}),
            value: {
                code: garageLocationAddress?.publicID,
                description: garageLocationAddress?.displayName,
                ...garageLocationAddress
            },
            path: "garageLocation_WMIC",
            onValueChange: handleChangeRatedGarageLocation,
            visible: !_.get(vehicleVM, 'rateAtPrimaryLocation_WMIC.value', true),
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
            showRequired: true
        },
        ratedGarageAddressDetailsComponent: {
            onValidate: setComponentValidation,
            addressVM: newRatedGarageLocation,
            onCancel: handleCancelNewRatedGarageLocation,
            onSave: handleSaveNewRatedGarageLocation,
            showErrors,
            readOnlyCountry: true
        },
        vehiclesUsageCommercial: {
            vehicleVM,
            commercialVM: _.get(vehicleVM, 'commercialVehicle_WMIC'),
            setCommercialVM,
            showErrors,
            onValidate: componentValidation,
            isEditMode,
            rateAsOfDate: _.get(jobVM, 'baseData.rateAsOfDate.value'),
            vehicles: _.get(jobVM, 'lobData.personalAuto.coverables.vehicles'),
            policyNumber: _.get(jobVM, 'baseData.policyAddress.value.state'),
            inceptionDate: _.get(jobVM, 'baseData.inceptionDate_WMIC.value'),
            periodStartDate: _.get(jobVM, 'baseData.periodStartDate.value')
        },
        reselectVehicleContainer: {
            visible: reselectVehicle
        },
        reselectVehicleMsg: {
            content: getReselectVehicleMessage(businessSegmentErrorMsg)
        },
        usedForCarSharingContainer: {
            visible: _.get(vehicleVM, 'showUsedForCarSharingContainer.aspects.ocular', false)
        }
    }

    const resolvers = {
        resolveComponentMap: {
            WMICEditForm,
            WMICAddressDetails,
            WMICVehiclesUsageCommercial
        },
        resolveCallbackMap: {
            onClickAddNewPrimaryLocation: handleClickAddNewPrimaryLocation,
            onClickAddRatedGarageLocation: handleClickAddRatedGarageLocation,
            onRateAtPrimaryLocation: handleChangeRateAtPrimaryLocation,
            onBusinessSegmentChange: handleBusinessSegmentChange
        },
        resolveClassNameMap: styles
    }

    return (
        <ViewModelForm
            model={vehicleVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onModelChange={updateVehicle}
            onValidationChange={setComponentValidation}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            showErrors={showErrors}
        />
    );

}

export default WMICVehiclesUsageComponent;
