import React, { Component } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { Loader } from '@jutro/components';
import { FormattedNumber } from 'react-intl';
import _ from 'lodash';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { AccountService } from 'gw-capability-gateway-account';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { CommissionAccountService } from 'gw-capability-gateway-commission';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import metadata from './AccountCommission.metadata.json5';
import styles from './AccountCommission.module.scss';
import messages from './AccountCommission.messages';

class AccountCommission extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string
            })
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        children: PropTypes.shape({}).isRequired,
        intl: PropTypes.func.isRequired
    };

    state = {
        commissionTransactions: '',
        commissionDataTable: '',
        commissionSummaryTable: '',
        selectedCommission: '',
        commissionTotal: 0,
        commissionSumTotal: 0,
        commissionSummaryData: [],
        commissionOptions: [],
        isLoading: true
    };

    componentDidMount() {
        this.retrieveAccountIfExists();
        const translator = this.context;
        this.setState({
            selectedCommission: translator(messages.commissionAllPoliciesAccount)
        });
    }

    retrieveAccountIfExists = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;
        const filter = {
            type: null,
            value: null
        };
        const responseFlag = await AccountService.accountExists(accountNumber, authHeader);
        if (responseFlag) {
            const summaryData = await CommissionAccountService.getPoliciesInForceByAccount(
                accountNumber,
                authHeader
            );
            const commissionData = await
            CommissionAccountService.getCommissionsTransactionsByAccount(
                accountNumber,
                authHeader
            );
            this.getCommissionDataTable(commissionData, filter);
            this.getSummaryDataTable(summaryData);
            this.setState({
                commissionTransactions: commissionData,
                commissionOptions: this.getCommissionOptions() || [],
                commissionSummaryData: summaryData,
                isLoading: false
            });
        }
    };

    getTransactionsFilterValues = (commissionArrayResult, filter) => {
        return _.filter(commissionArrayResult, (res) => {
            return res.policy === filter.value;
        });
    };

    getSearchFilterValues = (commissionArrayResult, filter) => {
        const lowerCaseFilterValue = filter.value.toLocaleLowerCase();
        return _.filter(commissionArrayResult, (res) => {
            return Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            );
        });
    };

    getSummaryDataTable = (summaryData) => {
        const summaryArrayResult = summaryData.map((summaryInfo) => {
            const summaryPolicy = `${summaryInfo.policy} — ${summaryInfo.productCode}`;
            const summaryProductcode = `${summaryInfo.producerCode}  ${summaryInfo.producerName}`;
            const commissionSummary = {
                commissionSummaryPolicy: summaryPolicy,
                commissionProductCode: summaryProductcode,
                commissionRole: summaryInfo.producerRole,
                commissionPaid: summaryInfo.paid,
                commissionNotPaid: summaryInfo.notPaid,
                commissionTotal: summaryInfo.totalCommission
            };
            return commissionSummary;
        });
        const commissionSumTotal = summaryArrayResult.reduce((commSet, commVal) => {
            return commSet + commVal.commissionTotal.amount;
        }, 0);

        this.setState({
            commissionSummaryTable: summaryArrayResult,
            commissionSumTotal
        });
    };

    getCommissionDataTable = (commissionData, filter) => {
        const { intl } = this.props;
        let commissionArrayResult = commissionData;
        switch (filter.type) {
            case 'transactionsFilter':
                commissionArrayResult = this.getTransactionsFilterValues(
                    commissionArrayResult,
                    filter
                );
                break;
            case null:
                commissionArrayResult = commissionData;
                break;
            default:
                commissionArrayResult = commissionData;
                break;
        }
        commissionArrayResult = commissionArrayResult.map((commissionInfo) => {
            const commissions = {
                commissionDate: intl.formatDate(new Date(commissionInfo.date), { year: 'numeric', month: 'short', day: 'numeric' }),
                commissionPolicy: commissionInfo.policy,
                commissionType: commissionInfo.type,
                commissionCharge: commissionInfo.charge,
                commissionBasis: commissionInfo.basis,
                commissionRate: commissionInfo.rate,
                commissionAmount: commissionInfo.amount
            };
            return commissions;
        });
        const commissionTotal = commissionArrayResult.reduce(
            (commSet, commVal) => commSet + commVal.commissionAmount.amount,
            0
        );

        this.setState({
            commissionDataTable: commissionArrayResult,
            commissionTotal
        });
    };

    getCommissionOptions = () => {
        const translator = this.context;
        const { commissionSummaryTable } = this.state;
        let commissionOptionsArray = [translator(messages.commissionAllPoliciesAccount)];
        if (_.isEmpty(commissionSummaryTable)) {
            return null;
        }
        commissionSummaryTable.map((commissionInfo) => {
            if (commissionOptionsArray.indexOf(commissionInfo.commissionSummaryPolicy) === -1) {
                commissionOptionsArray = [
                    ...commissionOptionsArray,
                    commissionInfo.commissionSummaryPolicy
                ];
            }
            return commissionOptionsArray;
        });
        return commissionOptionsArray.map((key) => {
            return {
                code: key,
                name: key
            };
        });
    };

    getPolicyLink = (item, index, property) => {
        const { commissionSummaryData } = this.state;
        return (
            <Link to={`/policies/${commissionSummaryData[index].policyNumber}/commission`}>
                {item[property.id]}
            </Link>
        );
    };

    handleFilterValueChange = (value, a, { id }) => {
        const { commissionTransactions } = this.state;
        const translator = this.context;
        this.setState({
            selectedCommission: value
        });
        const filter = {
            type: null,
            value: null
        };
        if (id === 'transactionsFilter') {
            if (value !== translator(messages.commissionAllPoliciesAccount)) {
                filter.type = 'transactionsFilter';
                // eslint-disable-next-line
                filter.value = value.split(' ')[0];
            }
            this.getCommissionDataTable(commissionTransactions, filter);
        }
    };

    handleFileClick = () => {
        const { history } = this.props;
        return history.push('/fnol-select-policy');
    };

    getCell = (items, index, property) => {
        return items[property.id];
    };

    getFormattedCurrency = (item, index, property) => {
        const currencyClasses = classNames({
            [styles.negative]: item[property.id].amount < 0
        });
        return (
            <CurrencyField
                id="currency"
                className={currencyClasses}
                value={item[property.id]}
                readOnly
                hideLabel
                showOptional={false}
            />
        );
    };

    getFormattedBasisCurrency = (item, index, property) => {
        return ((item[property.id].amount !== 0)
            ? (
                <CurrencyField
                    id={`basis_${index}`}
                    value={item[property.id]}
                    readOnly
                    hideLabel
                    showOptional={false}
                />
            ) : '—');
    };

    getLink = (item, index, property) => {
        return <Link to={`/commission/${item[property.id]}`}>{item[property.id]}</Link>;
    };

    getRate = (item, index, property) => {
        return !_.isUndefined(item[property.id]) ? (
            <FormattedNumber id="getRateInfo" value={item[property.id] / 100} style={['percent']} />
        ) : (
            '—'
        );
    };

    render() {
        const {
            commissionDataTable,
            commissionSummaryTable,
            selectedCommission,
            commissionOptions,
            commissionTotal,
            commissionSumTotal,
            isLoading
        } = this.state;
        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }
        const overrides = {
            commissionTableList: {
                data: commissionDataTable
            },
            commissionSummaryTableList: {
                data: commissionSummaryTable
            },
            transactionsFilter: {
                availableValues: commissionOptions,
                value: selectedCommission,
                onValueChange: this.handleFilterValueChange
            },
            gwTotalCommissionId: {
                value: commissionTotal
            },
            gwTotalSummaryCommissionId: {
                value: commissionSumTotal
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getFormattedCurrency: this.getFormattedCurrency,
                getAccount: this.getAccount,
                getPolicyLink: this.getPolicyLink,
                getRate: this.getRate,
                handleFilterValueChange: this.handleFilterValueChange,
                handleFileClick: this.handleFileClick,
                getFormattedBasisCurrency: this.getFormattedBasisCurrency
            }
        };
        const commissionPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
        return <div className={styles.summary}>{commissionPage}</div>;
    }
}

export const AccountCommissionComponent = AccountCommission;
export default withIntl(withRouter(withAuthenticationContext(AccountCommission)));
