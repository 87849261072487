/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, { useContext, useMemo, useState, Fragment, useCallback } from 'react';
import _ from 'lodash';
import { IntlContext, useTranslator } from '@jutro/locale';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS, WMICPageNavigationUtil } from 'wmic-portals-utils-js';
import WMICClickableIconTooltipComponent from 'gw-capability-policy-common-react/components/WMICClickableIconTooltipComponent/WMICClickableIconTooltipComponent';
import { WMICButton } from 'wmic-components-platform-react';
import metadata from './WMICPaymentInfoBox.metadata.json5';
import messages from './WMICPaymentInfoBox.messages';
import styles from './WMICPaymentInfoBox.module.scss';

const WMICPaymentInfoBox = (props) => {
    const { claimData: claimInfo } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const DOM_ELEMENT_IDS = {
        ADJUSTER_INFO: 'adjusterInfoBoxContainer',
        MOBILE_DROPDOWN_SELECT: 'paymentSortSelect',
        PAYMENT_HEADER_DIV: 'ww-claims-payment-header'
    };
    const SORTING_ATTRIBUTES = {
        DATE: 'createDate',
        PAY_TO: 'payTo',
        COVERAGE: 'coverage',
        AMOUNT: 'amount.amount'
    };
    const DROPDOWN_SORT_VALUES = [
        {
            sortingAttribute: SORTING_ATTRIBUTES.DATE,
            name: translator(messages.Date)
        },
        {
            sortingAttribute: SORTING_ATTRIBUTES.PAY_TO,
            name: translator(messages.PaidTo)
        },
        {
            sortingAttribute: SORTING_ATTRIBUTES.COVERAGE,
            name: translator(messages.Coverage)
        },
        {
            sortingAttribute: SORTING_ATTRIBUTES.AMOUNT,
            name: translator(messages.Amount)
        }
    ];

    const getPaymentDetails = (data) => {
        const paymentDetailsWithVoids = [];

        // eslint-disable-next-line no-unused-expressions
        data?.lobs?.personalAuto?.paymentDetails_WMIC.forEach((paymentDetail) => {
            
            if (paymentDetail.isInsured && paymentDetail.includedPayments.some((includedPayments) => includedPayments.status.toUpperCase() === CONSTANTS.INCLUDED_PAYMENT_STATUS.VOIDED.toUpperCase())) {
                const preVoidPaymentDetail = Object.assign({}, paymentDetail);

                preVoidPaymentDetail.includedPayments = preVoidPaymentDetail.includedPayments.filter((included) => !(included.status.toUpperCase() === CONSTANTS.INCLUDED_PAYMENT_STATUS.VOIDED.toUpperCase()));
                preVoidPaymentDetail.includedPayments = preVoidPaymentDetail.includedPayments.map((included) => {
                    included.amount.amount *= -1;

                    return included;
                });
                preVoidPaymentDetail.status = CONSTANTS.PAYMENT_DETAIL_STATUS.ISSUED;
                paymentDetailsWithVoids.push(preVoidPaymentDetail);

                const voidPaymentDetail = Object.assign({}, paymentDetail);

                voidPaymentDetail.createDate = voidPaymentDetail.issueDate;
                voidPaymentDetail.status = CONSTANTS.PAYMENT_DETAIL_STATUS.VOIDED;
                voidPaymentDetail.amount.amount *= -1;
                voidPaymentDetail.isInsured = 'false';
                voidPaymentDetail.includedPayments = voidPaymentDetail.includedPayments.filter((included) => included.status.toUpperCase() === CONSTANTS.INCLUDED_PAYMENT_STATUS.VOIDED.toUpperCase());
                voidPaymentDetail.includedPayments = voidPaymentDetail.includedPayments.map((included) => {
                    included.amount.amount *= -1;
                    included.createDate = voidPaymentDetail.issueDate;

                    return included;
                });
                paymentDetailsWithVoids.push(voidPaymentDetail);
            } else if (!paymentDetail.isInsured && paymentDetail.status === CONSTANTS.PAYMENT_DETAIL_STATUS.VOIDED) {

                const preVoidPaymentDetail = Object.assign({}, paymentDetail);

                preVoidPaymentDetail.status = CONSTANTS.PAYMENT_DETAIL_STATUS.ISSUED;
                paymentDetailsWithVoids.push(preVoidPaymentDetail);

                const voidPaymentDetail = Object.assign({}, paymentDetail);

                voidPaymentDetail.status = CONSTANTS.PAYMENT_DETAIL_STATUS.VOIDED;
                voidPaymentDetail.amount.amount *= -1;
                paymentDetailsWithVoids.push(voidPaymentDetail);
            } else {
                paymentDetailsWithVoids.push(paymentDetail);
            }
        });

        return paymentDetailsWithVoids.map((payment) => {
            if (payment.payTo === undefined) {
                payment.payTo = payment.accountName;
            }

            return payment;
        });
    };

    const [paymentDetails, setPaymentDetails] = useState(getPaymentDetails(claimInfo));

    const getPaymentHdExpanderIcon = () => (<i className="fa fa-fw fa-chevron-down" />);

    const formatMailAddress = (address) => {
        // eslint-disable-next-line eqeqeq
        if (address == undefined) return address;

        const splitAddress = address.split(',');
        
        return splitAddress[splitAddress.length - 1].replace(/\s+/g, '') === CONSTANTS.COUNTRY.US_LONG ? splitAddress.slice(0, -1).join(',') : address;
    };

    const getPaymentClaimHistoryWarning = useCallback(() => (
        <p>
            <i className="fa fa-fw fa-exclamation-circle ww-icon-warning" />
            { translator(messages.ThePaymentDisplayedMayNotBeAccuratePlease) }
            <a onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, DOM_ELEMENT_IDS.ADJUSTER_INFO)} to="/">
                { translator(messages.ContactYourAdjuster) }
            </a>
            { translator(messages.ForMoreInformation) }
        </p>
    ), [DOM_ELEMENT_IDS.ADJUSTER_INFO, translator]);

    const getPaymentVoidedNotice = useCallback((payment) => (
        <div>
            <strong>
                { translator(messages.YourCheckWasVoidedOn) }
            </strong>
            { intl.formatDate(new Date(payment?.includedPayments[0]?.createDate), { year: 'numeric', month: 'long', day: 'numeric' }) }
        </div>
    ), [intl, translator]);

    const getPaymentDepositedNotice = useCallback((payment) => (
        <div>
            <strong>
                { translator(messages.YourPaymentWasDepositedOn) }
            </strong>
            { intl.formatDate(new Date(payment.issueDate), { year: 'numeric', month: 'long', day: 'numeric' }) }
        </div>
    ), [intl, translator]);

    const getPaymentIssuedNotice = useCallback((payment) => (
        <div>
            <strong>
                { translator(messages.YourCheckWasMaileddOn) }
            </strong>
            { intl.formatDate(new Date(payment.issueDate), { year: 'numeric', month: 'long', day: 'numeric' }) }
        </div>
    ), [intl, translator]);

    const getPaymentCashedNotice = useCallback((payment) => (
        <div>
            <strong>
                { translator(messages.YourCheckWasDepositedOn) }
            </strong>
            { intl.formatDate(new Date(payment.issueDate), { year: 'numeric', month: 'long', day: 'numeric' }) }
        </div>
    ), [intl, translator]);

    const getPaymentMailTo = useCallback((payment) => (
            <p className="ww-card-details">
                <strong>
                    { translator(messages.MailingAddress) }
                </strong>
                <br />
                { payment.mailTo }
                <br />
                { formatMailAddress(payment.mailAddress) }
            </p>
        ), [translator]);

    const getPaymentPropertyDisplay = (message, value) => (
            <p className="ww-card-details">
                <strong>
                    { message }
                </strong>
                <br />
                { value }
            </p>
        );

    const getBreakdownPayments = useCallback((payment, index) => {
        const includedPaymentList = [];

        _.each(payment.includedPayments, (includedPayment, index2) => {
            includedPaymentList.push(
                (
                    <Fragment>
                        <span className="sr-only">{translator(messages.PaymentBreakdownAria, {coverage:  includedPayment?.coverage, amount: includedPayment?.amount?.amount})}</span>
                        <div aria-hidden="true">
                            { includedPayment?.coverage }
                        </div>
                        <div className={payment?.status === CONSTANTS.PAYMENT_DETAIL_STATUS.VOIDED ? ' ww-claims-payment-refund' : ''} aria-hidden="true">
                            <CurrencyField
                                id={`breakdownAmount${index}_${index2}`}
                                value={includedPayment?.amount}
                                dataType="object"
                                readOnly
                                hideLabel
                            />
                        </div>
                    </Fragment>
                )
            );
        });
        
        return includedPaymentList;
    }, [translator]);

    const togglePaymentDetails = (payments, index) => {
        const targetElement = document.getElementById(`paymentInfoBoxDetails${index}`);
        const isExpanded = targetElement?.classList?.contains('expanded');

        _.each(payments, (p, i) => {
            const element = document.getElementById(`paymentInfoBoxDetails${i}`);

            if (element) {
                element.classList.remove('expanded');
            }
        });

        if (targetElement) {
            if (isExpanded) {
                targetElement.classList.remove('expanded');
            } else {
                targetElement.classList.add('expanded');
            }
        }
    };

    const sortBy = (currentTarget, array, attr) => {
        togglePaymentDetails(paymentDetails, undefined); // close all payment details

        _.each(currentTarget.parentElement.querySelectorAll('i'), (e) => {
            e.style.display = 'none';
        });


        const targetIElement = currentTarget.querySelectorAll('i');

        if (targetIElement?.length === 0) {
            const i = document.createElement('i');
            
            i.className = 'fa fa-fw';
            currentTarget.appendChild(i);
        }

        currentTarget.querySelectorAll('i')[0].style.display = 'inline-block';

        const expanderClass = currentTarget.querySelectorAll('i')[0]?.classList;

        if (expanderClass.contains('fa-sort-up')) {
            expanderClass.remove('fa-sort-up');
            expanderClass.add('fa-sort-down');
            setPaymentDetails(_.sortBy(array, attr).reverse());
        } else {
            expanderClass.remove('fa-sort-down');
            expanderClass.add('fa-sort-up');
            setPaymentDetails(_.sortBy(array, attr));
        }
    };

    const getMobileSortForm = () => {
        const getSelectOptions = () => {
            const res = [];

            _.each(DROPDOWN_SORT_VALUES, (option) => {
                res.push(
                    <option label={option.name}>{option.name}</option>
                );
            });

            return res;
        };

        const mobileSortAction = () => {
            const sortingAttributeDisplayName = document.getElementById(DOM_ELEMENT_IDS.MOBILE_DROPDOWN_SELECT).value;
            const sortingAttributeCode = _.find(DROPDOWN_SORT_VALUES, (val) => val.name === sortingAttributeDisplayName).sortingAttribute;
            const targetHeaderElement = _.find(document.getElementsByClassName(DOM_ELEMENT_IDS.PAYMENT_HEADER_DIV)[0]?.children, (e) => e.innerText === sortingAttributeDisplayName);
            
            sortBy(targetHeaderElement, paymentDetails, sortingAttributeCode);
        };

        return (
            <Fragment>
                <label className="wmic-claims-payment-sort-label" htmlFor={DOM_ELEMENT_IDS.MOBILE_DROPDOWN_SELECT}>{ translator(messages.SortBy) }</label>
                <select className="ww-claims-payment-sort-menu" name="paymentDetailsSortByChoice" id={DOM_ELEMENT_IDS.MOBILE_DROPDOWN_SELECT}>
                    { getSelectOptions() }
                </select>
                <WMICButton onClick={mobileSortAction} type="secondary" tabIndex="0">
                    { translator(messages.Sort) }
                </WMICButton>
            </Fragment>
        );
    };

    const getValueWithMobileHeader = (mobileheader, value) => (
            <Fragment>
                <strong>
                    { `${mobileheader}:` }
                    &nbsp;
                </strong>
                { value }
            </Fragment>
        );

    const getAmount = (payment, index) => (
            <CurrencyField
                id={`amount${index}`}
                value={payment?.amount}
                dataType="object"
                readOnly
                hideLabel
                className={styles['wmic-currency-payment-value']}
            />
        )

    const getScreenReaderMessage = useCallback((payment) => {
        let message = '';

        message += ` ${  translator(messages.PaymentAria, {
            amount: payment?.amount?.amount,
            date: intl.formatDate(new Date(payment?.createDate), { year: 'numeric', month: '2-digit', day: '2-digit' }),
            paidTo: payment?.payTo,
            coverage: payment?.coverage === CONSTANTS.MULTIPLE ? translator(messages.Multiple) : payment?.coverage
        })}`;

        if (payment.isRecodedLossAndExpense) {
            message += ` ${  translator(messages.ThePaymentDisplayedMayNotBeAccuratePleaseAria)}`;
        }

        return message;
        
    }, [intl, translator]);

    const getScreenReaderExpanderMessage = useCallback((payment) => {
        let message = '';

        // payment Voided Notice
        if (payment && payment.includedPayments && payment?.status.toUpperCase() === CONSTANTS.PAYMENT_DETAIL_STATUS.VOIDED.toUpperCase()) {
            message += ` ${  payment && payment.includedPayments ? translator(messages.YourCheckWasVoidedOnAria, {date: intl.formatDate(new Date(payment?.includedPayments[0]?.createDate), { year: 'numeric', month: 'long', day: 'numeric' }) }) : null}`;
        }

        // payment Deposited Notice
        if (payment?.status.toUpperCase() === CONSTANTS.PAYMENT_DETAIL_STATUS.DEPOSITED.toUpperCase() && payment?.isEFTPayment && payment?.issueDate) {
            message += ` ${  translator(messages.YourPaymentWasDepositedOnAria, {date: intl.formatDate(new Date(payment?.issueDate), { year: 'numeric', month: 'long', day: 'numeric' }) })}`;
        }

        // payment Issued Notice
        if (payment?.status.toUpperCase() === CONSTANTS.PAYMENT_DETAIL_STATUS.ISSUED.toUpperCase() && !payment?.isEFTPayment && payment?.issueDate) {
            message += ` ${  translator(messages.YourCheckWasMaileddOnAria, {date: intl.formatDate(new Date(payment?.issueDate), { year: 'numeric', month: 'long', day: 'numeric' }) })}`;
        }

        // payment Cashed Notice
        if (payment?.status.toUpperCase() === CONSTANTS.PAYMENT_DETAIL_STATUS.CASHED.toUpperCase() && !payment?.isEFTPayment && payment?.issueDate) {
            message += ` ${  translator(messages.YourCheckWasDepositedOnAria, {date: intl.formatDate(new Date(payment?.issueDate), { year: 'numeric', month: 'long', day: 'numeric' }) })}`;
        }

        // payment MailTo
        if (payment?.mailTo) {
            message += ` ${  translator(messages.MailingAddressAria, {mailTo: payment?.mailTo, address: formatMailAddress(payment.mailAddress) })}`;
        }

        // payment Check Number
        if (payment?.checkNumber) {
            message += ` ${  translator(messages.CheckNumberAria, {checkNumber: payment.checkNumber})}`;
        }

        // payment Account Name
        if (payment?.accountName) {
            message += ` ${  translator(messages.AccountNameAria, {accountName: payment.accountName})}`;
        }

        // payment Bank Name
        if (payment?.bankName) {
            message += ` ${  translator(messages.BankNameAria, {bankName: payment.bankName})}`;
        }

        // payment Account Number
        if (payment?.accountNumber) {
            message += ` ${  translator(messages.AccountNumberAria, {accountNumber: payment.accountNumber})}`;
        }

        // payment Reference ID
        if (payment?.referenceID) {
            message += ` ${  translator(messages.ReferenceIDAria, {referenceID: payment.referenceID})}`;
        }

        // payment Status
        if (payment?.status) {
            message += ` ${  translator(messages.StatusAria, {status: payment.status})}`;
        }

        return message;
        
    }, [intl, translator]);
    
    const iterableOverrides = useMemo(() => {
        const overrides = paymentDetails.map((payment, index) => ({
                [`paymentInfoBoxDetails${index}`]: {
                    className: payment?.isInsured ? ' ww-claims-payment-details ww-claims-payment-expandable' : ' ww-claims-payment-details'
                },
                [`paymentDetailsCollapseHeader${index}`]: {
                    onClick: () => { togglePaymentDetails(paymentDetails, index); }
                },
                [`paymentDate${index}`]: {
                    content: getValueWithMobileHeader(translator(messages.Date), intl.formatDate(new Date(payment?.createDate), { year: 'numeric', month: '2-digit', day: '2-digit' }))
                },
                [`paymentPayee${index}`]: {
                    content: getValueWithMobileHeader(translator(messages.PaidTo), payment?.payTo)
                },
                [`paymentCoverage${index}`]: {
                    content: getValueWithMobileHeader(translator(messages.Coverage), payment?.coverage === CONSTANTS.MULTIPLE ? translator(messages.Multiple) : payment?.coverage)
                },
                [`paymentAmount${index}`]: {
                    content: getValueWithMobileHeader(translator(messages.Amount), getAmount(payment, index)),
                    className: payment?.status === CONSTANTS.PAYMENT_DETAIL_STATUS.VOIDED ? 'ww-claims-payment-amount ww-claims-payment-refund' : 'ww-claims-payment-amount'
                },
                [`paymentHdExpander${index}`]: {
                    visible: !!payment.isInsured,
                    content: getPaymentHdExpanderIcon()
                },
                [`paymentClaimHistoryWarning${index}`]: {
                    visible: !!payment.isRecodedLossAndExpense,
                    content: getPaymentClaimHistoryWarning()
                },
                [`paymentCardExpanderContent${index}`]: {
                    visible: !!payment.isInsured
                },
                [`paymentVoidedNotice${index}`]: {
                    visible: payment && payment.includedPayments && payment?.status.toUpperCase() === CONSTANTS.PAYMENT_DETAIL_STATUS.VOIDED.toUpperCase(),
                    content: payment && payment.includedPayments ? getPaymentVoidedNotice(payment) : null
                },
                [`paymentDepositedNotice${index}`]: {
                    visible: payment?.status.toUpperCase() === CONSTANTS.PAYMENT_DETAIL_STATUS.DEPOSITED.toUpperCase() && payment?.isEFTPayment && payment?.issueDate,
                    content: getPaymentDepositedNotice(payment)
                },
                [`paymentIssuedNotice${index}`]: {
                    visible: payment?.status.toUpperCase() === CONSTANTS.PAYMENT_DETAIL_STATUS.ISSUED.toUpperCase() && !payment?.isEFTPayment && payment?.issueDate,
                    content: getPaymentIssuedNotice(payment)
                },
                [`paymentCashedNotice${index}`]: {
                    visible: payment?.status.toUpperCase() === CONSTANTS.PAYMENT_DETAIL_STATUS.CASHED.toUpperCase() && !payment?.isEFTPayment && payment?.issueDate,
                    content: getPaymentCashedNotice(payment)
                },
                [`paymentMailTo${index}`]: {
                    visible: !!payment.mailTo,
                    content: getPaymentMailTo(payment)
                },
                [`paymentCheckNumber${index}`]: {
                    visible: !!payment.checkNumber,
                    content: getPaymentPropertyDisplay(translator(messages.CheckNumber), payment.checkNumber)
                },
                [`paymentAccountName${index}`]: {
                    visible: !!payment.accountName,
                    content: getPaymentPropertyDisplay(translator(messages.AccountName), payment.accountName)
                },
                [`paymentBankName${index}`]: {
                    visible: !!payment.bankName,
                    content: getPaymentPropertyDisplay(translator(messages.BankName), payment.bankName)
                },
                [`paymentAccountNumber${index}`]: {
                    visible: !!payment.accountNumber,
                    content: getPaymentPropertyDisplay(translator(messages.AccountNumber), payment.accountNumber)
                },
                [`paymentReferenceID${index}`]: {
                    visible: !!payment.referenceID,
                    content: getPaymentPropertyDisplay(translator(messages.ReferenceID), payment.referenceID)
                },
                [`paymentStatus${index}`]: {
                    visible: !!payment.status,
                    content: getPaymentPropertyDisplay(translator(messages.Status), payment.status)
                },
                [`paymentBreakdownContent${index}`]: {
                    visible: payment?.includedPayments?.length > 1 && !payment.isRecodedLossAndExpense
                },
                [`paymentBreakdownTooltip${index}`]: {
                    tooltipMessage: translator(messages.PaymentBreakdownTooltip)
                },
                [`paymentBreakdownList${index}`]: {
                    content: getBreakdownPayments(payment, index)
                },
                [`paymentAria${index}`]: {
                    content: getScreenReaderMessage(payment)
                },
                [`paymentCardExpanderAria${index}`]: {
                    content: getScreenReaderExpanderMessage(payment)
                }
            }));

        return Object.assign({}, ...overrides);
    }, [getBreakdownPayments, getPaymentCashedNotice, getPaymentClaimHistoryWarning, getPaymentDepositedNotice, getPaymentIssuedNotice, getPaymentMailTo, getPaymentVoidedNotice, getScreenReaderExpanderMessage, getScreenReaderMessage, intl, paymentDetails, translator]);

    const overrides = {
        paymentInfoBoxClaimsPaymentHdDate: {
            onClick: (event) => { sortBy(event.currentTarget, paymentDetails, SORTING_ATTRIBUTES.DATE); }
        },
        paymentInfoBoxClaimsPaymentHdPayee: {
            onClick: (event) => { sortBy(event.currentTarget, paymentDetails, SORTING_ATTRIBUTES.PAY_TO); }
        },
        paymentInfoBoxClaimsPaymentHdCoverage: {
            onClick: (event) => { sortBy(event.currentTarget, paymentDetails, SORTING_ATTRIBUTES.COVERAGE); }
        },
        paymentInfoBoxClaimsPaymentHdAmount: {
            onClick: (event) => { sortBy(event.currentTarget, paymentDetails, SORTING_ATTRIBUTES.AMOUNT); }
        },
        paymentInfoBoxIterable: {
            data: paymentDetails
        },
        paymentInfoBoxClaimsPaymentSort: {
            content: getMobileSortForm()
        },
        ...iterableOverrides
    };

    const resolvers = {
        resolveComponentMap: {
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={claimInfo}
        />
    );
};

WMICPaymentInfoBox.propTypes = {
    claimData: PropTypes.shape({})
};

WMICPaymentInfoBox.defaultProps = {
    claimData: {}
};

export default WMICPaymentInfoBox;
