import React from 'react';
import { Button } from '@jutro/components';
import PropTypes from "prop-types";
import cx from 'classnames';

import './WMICAnimatedLoaderButton.scss';
import {
    BUTTON_DEFAULT_TYPE,
    BUTTON_ALLOWED_TYPES,
    BUTTON_FILLED_TYPES,
    BUTTON_BASE_CLASSNAME,
    BUTTON_FILLED,
    BUTTON_OUTLINED
} from '../constants';

const WMICAnimatedLoaderButton = (props) => {
    const {
        type: passedType = BUTTON_DEFAULT_TYPE, className: passedClassName, ...otherProps
    } = props;

    return (
        props.isAnimated ?
        (<button type="button" className="jut__Button__button jut__Button__filled ww-btn ww-btn-outlined mt-4" id="submit">
            <span style={{display: "inline-flex", alignItems: "center", justifyContent: "center"}}>
                <span>
                    <div className="app__WMICLoader__wmicLoadContainer">
                        <div className="ww-loader">
                            <div className="app__WMICLoader__wmicProgress">
                                <div className="app__WMICLoader__wmicProgressBounce"></div>
                            </div>
                        </div>
                    </div>
                </span>
            </span>
        </button>) :
        <Button
            {...otherProps}
            type={BUTTON_FILLED_TYPES.includes(passedType) ? BUTTON_FILLED : BUTTON_OUTLINED }
            className={cx(`${BUTTON_BASE_CLASSNAME} ${BUTTON_BASE_CLASSNAME}-${passedType}`, passedClassName)}
        />
    );
};

WMICAnimatedLoaderButton.propTypes = {
    type: PropTypes.oneOf(BUTTON_ALLOWED_TYPES),
    isAnimated: PropTypes.bool
};

export default WMICAnimatedLoaderButton;
