import { defineMessages } from 'react-intl';

export default defineMessages({
    annualMileageToggleYes: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.annualMileageToggleYes',
        defaultMessage: 'Yes'
    },
    annualMileageToggleNo: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.annualMileageToggleNo',
        defaultMessage: 'No'
    },
    removeVehicleAria: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Remove Vehicle Aria',
        defaultMessage: 'Remove Vehicle.'
    },
    cancel: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Cancel',
        defaultMessage: 'Cancel'
    },
    cancelAriaMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.cancelAriaMessage',
        defaultMessage: 'Cancel remove vehicle'
    },
    back: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Back',
        defaultMessage: 'Back'
    },
    backAriaMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.backAriaMessage',
        defaultMessage: 'Back to previous step'
    },
    continue: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Continue',
        defaultMessage: 'Continue'
    },
    indicateTheReasonMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Indicate the reason for the deletion of your',
        defaultMessage: 'Indicate the reason for the removal of your &lt;strong&gt;{vehicleName}&lt;/strong&gt;'
    },
    reasonForDeletionLabelWithWarningAria: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Reason of removal with warning aria',
        defaultMessage: 'Error: Please select the reason for removing your vehicle.'
    },
    reasonForDeletionLabelAria: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Reason of removal aria',
        defaultMessage: 'Reason of removal'
    },
    reasonForDeletionLabel: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Reason of removal',
        defaultMessage: 'Reason of removal'
    },
    pleaseSelectTheReasonForRemoval: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Please select the reason for removal of your vehicle.',
        defaultMessage: 'Please select the reason for removal of your vehicle.'
    },
    effectiveDateMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.When did you sell or gift your',
        defaultMessage: 'When did you sell or gift your &lt;strong&gt;{vehicleName}&lt;/strong&gt;'
    },
    effectiveDateLabel: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Select an Effective Date',
        defaultMessage: 'Select an effective date'
    },
    usingVehicleOver12000MilesMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.We have prefilled the use and annual mileage from our records for your. Please review the form below to make sure it is accurate.',
        defaultMessage: 'We have prefilled the use and annual mileage from our records for your &lt;strong&gt;{vehicleName}&lt;/strong&gt;. Please review the form below to make sure it is accurate.'
    },
    usingVehicleUnder12000MilesMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.We have prefilled the primary use from our records for your. Please review the form below to make sure it is accurate.',
        defaultMessage: 'We have prefilled the primary use from our records for your &lt;strong&gt;{vehicleName}&lt;/strong&gt;. Please review the form below to make sure it is accurate.'
    },
    annualMileageUpdatedTo12000Miles: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.AnnualMileageUpdatedTo12000Miles',
        defaultMessage: 'The annual mileage will be updated to 12,000 for your &lt;strong&gt;{vehicleName}&lt;/strong&gt;.'
    },
    pleaseSelectAnnualMileageUnder12000MilesToggle: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Please make a selection regarding annual mileage to proceed.',
        defaultMessage: 'Please make a selection regarding annual mileage to proceed.'
    },
    primaryUseVehicleLabel: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.What do you primarily use your car to do',
        defaultMessage: 'What do you primarily use your car to do?'
    },
    annualMileageOverLimitAria: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.annualMileageOverLimitAria',
        defaultMessage: 'Total annual mileage of your {vehicleName}. Annual mileage cannot exceed 99999 miles.'
    },
    annualMileageOverLimit: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.annualMileageOverLimit',
        defaultMessage: 'Annual mileage cannot exceed 99,999 miles.'
    },
    primaryUseVehicleLabelAria: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.primaryUseVehicleLabelAria',
        defaultMessage: 'What do you primarily use your {vehicleName} to do?'
    },
    annualMileageLabel: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Total annual mileage of your',
        defaultMessage: 'Total annual mileage of your  &lt;strong&gt;{vehicleName}&lt;/strong&gt;'
    },
    annualMileageFromRecordLabel: {
        id: 'wmic.policy-change.component.annualMileageFromRecordLabel',
        defaultMessage: 'We have prefilled the annual mileage from our records for your  &lt;strong&gt;{vehicleName}&lt;/strong&gt;. Please review for accuracy.'
    },
    annualMileageFromRecordAriaLabel: {
        id: 'wmic.policy-change.component.annualMileageFromRecordAriaLabel',
        defaultMessage: 'We have prefilled the annual mileage from our records for your {vehicleName}. Please review for accuracy. Annual mileage cannot exceed 99,999 miles.'
    },
    annualMileageUnder12000Toggle: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Total annual mileage over 12000 mileages toggle',
        defaultMessage: 'The average person is driving about 12,000 miles per year. Does this sound right for your &lt;strong&gt;{vehicleName}&lt;/strong&gt;?'
    },
    annualMileageUnder12000ToggleAria: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.annualMileageUnder12000ToggleAria',
        defaultMessage: 'The average person is driving about 12,000 miles per year. Does this sound right for your {vehicleName}?'
    },
    annualMileageUnder12000ToggleWarningAria: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.annualMileageUnder12000ToggleWarningAria',
        defaultMessage: 'Error: Please make a selection regarding annual mileage to proceed. The average person is driving about 12,000 miles per year. Does this sound right for your {vehicleName}?'
    },
    toggleYesAnnualMileageAbout12000Aria: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.yesApplyAnnualMileageAria',
        defaultMessage: 'Yes, {vehicleName} is driven about 12,000 miles.'
    },
    toggleNoAnnualMileageAbout12000Aria: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.noDoNotApplyAnnualMileageAria',
        defaultMessage: 'No, {vehicleName} is driven less than 12,000 miles.'
    },
    thanksWeAreAlmostThere: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Thanks, we are almost there',
        defaultMessage: 'Thanks, we\'re almost there'
    },
    youNeedToConfirmRemainingVehicle: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.youNeedToConfirmRemainingVehicle',
        defaultMessage: 'To complete this policy change, you\'ll need to confirm details about the remaining vehicles and drivers on your policy.'
    },
    assignPrimaryDriversMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Use the form below to assign primary drivers for each vehicle',
        defaultMessage: 'Use the form below to  &lt;strong&gt;assign primary drivers&lt;/strong&gt; for each vehicle.' 
    },
    assignPrimaryDriversToTheVehicleToolTip: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Assign primary drivers to the vehicle',
        defaultMessage: 'Assign primary drivers to the vehicle they drive most often. Note that the primary driver for each vehicle must be unique.'
    },
    assignDriversCallMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.To add or remove a driver from your policy..',
        defaultMessage: 'To add or remove a driver from your policy, please call us during normal business hours at &lt;a aria-label="contact us by phone at the number 800-640-2920" href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt;.' 
    },
    assignDriversPleaseSelect: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.assignDriversPleaseSelect',
        defaultMessage: 'Please select one'
    },
    assignDriversValidationAriaWarningMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.assignDriversValidationAriaWarningMessage',
        defaultMessage: 'Error: Please assign a primary driver to {vehicle}.'
    },
    assignDriversValidationMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.assignDriversValidationMessage',
        defaultMessage: 'Please assign a primary driver to this vehicle.'
    },
    assignVehicleOccasionalDriverAriaWarningMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.assignVehicleOccasionalDriverAriaWarningMessage',
        defaultMessage: 'Error: Please assign a vehicle to the occasional driver {drivername}.'
    },
    assignVehicleOccasionalDriverValidationMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.assignVehicleOccasionalDriverValidationMessage',
        defaultMessage: 'Please assign a vehicle to this occasional driver.'
    },
    use: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Use',
        defaultMessage: 'Use:' 
    },
    annualMileage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Annual Mileage',
        defaultMessage: 'Annual Mileage:' 
    },
    assignOccasionalDriversMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Use the form below to assign occasional drivers for each vehicle.',
        defaultMessage: 'Use the form below to &lt;strong&gt;assign occasional drivers&lt;/strong&gt; for each vehicle.' 
    },
    assignOccasionalDriversToTheVehicleToolTip: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.A single vehicle may have more than one',
        defaultMessage: 'A single vehicle may have more than one occasional driver assigned to it. An occasional driver, drives the vehicle from time to time, but is not the primary driver.'
    },
    pleaseCallUsToComplete: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Please call us to complete this change',
        defaultMessage: 'Please call us to complete this change' 
    },
    isInPolicyRangeErrorMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.The effective date should be in the policy range...',
        defaultMessage: 'The effective date of {date} for this policy change is not within your current policy term of {effective} to {expiration}.' 
    },
    isDiffInRangeErrorMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.The effective date...',
        defaultMessage: 'The effective date of {date} is more than 30 days in the past and the vehicle cannot be removed online.'
    },
    dateInFutureErrorMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.The effective date is in the future...',
        defaultMessage: 'The effective date of {date} is in the future and this vehicle cannot be removed online.'
    },
    yourPolicyIsScheduledToRenewOnErrorMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Your policy is scheduled to renew on...',
        defaultMessage: 'Your policy is scheduled to renew on {date} and changes cannot be made to your policy online at this time.'
    },
    hasFutureTransactionErrorMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.There is already a pending request to make changes...',
        defaultMessage: 'There is already a pending request to make changes to this policy and a new one cannot be processed online.'
    },
    isMileageReducedMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Your request to lower the annual mileage on your remaining vehicle(s) requires additional review.',
        defaultMessage: 'Your request to lower the annual mileage on your remaining vehicle(s) requires additional review.'
    },
    pleaseCallUsToCompleteRequestMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Please call us at to complete this request...',
        defaultMessage: 'Please call us at &lt;a aria-label="contact us by phone at the number 800-640-2920" href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt; to complete this request. Our customer service team is available Monday to Friday 7:30 am – 7:30 pm PT and Saturday 8:00 am – 4:30 pm PT.'
    },
    correctTheFollowing: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Please correct the following',
        defaultMessage: 'Please correct the following'
    },
    multipleErrors: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.There are multiple errors on your requested change. Please review the errors to continue removing your vehicle.',
        defaultMessage: 'There are multiple errors on your requested change. Please review the errors to continue removing your vehicle.'
    },
    annualMileageRating: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.The vehicle you are removing had an annual mileage',
        defaultMessage: 'The vehicle you\'re removing had an annual mileage of {annualMileage} miles. To continue one of your remaining vehicles must be set to &lt;strong&gt;{annualMileage} annual miles or greater.&lt;/strong&gt;'
    },
    changeToBusiness: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.The removed vehicle was being used for business. To continue one of your remaining vehicles must be set to business use.',
        defaultMessage: 'The removed vehicle was being used for business. To continue one of your remaining vehicles must be set to business use.'
    },
    indicatedChange: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.You indicated a change of use to your vehicle',
        defaultMessage: 'You indicated a change of use to your &lt;strong&gt;{vehicleName} from {existingUsage} to {futureUsage}&lt;/strong&gt;.'
    },
    vehicleUseChangeAssistantPhoneNumber: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Vehicle Use Change Assitant Phone Number, Please Call',
        defaultMessage: 'If you need to change the use of your vehicle, please call us at &lt;a aria-label="contact us by phone at the number 800-640-2920" href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt;, our customer service team is available Monday to Friday 7:30 am - 7:30 pm PT and Saturday 8:00 am - 4:30 pm PT. '
    },
    assistantPhoneNumber: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Assitant Phone Number, Please Call',
        defaultMessage: 'If you need assistance with making this policy change, please call us at &lt;a aria-label="contact us by phone at the number 800-640-2920" href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt;. Our customer service team is available Monday to Friday 7:30 am - 7:30 pm PT and Saturday 8:00 am - 4:30 pm PT. '
    },
    averageMileage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.The average mileage on one of your remaining vehicles',
        defaultMessage: 'The {vehicleName} you\'re removing has an annual mileage amount of {annualMileage} miles. The &lt;span&gt;average mileage&lt;/span&gt; on one of your remaining vehicles  &lt;span&gt;must be equal to or greater than {annualMileage} miles &lt;/span&gt; to continue.'
    },
    removingCarUsedForBusiness: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.The car you are removing was being used for business',
        defaultMessage: 'The &lt;span&gt;{vehicleName}&lt;/span&gt; you\'re removing has an annual mileage amount of {annualMileage} miles and it was being used for &lt;span&gt;business&lt;/span&gt;. The &lt;span&gt;average mileage &lt;/span&gt; on one of your remaining vehicles &lt;span&gt;must be equal to or greater than {annualMileage} miles &lt;/span&gt; to continue and one of your remaining vehicles must be set to &lt;span&gt;business&lt;/span&gt; use.'
    },
    selectVehicleMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Select which vehicle you d like to edit',
        defaultMessage: 'Select which vehicle you\'d like to edit'
    },
    editVehicle: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Edit Vehicle',
        defaultMessage: 'Edit Vehicle'
    },
    editVehicleButtonAriaMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Edit Vehicle Button Aria Message',
        defaultMessage: 'Edit Vehicle {vehicleName} used for {vehicleUseage} with {annualMileage} miles.'
    },
    reviewDriverAssignments: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Review driver assignments',
        defaultMessage: 'Review driver assignments'
    },
    allExistingDriversMustBeAssigned: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.All existing drivers must be assigned to at least 1 vehicle.',
        defaultMessage: 'All existing drivers must be assigned to at least 1 vehicle.'
    },
    existingVehicleAssignedUniquePrimaryDriver: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Each vehicle on this policy must have a unique primary driver.',
        defaultMessage: 'Each vehicle on this policy must have a unique primary driver.'
    },
    somethingWentWrong: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Something went wrong',
        defaultMessage: 'Sorry, we are unable to remove your vehicle at this time. '
    },
    pleaseCallUsForAssistanceMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Please call us for assistance...',
        defaultMessage: 'Please call us for assistance to make changes to this policy at &lt;a aria-label="contact us by phone at the number 800-640-2920" href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt;. Our customer service team is available Monday to Friday 7:30 am – 7:30 pm PT and Saturday 8:00 am – 4:30 pm PT.'
    },
    paInsuredOrSpouseRegOwnerForVehicle: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.We are unable to complete your delete vehicle request because at least one vehicle must be registered/co-registered to the policyholder or spouse...',
        defaultMessage: 'We are unable to complete your delete vehicle request because at least one vehicle must be registered/co-registered to the policyholder or spouse.'
    },
    pleaseCallUsAtMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Please call us at...',
        defaultMessage: 'Please call us at &lt;a aria-label="contact us by phone at the number 800-640-2920" href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt; to finish processing this request. Our customer service team is available Monday to Friday 7:30 am – 7:30 pm PT and Saturday 8:00 am – 4:30 pm PT.'
    },
    pleaseCallUsAtMessage2: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Please call us at 2',
        defaultMessage: 'Please call us at &lt;a aria-label="contact us by phone at the number 800-640-2920" href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt;, our customer service team is available Monday to Friday 7:30 am – 7:30 pm PT and Saturday 8:00 am – 4:30 pm PT.'
    },
    pleaseCallUsForAssistanceAdditionalMessage: {
        id: 'wmic.policy-change.component.remove-vehicle-pa-w.Please call us for assistance to make additional...',
        defaultMessage: 'Please call us for assistance to make additional changes to this policy at &lt;a aria-label="contact us by phone at the number 800-640-2920" href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt;. Our customer service team is available Monday to Friday 7:30 am – 7:30 pm PT and Saturday 8:00 am – 4:30 pm PT.'
    },
});
