import { useCallback, useEffect } from 'react';

export function useTokenRenewal({ tokenManager, onTokenRenewed }) {
    const handleRenewal = useCallback((_tokenIdentifier, token) => {
        onTokenRenewed(token);
    }, [onTokenRenewed]);

    useEffect(() => {
        if (tokenManager) {
            tokenManager.on('renewed', handleRenewal);
        }
        return () => {
            if (tokenManager) {
                tokenManager.off('renewed', handleRenewal);
            }
        };
    });
}
